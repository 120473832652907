$f20: 5.556vw;
$f18: 5vw;
$f16: 4.444vw;
$f14: 3.889vw;
$f13: 3.611vw;
$f12: 3.333vw;
$f11: 3.056vw;
$f10: 2.778vw;
$f9: 2.5vw;
@charset "UTF-8";
.mobile-wrapper {
	html,
	body {
		height: 100%;
		-webkit-text-size-adjust: none;
		font-family: 'Spoqa Han Sans', dotum, '돋움', Arial, Sans-serif;
		font-size: 18px;
		color: #343a40;
	}

	body,
	input,
	select,
	button,
	textarea,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	table {
		line-height: 1.5;
		font: inherit;
		color: inherit;
	}

	html,
	body,
	div,
	form,
	input,
	select,
	button,
	textarea,
	legend,
	fieldset,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	ul,
	ol,
	dl,
	li,
	dt,
	dd,
	blockquote,
	address,
	p,
	th,
	td,
	caption {
		margin: 0;
		padding: 0;
		font-style: normal;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}

	ol,
	ul,
	li {
		list-style: none;
	}

	img,
	fieldset {
		vertical-align: middle;
		border: 0 none;
	}

	input,
	select,
	textarea {
		vertical-align: middle;
		resize: none;
		outline: none;
	}

	input[type='text'],
	input[type='password'],
	input[type='submit'],
	input[type='search'],
	input[type='email'],
	input[type='tel'],
	textarea {
		-webkit-appearance: none;
		border-radius: 0;
	}

	select {
		padding: 5px 20px 5px 10px;
		border-radius: 0;
		-webkit-appearance: none;
		border-radius: 0;
		border: 1px solid #c9c9c9;
	}

	/* IE 에서 Select box 화살표 제거 */
	select::-ms-expand {
		display: none;
	}

	button {
		border: 0 none;
		background: transparent;
		cursor: pointer;
		outline: none;
		border-radius: 1px;
	}

	hr {
		display: none;
	}

	legend,
	.hide {
		position: absolute;
		left: -9999px;
	}

	table caption {
		width: 0;
		height: 0;
		visibility: hidden;
		text-indent: -9999px;
		font-size: 0;
		line-height: 0;
	}

	table {
		border-collapse: collapse;
		border-spacing: 0;
		width: 100%;
		table-layout: fixed;
	}

	header,
	footer,
	section,
	article,
	aside,
	nav,
	hgroup,
	details,
	menu,
	figure,
	figcaption {
		display: block;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}

	/* common */
	.left_box {
		float: left;
	}

	.right_box {
		float: right;
	}

	.blind {
		width: 1px;
		height: 1px;
		font-size: 0;
		line-height: 0;
		position: absolute;
		top: 0;
		left: -1000%;
		overflow: hidden;
	}
	.ellipsis {
		text-overflow: ellipsis;
		white-space: nowrap;
		word-wrap: normal;
		width: 100%;
		overflow: hidden;
	}
	a {
		color: inherit;
		text-decoration: none;
		&:active,
		&:hover,
		&:focus {
			text-decoration: none;
			color: #be9162;
			outline: none;
		}
		border-radius: 1px;
	}

	input[type='checkbox'] {
		outline: none;
		appearance: none;
		display: inline-block;
		width: 5.556vw;
		height: 5.556vw;
		background: #fff;
		border: 0.278vw solid #dce1e6;
		border-radius: 0.556vw;
		background: #fff;
		&:checked {
			background: #31383f url(../img/common/ico_check.svg) no-repeat 50% 50% / 3.333vw;
			border: 0.278vw solid #31383f;
		}
	}
	input[type='radio'] {
		outline: none;
		appearance: none;
		display: inline-block;
		width: 5.556vw;
		height: 5.556vw;
		background: url(../img/common/ico_radio_off.svg) no-repeat 0 0 / cover;
		&:checked {
			background: url(../img/common/ico_radio_on.svg) no-repeat 0 0 / cover;
			border: 0 solid #000;
		}
	}
	input[type='radio'].check {
		outline: none;
		appearance: none;
		display: inline-block;
		width: 5.556vw;
		height: 5.556vw;
		background: #fff;
		border: 0.278vw solid #dce1e6;
		border-radius: 0.556vw;
		background: #fff;
		&:checked {
			background: #31383f url(../img/common/ico_check.svg) no-repeat 50% 50% / 3.333vw;
			border: 0.278vw solid #31383f;
		}
	}
	input[type='checkbox'].round {
		outline: none;
		appearance: none;
		display: inline-block;
		width: 5.556vw;
		height: 5.556vw;
		background: url(../img/common/ico_radio_off.svg) no-repeat 0 0 / cover;
		&:checked {
			background: url(../img/common/ico_radio_on.svg) no-repeat 0 0 / cover;
			border: 0 solid #000;
		}
	}
	input.input_t {
		display: flex;
		align-items: center;
		background: #fff;
		border: 0.278vw solid #e7ecf1;
		border-radius: 1.111vw;
		height: 13.333vw;
		padding: 0 4.444vw;
		font-size: $f14;
		color: #31383f;
		font-weight: 500;
		width: 100%;
		&::placeholder {
			opacity: 0.5;
		}
		&:disabled {
			background-color: #f5f7fa;
		}
		&:focus {
			border-color: #343a40;
		}
	}
	.clear:after {
		content: '';
		display: block;
		clear: both;
	}
	/* font-size vw */
	$f14: 3.889vw;
	$f13: 3.611vw;
	.fl {
		float: left;
	}

	.fr {
		float: right;
	}

	.m_br {
		display: none;
	}

	.pc_br {
		display: block;
	}

	.wrap {
		position: relative;
		width: 100%;
	}
	#wrap {
		width: 100%;
		overflow-x: hidden;
	}
	#wrap.app {
		padding-bottom: 17.778vw;
	}

	body {
		overflow-x: hidden;
		background: #f5f7fa;
	}

	.bg_dimmed {
		display: none;
		position: fixed;
		left: 0;
		top: 0;
		background: rgba(0, 0, 0, 0.5);
		width: 100%;
		height: 100%;
		z-index: 1000;
		&.show {
			display: block;
		}
	}

	.poppins {
		font-family: 'Poppins';
	}

	header {
		background: #fff;
		h1 img {
			width: 35.475vw;
		}
		.inner {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			padding: 0 5.556vw 0 4.167vw;
			height: 18.056vw;
			button {
				width: 6.111vw;
				img {
					width: 100%;
				}
			}
		}
		nav {
			background-color: #000;
			// font-size: $f14;
			font-size: 3.1vw;
			color: #fff;
			font-weight: 500;
			ul {
				// display: flex;
				// align-items: center;
				// justify-content: space-between;
				// padding: 0 2.778vw;
				// height: 13.056vw;
				// padding: 4vw 6.778vw;
				padding: 4vw 5.778vw;
				height: 13.056vw;
			}
		}
	}
	footer {
		font-size: $f13;
		color: #fff;
		background: #212529;
		.inner {
			font-size: $f14;
			padding: 12.5vw 5vw 9.167vw;
			.t1 {
				font-weight: 700;
				margin-bottom: 2.778vw;
			}
		}
		.faq_box {
			padding: 0 5vw 7.5vw;
			.tit {
				color: #000;
				padding: 1.389vw 3.611vw 1.944vw;
				font-size: $f14;
				font-weight: 400;
				background-color: #fff;
			}
			div {
				margin-top: 8.611vw;
				display: flex;
				justify-content: space-between;
				strong {
					font-weight: 700;
				}
				li {
					font-size: $f14;
					margin-bottom: 1.944vw;
					line-height: 3.889vw;
					&:first-child {
						margin-bottom: 2.5vw;
						line-height: 5.833vw;
					}
				}
			}
		}
		.agree_box {
			color: #fff;
			font-size: $f14;
			font-weight: 700;
			padding: 8.333vw 5vw 5vw;
			border-top: 1px solid #444;
			a {
				margin-right: 3.611vw;
			}
		}
		.sns_list {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 5vw;
			background-color: #000;
			ul {
				display: flex;
			}
			button {
				color: #fff;
				font-size: $f14;
				font-weight: 700;
				padding-right: 4.444vw;
				background: url(../img/common/ico_down_arrow.svg) no-repeat 100% 50% / 3.333vw;
			}
		}
		.copy {
			text-align: center;
			font-size: $f13;
			font-weight: 400;
			opacity: 0.75;
			padding: 7.222vw 0 5.556vw;
		}
		.footer_btm {
			padding: 0 5vw 8.611vw;
			display: none;
			&.show {
				display: block;
			}
			li {
				display: flex;
				justify-content: space-between;
				opacity: 0.75;
				margin-bottom: 2.778vw;
				&:last-child {
					margin-bottom: 0;
				}
				strong {
					font-weight: 400;
				}
				span {
					text-align: right;
				}
			}
		}
	}
	.foot_nav {
		position: fixed;
		width: 100%;
		left: 0;
		bottom: 0;
		background-color: #fff;
		z-index: 100;
		ul {
			display: flex;
			width: 100%;
			height: 17.778vw;
			li {
				width: calc(100% / 5);
				opacity: 0.5;
				a {
					width: 100%;
					height: 100%;
					display: flex;
					justify-content: center;
					padding-top: 11.111vw;
					font-size: 3.056vw;
					color: #31383f;
					line-height: 109%;
					font-weight: 500;
				}
				&:nth-child(1) {
					background: url(../img/common/app_nav_01.svg) no-repeat 50% 3.611vw / 5.556vw;
				}
				&:nth-child(2) {
					background: url(../img/common/app_nav_02.svg) no-repeat 50% 3.333vw / 6.111vw;
				}
				&:nth-child(3) {
					background: url(../img/common/app_nav_03.svg) no-repeat 50% 3.611vw / 6.667vw;
				}
				&:nth-child(4) {
					background: url(../img/common/app_nav_04.svg) no-repeat 50% 3.056vw / 6.25vw;
				}
				&:nth-child(5) {
					background: url(../img/common/app_nav_05.svg) no-repeat 50% 4.444vw / 5.556vw;
				}
				&.on {
					opacity: 1;
				}
			}
		}
	}
	/* popup alert */
	.popup_alert {
		position: fixed;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 83%;
		background: #fff;
		box-shadow: 0 0 6.667vw rgba(0, 0, 0, 0.22);
		border-radius: 2.222vw;
		padding: 5vw 5.556vw 5.556vw;
		z-index: 1001;
		.t1 {
			font-size: $f16;
			font-weight: 500;
			color: rgba(49, 56, 63, 1);
		}
		.t2 {
			font-size: $f18;
			font-weight: 500;
			color: rgba(49, 56, 63, 1);
		}
		.t3 {
			font-size: $f16;
			font-weight: 500;
			color: rgba(49, 56, 63, 1);
			opacity: 0.75;
			margin-top: 2.222vw;
		}
		.t4 {
			font-size: $f18;
			font-weight: 700;
			color: rgba(49, 56, 63, 1);
		}
		.t5 {
			font-size: $f16;
			font-weight: 500;
			color: rgba(49, 56, 63, 1);
			opacity: 0.75;
			margin-top: 2.222vw;
			text-align: center;
		}
		.t6 {
			font-size: $f13;
			font-weight: 500;
			color: rgba(49, 56, 63, 1);
			opacity: 0.75;
			margin-top: 4.444vw;
			text-align: center;
		}

		.tbl_info {
			table-layout: fixed;
			width: 100%;
			background: rgba(243, 246, 249, 0.5);
			border: 0.278vw solid #f1f1f1;
			border-radius: 0.556vw;
			td,
			th {
				padding: 2.778vw;
				border: 0.278vw solid #edf0f3;
			}
			th {
				font-size: $f12;
				text-align: left;
				font-weight: 500;
				color: rgba(49, 56, 63, 1);
			}
			td {
				font-size: $f11;
				color: #31383f;
				vertical-align: top;
			}
		}

		.setting_box {
			background: #f3f6f9;
			border: 0.278vw solid #f1f1f1;
			border-radius: 0.556vw;
			padding: 6.667vw;
			margin-top: 4.444vw;
			font-size: $f14;

			ul {
				li {
					margin-bottom: 3.333vw;
					&:last-child {
						margin-bottom: 0;
					}
					display: flex;
					span {
						width: 31.389vw;
						opacity: 0.75;
					}
				}
			}
		}

		.btn,
		.btn_ok {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 13.333vw;
			margin-top: 11.111vw;
			background: #31383f;
			border-radius: 1.111vw;
			font-size: $f16;
			font-weight: 500;
			color: #fff;
		}
		.btn_wrap {
			margin-top: 18.333vw;
			display: flex;
			justify-content: space-between;
			button {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 48.5%;
				height: 13.333vw;
				margin-top: 0;
				background: #31383f;
				border-radius: 1.111vw;
				font-size: $f16;
				font-weight: 500;
				color: #fff;
				&.btn_cancel {
					background: #f5f7fa;
					color: rgba(49, 56, 63, 1);
				}
			}
		}
	}
}
/*# sourceMappingURL=common.css.map */
