.hapl {
	font-family: Roboto;
	font-weight: normal;
	font-size: 1rem;
	.sub-top {
		font-family: Roboto;
		background-image: url(../../assets-pc/front-img/pc-hapl-main-2.png);
		background-size: 100% 100%;
		padding: 0 100px;
		// background-repeat: no-repeat;
		width: 100%;
		position: relative;
		height: 362px;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-align: center;
		align-items: center;
		-ms-flex-direction: column;
		flex-direction: column;
		-ms-flex-pack: center;
		justify-content: center;
		color: #fff;
		h1,
		div {
			font-family: Roboto;
			font-style: normal;
			font-weight: 300;
			font-size: 30px;
			line-height: 21px;
			letter-spacing: 0.1em;
			text-align: center;
			height: 40px;
		}
		h1 {
			position: absolute;
			bottom: 160px;
			font-size: 36px;
			font-weight: 500;
		}
		.sub-title {
			font-size: 18px;
			margin-top: 8px;
			position: absolute;
			bottom: 120px;
			font-weight: 300;
		}
		.btn-box {
			margin-top: 30px;
			position: absolute;
			bottom: 36px;
			img {
				cursor: pointer;
			}
			.reservation-img {
				margin-right: 2px;
				width: 211px;
			}
			.store-img {
				margin-left: 2px;
				width: 211px;
			}
			.reservation-btn {
				background-color: #b9a07e;

				height: 54px;
				width: 211px;
				left: 0px;
				top: 0px;
				border-radius: 0px;
				margin-right: 2px;
				position: relative;
				.content {
					position: absolute;
					left: 31.75%;
					top: 37.04%;

					font-family: Roboto;
					font-weight: normal;
					font-size: 1rem;
					/* identical to box height */

					display: flex;
					align-items: center;
					text-align: center;

					color: #fcfcfc;
				}
				.line1 {
					position: absolute;
					left: 31.75%;
					right: 5.21%;
					top: 85.19%;
					bottom: 14.81%;

					border: 1px solid rgba(255, 255, 255, 0.46);
				}
				.line2 {
					position: absolute;
					left: 84%;
					right: 4%;
					top: 70.61%;
					bottom: 33.33%;

					border: 1px solid rgba(255, 255, 255, 0.46);
					transform: rotate(37.57deg);
				}
			}
			.introduce-btn {
				background-color: #574b3a;
				height: 54px;
				width: 211px;
				left: 0px;
				top: 0px;
				border-radius: 0px;
				margin-left: 2px;
				position: relative;
				.content {
					position: absolute;
					left: 32.23%;
					top: 38.89%;

					font-family: Roboto;
					font-weight: normal;
					font-size: 1rem;
					/* identical to box height */

					display: flex;
					align-items: center;
					text-align: center;
					color: rgba(255, 255, 255, 0.5);
				}
				.line1 {
					position: absolute;
					left: 32.23%;
					right: 4.74%;
					top: 85.19%;
					bottom: 14.81%;

					border: 1px solid rgba(255, 255, 255, 0.46);
				}
				.line2 {
					position: absolute;
					left: 84%;
					right: 4%;
					top: 70.61%;
					bottom: 33.33%;

					border: 1px solid rgba(255, 255, 255, 0.46);
					transform: rotate(37.57deg);
				}
			}
			.left-top-logo {
				position: absolute;
				left: 5.21%;
				right: 60.66%;
				top: 12.96%;
				bottom: 64.81%;

				font-family: Roboto;
				font-style: normal;
				font-weight: normal;
				font-size: 10px;
				line-height: 12px;
				/* identical to box height */

				display: flex;
				align-items: center;
				text-align: center;

				color: rgba(252, 252, 252, 0.3);
			}
		}
	}
	.hapl-slider-wrapper {
		width: 1280px;
		position: relative;
		margin: 0 auto;
		padding: 39px 40px 40px;
		font-family: Roboto;
		.seoul {
			background-image: url(../../assets-pc/front-img/seoul.png);
		}
		.daegu {
			background-image: url(../../assets-pc/front-img/daegu.png);
		}
		.busan {
			background-image: url(../../assets-pc/front-img/busan.png);
		}
		.gray {
			position: absolute;
			left: 0%;
			right: 0%;
			top: 60.65%;
			bottom: 0%;

			background: rgba(0, 0, 0, 0.31);
		}

		.hapl-swiper-slide {
			height: 338px;
			width: 341px;
			border-radius: 5px;
			background-color: #535353;
			position: relative;
			margin: 0 auto;
			background-repeat: no-repeat;
			background-position: 50% 50%;
			background-size: cover;
			cursor: 'pointer';
			.slide-reservation-btn {
				position: absolute;
				left: 61%;
				right: 5.28%;
				top: 5.92%;
				bottom: 79.88%;

				background: #010101;
			}
			.reservation-btn-dots {
				position: absolute;
				left: 63.93%;
				right: 34.02%;
				top: 8.28%;
				bottom: 87.57%;

				font-family: Roboto;
				font-style: normal;
				font-weight: 300;
				font-size: 14px;
				text-align: center;

				color: #535353;
				height: 14px;
				width: 7px;
				border-radius: nullpx;
			}
			.dots {
				position: absolute;
				left: 63.93%;
				right: 34.02%;
				top: 8.28%;
				bottom: 87.57%;
				width: 7px;
			}
			.line {
				position: absolute;
				left: 88.86%;
				right: 7.04%;
				top: 13.31%;
				bottom: 82.54%;

				width: 14px;
			}
			.reservation-text {
				position: absolute;
				left: 67.74%;
				top: 10.95%;

				font-family: 'Roboto';
				font-style: normal;
				font-weight: 300;
				font-size: 14px;
				line-height: 16px;

				color: #f8f8f8;
				text-align: center;
			}
			.store-info-wrapper {
				position: absolute;
				left: 4.69%;
				right: 16.42%;
				top: 65.86%;
				bottom: 4.07%;
				z-index: 100;

				font-family: NanumGothic;
				font-style: normal;
				font-weight: bold;
				font-size: 18px;
				line-height: 18px;

				color: #f9f9f9;
				display: flex;
				justify-content: space-around;
				flex-direction: column;
				.branch-name {
					font-family: NanumGothic;
					font-size: 18px;
					font-style: normal;
					font-weight: 700;
					line-height: 18px;
					letter-spacing: 0em;
					text-align: left;
				}
				.branch-info {
					font-family: NanumGothic;
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: 16px;
					letter-spacing: 0em;
					text-align: left;
				}
			}
		}
	}
	.reservation-list-wrapper {
		width: 1140px;
		position: relative;
		margin: 0 auto;
		.reservation-top {
			display: flex;
			justify-content: space-between;
		}
		.reservation-notice {
			.dot {
				font-size: 17px;
				font-weight: 900;
			}
			font-family: Roboto;
			font-size: 16px;
			font-style: normal;
			font-weight: 300;
			line-height: 52px;
			letter-spacing: 0em;
			text-align: center;
			color: #000000;
		}
		.select-store {
			width: 341px;
			height: 52px;

			background: #ffffff;
			border: 1px solid #c4c4c4;
			font-family: Roboto;

			box-sizing: border-box;
			border-radius: 2px;
			position: relative;
			cursor: pointer;
			.selected-name {
				position: absolute;
				width: 180.46px;
				height: 19px;
				left: 17px;
				top: 17px;

				font-family: Roboto;
				font-style: normal;
				font-weight: 300;
				font-size: 16px;
				line-height: 19px;
				/* identical to box height */

				color: #000000;
			}
			.triangle-img {
				position: absolute;
				width: 12.07px;
				height: 10px;
				right: 17px;
				background: url(../../assets-pc/img/common/btn_paging_prev.svg) no-repeat 50% 50%;
				top: 20px;
				transform: rotate(-90deg);
			}
			.up {
				transform: rotate(-270deg);
			}
			.select-list {
				position: absolute;
				width: 341px;
				top: 58px;
				padding: 20px 24px;
				font-family: Roboto;

				background: #ffffff;
				border: 1px solid #c4c4c4;
				box-sizing: border-box;
				overflow: scroll;
				.oneSelect {
					width: 100%;
					font-family: Roboto;
					font-style: normal;
					font-weight: 300;
					font-size: 16px;
					line-height: 25px;

					color: #828282;
					cursor: pointer;
					&:hover {
						font-weight: 400;
					}
				}
			}
		}
		.reservation-table {
			margin-top: 16.5px;
			font-weight: 300;
			font-size: 16px;
			line-height: 19px;
			text-align: center;
			color: #000000;
			.no-list {
				width: 1140px;
				height: 200px;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			thead {
				font-family: Roboto;

				border-top: 5px solid #000000;
				height: 50px;
				background-color: #efefef;
				th {
					color: #000000;

					font-weight: 300;
					font-size: 16px;
				}
				th:nth-child(1n) {
					border: 1px solid #c4c4c4;
					border-left: none;
				}
				th:nth-child(5n) {
					border: 1px solid #c4c4c4;
					border-right: none;
				}
			}
			tbody {
				font-family: Roboto;

				tr {
					height: 49px;
					td {
					}
					td:nth-child(1n) {
						border: 1px solid #c4c4c4;
						border-left: none;
					}
					td:nth-child(5n) {
						border: 1px solid #c4c4c4;
						border-right: none;
					}
				}
			}

			.paging {
				display: flex;
				justify-content: center;
				margin-top: 44px;
				.first-page {
					position: relative;
				}
				.last-page {
					position: relative;
				}
				.first-page-prev {
					position: absolute;
					left: -2.5px;
					width: 9px;
					height: 0px;

					border: 1px solid #000000;
					transform: rotate(90deg);
				}
				.last-page-next {
					position: absolute;
					right: -2.5px;
					width: 9px;
					height: 0px;

					border: 1px solid #000000;
					transform: rotate(90deg);
				}
				div {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 32px;
					height: 32px;
					font-size: 14px;
					margin: 0 6px;
					cursor: pointer;
					&:hover {
						color: #fff;
						background-color: #31383f;
					}
				}
				.btn.prev {
					background: url(../../assets-pc/img/common/btn_paging_prev.svg) no-repeat 50% 50%;
				}
				.btn.next {
					background: url(../../assets-pc/img/common/btn_paging_next.svg) no-repeat 50% 50%;
				}
			}
		}
	}

	// step1
	.step1-wrapper {
		width: 1200px;
		margin: 0 auto;
		// position: relative;
		.step1-numbering {
			width: 254px;
			height: 87px;
			margin: 53px auto 75px;
			position: relative;
			.light-brown {
				width: 51px;
				height: 51px;

				background: #b9a07e;
				border: 10px solid #ded6cb;
				box-sizing: border-box;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;

				margin-left: 24px;
			}
			.num {
				width: 7px;
				height: 19px;

				font-family: Roboto;
				font-style: normal;
				font-weight: 500;
				font-size: 16px;
				line-height: 19px;
				/* identical to box height */

				display: flex;
				align-items: center;

				color: #ffffff;
			}
			.line {
				width: 120px;
				height: 0px;

				border: 1px solid #dfdfdf;
				position: absolute;
				left: 75px;
				top: 25px;
			}
			.light-gray {
				position: absolute;
				width: 51px;
				height: 51px;

				background: #898989;
				border: 10px solid #e5e5e5;
				box-sizing: border-box;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				left: 195px;
				top: 0px;
			}
			.step1-text {
				position: absolute;
				height: 21px;
				left: 0px;
				bottom: 0px;

				font-family: Roboto;
				font-style: normal;
				font-weight: 500;
				font-size: 18px;
				/* identical to box height */

				display: flex;
				align-items: center;

				color: #b9a07e;
			}
			.step2-text {
				position: absolute;
				height: 21px;
				right: 0px;
				bottom: 0px;

				font-family: Roboto;
				font-style: normal;
				font-weight: 500;
				font-size: 18px;
				/* identical to box height */

				display: flex;
				align-items: center;

				color: #000000;
			}
		}

		.step1-notice {
			font-family: Roboto;
			font-style: normal;
			font-weight: 500;
			font-size: 24px;
			line-height: 28px;
			display: flex;
			flex-direction: column;
			align-items: center;

			color: #000000;
			.selected-store {
				width: 1200px;
				height: 91px;

				background: #f9fafb;
				border-bottom: 1px solid #cecece;
				border-top: 2px solid #000000;

				display: flex;
				justify-content: center;
				align-items: center;
				.text {
					height: 28px;
				}
			}
			.step1-title {
				width: 1200px;

				color: #000000;
				margin-top: 49px;
				padding-bottom: 23px;
				border-bottom: 1px solid #cecece;
			}
			.notice-box {
				width: 1202px;
				height: 166px;
				font-family: Roboto;

				background: #ffffff;
				border: 3px solid #edf0f3;
				box-sizing: border-box;
				border-radius: 10px;
				margin-top: 37px;
				font-weight: 500;
				position: relative;
				.purple-icon {
					width: 26px;
					height: 26px;
					border-radius: 50%;
					background: #aa9ce0;
					position: absolute;
					margin-left: 21px;
					margin-top: 19px;
				}
				span {
					color: white;
					position: absolute;
					font-size: 18px;
					width: 5px;
					height: 21px;
					margin-left: 31.5px;
					margin-top: 21px;
				}

				.title {
					font-size: 18px;
					line-height: 21px;
					/* identical to box height */

					display: flex;
					align-items: center;

					color: #000000;
					margin-left: 58px;
					margin-top: 21px;
				}
				p {
					width: 897px;
					margin-left: 58px;
					margin-top: 19px;
					height: 73px;

					font-size: 16px;
					line-height: 24px;
					display: flex;
					align-items: center;

					color: #000000;
				}
			}
		}
		.user-info {
			margin-top: 74.28px;
			.user-info-title {
				height: 28px;

				font-weight: 500;
				font-size: 24px;
				line-height: 28px;
				display: flex;
				align-items: center;

				color: #000000;

				margin-bottom: 37px;
			}
			.tbl {
				width: 100%;
				border-top: 1px solid #31383f;
				border-radius: 1px;
				thead {
					border-top: 2px solid black;
				}
				th,
				td {
					border-bottom: 1px solid rgba(161, 172, 183, 0.35);
					text-align: left;
					font-size: 16px;
					font-weight: 500;
				}
				th {
					border-right: 1px solid rgba(161, 172, 183, 0.35);
				}
				td:nth-child(1n) {
				}
				tr:nth-child(1n) {
					height: 79px;
					th {
						height: 79px;
					}
				}
				tr:nth-child(2n) {
					height: 90px;
					th {
						height: 90px;
					}
				}
				tr:nth-child(3n) {
					height: 99px;
					th {
						height: 99px;
					}
				}
				tr:nth-child(4n) {
					height: 140px;
					th {
						height: 140px;
					}
				}
				th {
					font-weight: 500;
					background: rgba(249, 250, 251, 1);
					display: flex;
					justify-content: center;
					align-items: center;
				}
				ul {
					display: flex;
					span {
						margin-left: 15px;
					}
					li:nth-child(1n) {
						margin-left: 16.5px;
					}
					li:nth-child(2n) {
						margin-left: 39px;
					}
				}
				input[type='checkbox'].round {
					outline: none;
					appearance: none;
					display: inline-block;
					width: 26px;
					height: 26px;
					background: url(../../assets-pc/img/common/ico_radio_off.svg) no-repeat 0 0 / cover !important;
					&:checked {
						background: url(../../assets-pc/img/common/ico_radio_on.svg) no-repeat 0 0 / cover !important;
						border: 0 solid #000;
					}
				}
				.user-name {
					margin: 20px 0 20px 18.5px !important;
					width: 456px !important;
					height: 50px;

					background: #ffffff;
					border: 1px solid #c4c4c4;
					box-sizing: border-box;
					padding: 22px;
				}
				.people-num {
					margin: 20px 0 20px 18.5px !important;
					width: 73px;
					height: 50px;

					background: #ffffff;
					border: 1px solid #c4c4c4;
					box-sizing: border-box;
					text-indent: 19px;
					padding: 10px;
				}
				.people-num-text {
					margin-left: 20px;
				}
				.email-id {
					margin: 20px 17px 16px 18.5px !important;
					padding: 13px 20px;
					width: 205px;
					height: 50px;

					background: #ffffff;
					border: 1px solid #c4c4c4;
					box-sizing: border-box;
				}
				.desc {
					margin-left: 18.5px;
				}
				.email-address {
					margin: 20px 0 20px 11px !important;
					padding: 13px 20px;
					width: 205px;
					height: 50px;

					background: #ffffff;
					border: 1px solid #c4c4c4;
					box-sizing: border-box;
				}
				.email-select {
					width: 205px;
					height: 50px;

					background: #ffffff;
					border: 1px solid #c4c4c4;
					box-sizing: border-box;
					margin-left: 8px;

					position: relative;
				}
				.arrow {
					position: absolute;
					width: 12.07px;
					height: 10px;
					left: 660px;
					bottom: 44px;
					background: url(../../assets-pc/img/common/btn_paging_prev.svg) no-repeat 50% 50%;
					transform: rotate(270deg);
				}
				.email-tr {
					position: relative;
				}
			}
		}
		// step1 - 거래정보

		.deal-info {
			margin-top: 86px;
			font-family: Roboto;
			font-style: normal;

			color: #000000;
			.deal-info-title {
				height: 28px;
				font-weight: 500;
				font-size: 24px;
				line-height: 28px;
				display: flex;
				align-items: center;
				margin-bottom: 37px;
			}
			.tbl {
				width: 100%;
				border-top: 1px solid #31383f;
				border-radius: 1px;
				th,
				td {
					border-bottom: 1px solid rgba(161, 172, 183, 0.35);
					padding: 22px 20px;
					text-align: left;
					font-size: 16px;
				}
				th {
					font-weight: bold;
					background: #f5f7fa;
				}
				thead {
					border-top: 2px solid black;
				}
				th,
				td {
					border-bottom: 1px solid rgba(161, 172, 183, 0.35);
					text-align: left;
					font-size: 16px;
					font-weight: 500;
				}
				th {
					border-right: 1px solid rgba(161, 172, 183, 0.35);
				}
				td:nth-child(1n) {
				}
				tr:nth-child(1n) {
					height: 79px;
					th {
						height: 79px;
					}
				}
				tr:nth-child(2n) {
					height: 100px;
					th {
						height: 100px;
					}
				}
				tr:nth-child(3n) {
					height: 99px;
					th {
						height: 99px;
					}
				}
				tr:nth-child(4n) {
					height: 200px;
					th {
						height: 200px;
					}
				}
				th {
					font-weight: 500;
					background: rgba(249, 250, 251, 1);
					display: flex;
					justify-content: center;
					align-items: center;
				}
				ul {
					display: flex;
					li {
						span {
							margin-left: 12px;
						}
					}
					.consignment {
						margin-left: 42px;
					}
					.purchase {
						margin-left: 28px;
					}
					.normal-checkbox:nth-child(2n) {
						margin-left: 25px;
					}
					.normal-checkbox:nth-child(3n) {
						margin-left: 25px;
					}
				}
				.desc {
					margin-top: 10px;
					// font-size: 15px;
					// color: #c4c4c4;
				}
				input[type='radio'] {
					outline: none;
					appearance: none;
					display: inline-block;
					width: 24px;
					height: 24px;
					background: url(../../assets-pc/img/common/ico_radio_off.svg) no-repeat 0 0 / cover;
					&:checked {
						background: url(../../assets-pc/img/common/ico_radio_on.svg) no-repeat 0 0 / cover;
						border: 0 solid #000;
					}
				}
				input[type='checkbox'].pay-service {
					outline: none;
					appearance: none;
					display: inline-block;
					width: 26px;
					height: 26px;
					background: url(../../assets-pc/front-img/ico_checkbox_brown_off.svg) no-repeat 0 0 /
						cover;
					&:checked {
						background: url(../../assets-pc/front-img/ico_checkbox_brown_on.svg) no-repeat 0 0 /
							cover;
						border: 0 solid #000;
						background-color: #edf0f3;
						border: 1px solid #c4c4c4;
						box-sizing: border-box;

						background-size: 60%;
						background-position: 5px 6px;
					}
				}
				.place-holder {
					height: 26px;

					font-weight: 500;
					font-size: 18px;
					line-height: 21px;
					/* identical to box height */

					display: flex;
					align-items: center;

					color: #c4c4c4;
					margin-left: 43px;
				}
				.description-email {
					width: 857px;
					height: 148px;

					background: #ffffff;
					border: 1px solid #c4c4c4;
					box-sizing: border-box;
					padding: 18px 11px;
					overflow-y: scroll;
					resize: none;
				}
			}
		}
		.btn-box {
			margin: 89px auto 16px;
			display: flex;
			justify-content: center;
			.btn-cancel {
				width: 153px;
				height: 52px;

				background: #ffffff;
				border: 1px solid #c4c4c4;
				box-sizing: border-box;
				border-radius: 5px;
				margin-right: 5px;
			}
			.btn-reservation {
				width: 153px;
				height: 52px;

				background: #3865e0;
				border-radius: 5px;
				color: white;

				margin-left: 4px;
			}
		}
		.calendar {
			.control {
				margin-top: 67px;
				display: flex;
				justify-content: space-between;
				.left {
					display: flex;
					align-items: center;
					font-family: Inter;

					button {
						font-style: normal;
						font-weight: 900;
						font-size: 12px;
						/* identical to box height */

						display: flex;
						align-items: center;
						text-align: right;

						color: #000000;
					}
					span {
						height: 29px;

						font-weight: 600;
						font-size: 24px;
						/* identical to box height */

						display: flex;
						align-items: center;
						text-align: right;

						color: #000000;
					}
					.desc {
						height: 17px;
						font-weight: 500;
						font-size: 14px;
						display: flex;
						align-items: center;
						text-align: right;
						margin-left: 6px;

						color: #000000;
					}
					.prev {
						margin-right: 9px;
					}
					.next {
						margin-left: 9px;
					}
				}
				.right {
					display: flex;
					align-items: center;
					margin-bottom: 15px;
					position: relative;
					.not-possible {
						width: 79px;
						height: 34px;

						background: #d7e0f9;
						border-radius: 5px;
						color: #3865e0;
						font-weight: 500;
						font-size: 14px;
						line-height: 17px;
						/* identical to box height */

						display: flex;
						align-items: center;
						justify-content: center;
						margin-right: 5px;
					}
					.possible {
						width: 56px;
						height: 34px;

						background: #3865e0;
						border-radius: 5px;
						color: #ffffff;
						font-size: 14px;
						line-height: 17px;
						display: flex;
						align-items: center;
						justify-content: center;
						margin-right: 5px;
					}
					.select {
						width: 218px;
						height: 34px;

						background: #3865e0;
						border-radius: 5px;
						color: #ffffff;
						font-size: 14px;
						line-height: 17px;
						display: flex;
						align-items: center;
						padding-left: 15px;
						position: relative;
					}
					.triangle {
						position: absolute;
						right: 13px;
						width: 10px;
						height: 10px;
					}
					.down {
						background: url(../../assets-pc/front-img/white-triangle.svg) no-repeat 0 0;
					}
					.up {
						background: url(../../assets-pc/front-img/white-triangle.svg) no-repeat 0 0;
						transform: rotate(180deg);
					}
					.time-select {
						position: absolute;
						width: 218px;

						background: #ffffff;
						border: 1px solid #f0f0f1;
						box-sizing: border-box;
						right: 0;
						top: 49px;
						padding: 17px 24px 22px 24px;
						li {
							font-family: Inter;
							font-style: normal;
							font-weight: 500;
							font-size: 14px;
							line-height: 30px;
							display: flex;
							align-items: center;

							color: black;
							cursor: pointer;
							position: relative;
							.selected-content {
								position: absolute;
								width: 19px;
								height: 19px;

								background: #aa9ce0;
								top: 3px;
								left: -15px;
								border-radius: 50%;
								color: white;
								display: flex;
								justify-content: center;
								align-items: center;
							}
						}
						.disabled {
							color: #c4c4c4;
							cursor: not-allowed;
						}
					}
				}
			}
			.container-gray {
				height: 975.62px;
				background: #f0f0f1;
				padding: 36.63px 57.81px 64.11px 57.81px;
				.day-tr {
					// td:nth-child(2) {
					// 	border-left: none;
					// 	background: #d7e0f9;
					// 	border-radius: 5px;
					// 	div {
					// 		color: #007aff;
					// 	}
					// }
					.full {
						border-left: none;
						background: #d7e0f9;
						border-radius: 5px;
						div {
							color: #007aff;
						}
					}
				}
				table {
				}
				.day-table {
					background-color: rgba(255, 255, 255, 1);
					border-radius: 5px;
					border-collapse: separate;
					border-spacing: 10px 20px;
					.full {
						border-left: none;
						background: #d7e0f9;
						border-radius: 5px;
						div {
							color: #007aff;
						}
					}
					.day-td {
						width: 112px;
						height: 112.56px;

						font-family: Inter;
						font-style: normal;
						font-weight: 500;
						font-size: 18px;
						line-height: 22px;
						text-align: center;
						/* Black */

						color: #000000;
						div {
							font-family: Inter;
							font-style: normal;
							font-weight: 500;
							font-size: 12px;
							line-height: 14.52px;
							text-align: center;
						}
						.day {
							font-size: 18px !important;
							line-height: 21.78px !important;
						}
					}
					.today {
						width: 112px;
						height: 112.56px;
						color: white !important;

						background-color: #3865e0 !important;
						border-radius: 5px;
						div {
							color: white !important;
						}
						.day {
							font-size: 18px !important;
							line-height: 21.78px !important;
						}
					}
					.full {
						border-left: none;
						background: #d7e0f9;
						border-radius: 5px;
						color: #007aff;
						div {
							color: #007aff;
						}
					}
					.non-show {
						visibility: hidden;
					}
				}
				.week-body {
				}
				.week-string {
					.one-title {
						width: 48.4px;
						height: 65.53px;
						margin-bottom: 10.87px;

						font-size: 18px;
						line-height: 22px;
						text-align: center;

						color: #2e3543;
					}
				}
			}
		}

		.fixed-own-reservation {
			position: fixed;
			right: 20px;
			width: 309px;
			z-index: 100;
			.one-reservation {
				width: 309px;
				right: 0px;
				margin-top: 10px;

				background: #f2f6ff;
				.title {
					height: 50.5px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					font-family: Inter;
					font-style: normal;
					font-weight: normal;
					font-size: 24px;
					line-height: 29px;
					padding: 0 20px;
					border-bottom: 1px solid rgba(196, 196, 196, 1);
					.cursor {
						cursor: pointer;
						width: 25px;
					}
				}
				.content {
					padding: 40px 30px;
					line-height: 40px;
				}
				.between {
					display: flex;
					justify-content: space-between;
				}
			}
		}
	}
	.step2-wrapper {
		width: 1200px;
		margin: 0 auto;
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		.step2-numbering {
			width: 254px;
			height: 87px;
			margin: 53px auto 75px;
			position: relative;
			.light-brown {
				position: absolute;
				left: 195px;
				top: 0px;
				width: 51px;

				height: 51px;

				background: #b9a07e;
				border: 10px solid #ded6cb;
				box-sizing: border-box;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			.num {
				width: 7px;
				height: 19px;
				font-size: 16px;
				line-height: 19px;
				/* identical to box height */

				display: flex;
				align-items: center;

				color: #ffffff;
			}
			.line {
				width: 120px;
				height: 0px;

				border: 1px solid #dfdfdf;
				position: absolute;
				left: 75px;
				top: 25px;
			}
			.light-gray {
				margin-left: 24px;
				width: 51px;
				height: 51px;

				background: #898989;
				border: 10px solid #e5e5e5;
				box-sizing: border-box;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			.step1-text {
				position: absolute;
				height: 21px;
				left: 0px;
				bottom: 0px;

				font-size: 18px;
				/* identical to box height */

				display: flex;
				align-items: center;

				color: #000000;
			}
			.step2-text {
				position: absolute;
				height: 21px;
				right: 0px;
				bottom: 0px;

				font-size: 18px;
				/* identical to box height */

				display: flex;
				align-items: center;

				color: #b9a07e;
			}
		}
		.complete-txt {
			margin-top: 71px;
			margin-bottom: 63px;
			display: flex;
			justify-content: center;

			font-size: 24px;
			line-height: 28px;
		}
		.reservation-info {
			.tbl {
				width: 100%;
				border-top: 1px solid #31383f;
				border-radius: 1px;
				thead {
					border-top: 2px solid black;
				}
				th,
				td {
					border-bottom: 1px solid rgba(161, 172, 183, 0.35);
					text-align: left;
					font-size: 16px;
					font-weight: 500;
				}
				td {
					padding-left: 24px;
				}
				th {
					border-right: 1px solid rgba(161, 172, 183, 0.35);
				}
				td:nth-child(1n) {
				}
				tr:nth-child(1n) {
					height: 79px;
					th {
						height: 79px;
					}
				}
				tr:nth-child(2n) {
					height: 90px;
					th {
						height: 90px;
					}
				}
				tr:nth-child(3n) {
					height: 99px;
					th {
						height: 99px;
					}
				}
				tr:nth-child(4n) {
					height: 100px;
					th {
						height: 100px;
					}
				}
				th {
					font-weight: 500;
					background: rgba(249, 250, 251, 1);
					display: flex;
					justify-content: center;
					align-items: center;
				}
				ul {
					display: flex;
					span {
						margin-left: 15px;
					}
					li:nth-child(1n) {
						margin-left: 16.5px;
					}
					li:nth-child(2n) {
						margin-left: 39px;
					}
				}
				input[type='checkbox'].round {
					outline: none;
					appearance: none;
					display: inline-block;
					width: 26px;
					height: 26px;
					background: url(../../assets-pc/img/common/ico_radio_off.svg) no-repeat 0 0 / cover !important;
					&:checked {
						background: url(../../assets-pc/img/common/ico_radio_on.svg) no-repeat 0 0 / cover !important;
						border: 0 solid #000;
					}
				}
				.user-name {
					margin: 20px 0 20px 18.5px !important;
					width: 456px !important;
					height: 50px;

					background: #ffffff;
					border: 1px solid #c4c4c4;
					box-sizing: border-box;
					padding: 22px;
				}
				.people-num {
					margin: 20px 0 20px 18.5px !important;
					width: 73px;
					height: 50px;

					background: #ffffff;
					border: 1px solid #c4c4c4;
					box-sizing: border-box;
					text-indent: 19px;
					padding: 10px;
				}
				.people-num-text {
					margin-left: 20px;
				}
				.email-id {
					margin: 20px 17px 20px 18.5px !important;
					padding: 13px 20px;
					width: 205px;
					height: 50px;

					background: #ffffff;
					border: 1px solid #c4c4c4;
					box-sizing: border-box;
				}
				.email-address {
					margin: 20px 0 20px 11px !important;
					padding: 13px 20px;
					width: 205px;
					height: 50px;

					background: #ffffff;
					border: 1px solid #c4c4c4;
					box-sizing: border-box;
				}
				.email-select {
					width: 205px;
					height: 50px;

					background: #ffffff;
					border: 1px solid #c4c4c4;
					box-sizing: border-box;
					margin-left: 8px;

					position: relative;
				}
				.arrow {
					position: absolute;
					width: 12.07px;
					height: 10px;
					left: 660px;
					bottom: 44px;
					background: url(../../assets-pc/img/common/btn_paging_prev.svg) no-repeat 50% 50%;
					transform: rotate(270deg);
				}
				.email-tr {
					position: relative;
				}
			}
		}
		.btn-box {
			margin: 89px auto 16px;
			display: flex;
			justify-content: center;
			.btn-cancel {
				width: 250px;
				height: 52px;

				background: #ffffff;
				border: 1px solid #c4c4c4;
				box-sizing: border-box;
				border-radius: 5px;
				margin-right: 5px;
			}
			.btn-reservation {
				width: 250px;
				height: 52px;

				background: #3865e0;
				border-radius: 5px;
				color: white;

				margin-left: 4px;
			}
		}
	}
	.detail-wrapper {
		width: 1200px;
		margin: 0 auto;
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		.title {
			font-size: 24px;
			line-height: 28.13px;
			height: 28px;
			margin: 48px 0;
		}
		.first {
			display: flex;
			justify-content: space-between;
			.img-wrapper {
				width: 570px;
				height: 302px;
				overflow: hidden;
			}
			img {
				height: 302px;
			}
		}
		.first {
			.tbl {
				width: 570px;
				border-top: 1px solid #31383f;
				border-radius: 1px;
				.table-col-first {
					width: 30%;
				}
				.table-col-second {
					width: 70%;
				}
				thead {
					border-top: 2px solid black;
				}
				th,
				td {
					border-bottom: 1px solid rgba(161, 172, 183, 0.35);
					text-align: left;
					font-size: 16px;
					font-weight: 500;
				}
				td {
					padding-left: 24px;
				}
				th {
					border-right: 1px solid rgba(161, 172, 183, 0.35);
				}
				td:nth-child(1n) {
				}
				tr {
					height: 60px;
					th {
						height: 60px;
					}
				}

				th {
					font-weight: 500;
					background: rgba(249, 250, 251, 1);
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}
		}
		.tbl {
			width: 100%;
			border-top: 1px solid #31383f;
			border-radius: 1px;
			.table-col-first {
			}
			.table-col-second {
			}
			thead {
				border-top: 2px solid black;
			}
			th,
			td {
				border-bottom: 1px solid rgba(161, 172, 183, 0.35);
				text-align: left;
				font-size: 16px;
				font-weight: 500;
			}
			td {
				padding-left: 24px;
			}
			th {
				border-right: 1px solid rgba(161, 172, 183, 0.35);
			}
			.content {
				height: 200px;
				th {
					height: 200px;
				}
			}
			tr {
				height: 80px;
				th {
					height: 80px;
				}
			}

			th {
				font-weight: 500;
				background: rgba(249, 250, 251, 1);
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
		.btn-box-detail {
			margin: 89px auto 16px;
			display: flex;
			justify-content: space-between;
			.btn-cancel {
				width: 153px;
				height: 52px;

				background: #3865e0;
				color: white;
				border: 1px solid #c4c4c4;
				box-sizing: border-box;
				border-radius: 5px;
				margin-right: 5px;
			}
			.btn-reservation {
				width: 153px;
				height: 52px;

				background: #3865e0;
				border-radius: 5px;
				color: white;

				margin-left: 4px;
			}
			.btn-list {
				width: 153px;
				height: 52px;

				background: #ffffff;
				border: 1px solid #c4c4c4;
				box-sizing: border-box;
				border-radius: 5px;
				margin-left: 5px;
			}
		}
	}
}
