.whole_frame {
	width: 1080px;
	margin: 0 auto;
	position: relative;
}
.big_frame {
	width: 1280px;
	margin: 0 auto;
}
.header-new-menu-li {
	margin: 0 34px !important;
}
.care_wrapper {
	display: flex;
	width: 100%;
	.care-img-wrapper {
		width: 640px;
		height: 640px;
		display: flex;
		justify-content: center;
		img {
			width: 500px;
		}
	}
	.care-second-wrapper {
		font-size: 18px;
		line-height: 20px;
		letter-spacing: 1px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 640px;
		padding: 0 70px;
		.title {
			font-size: 28px;
			margin-bottom: 54px;
		}
		.second-title {
			font-size: 28px;
			font-weight: normal;
			margin-bottom: 44px;
			.red {
				color: rgb(255, 3, 2);
			}
		}
		.content {
			margin-bottom: 20px;
		}
		.content-bold {
			margin-bottom: 20px;
			font-weight: bold;
		}
		li {
			list-style: inside;
		}
	}
	.second-line-img-wrapper {
		width: 640px !important;
		height: 640px !important;
		display: flex !important;
		justify-content: center;
		.second-img {
			width: 511px !important;
		}
		.third-img {
			width: 500px !important;
		}
	}
	.third-line-second {
		width: 640px;
		height: 640px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 0 70px;
		text-align: center;
		.title {
			font-size: 28px;
			font-weight: normal;
			margin-bottom: 22px;
		}
		.content {
			margin-bottom: 20px;
			font-size: 14px;
		}
		img {
			width: 341px;
		}
	}
}

.mypage_info {
	.mypage_cont {
		h2 + .info_editor {
			/*미현, 정보수정페이지 내 데이트피커 버튼 수정*/
			.react-datepicker button {
				display: inline-flex;
				align-items: center;
				justify-self: center;
				height: 0;
				background: none;
				border-radius: 1px;
				color: #fff;
				font-weight: 500;
				font-size: 14px;
				padding: 0 16px;
				margin-left: 16px;
			}
			/*미현, 정보수정페이지 내 데이트피커 버튼 수정 여기까지*/
		}
	}
}

.main {
	.chart_slider {
		//미현, 메인롤링 호버
		ul:hover > .price_box {
			animation-play-state: paused;
		}
	}
}

.find_info_email_input {
	display: flex;
	align-items: center;
	padding: 0 16px;
	height: 48px;
	border: 1px solid #c2ccd5;
	box-sizing: border-box;
	font-size: 14px;
}

.new_main_shop_photo {
	animation: fadein 3s;
	-moz-animation: fadein 3s; /* Firefox */
	-webkit-animation: fadein 3s; /* Safari and Chrome */
	-o-animation: fadein 3s; /* Opera */
}
@keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@-webkit-keyframes fadein {
	/* Safari and Chrome */
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
.auction_sub_top {
	background-color: rgba(0, 0, 0, 1);
	padding: 0 100px;
	background-size: 20% 100%;
	width: 100%;
	position: relative;
	height: 300px;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	color: #fff;
	h1 {
		font-size: 36px;
		font-weight: 700;
	}
	p {
		margin-top: 10px;
		font-size: 16px;
		font-weight: 500;
	}
	.auction-top-img {
		width: 746px;
		.auction-top-first {
			background: linear-gradient(
					to right,
					rgba(0, 0, 0, 0.9) 10%,
					rgba(0, 0, 0, 0.4) 25%,
					rgba(20, 20, 20, 0.9) 50%,
					rgba(0, 0, 0, 0.9) 75%,
					rgba(0, 0, 0, 0.9) 100%
				),
				url(../front-img/auction_top_img_01.png);
			background-repeat: no-repeat;
			background-size: 50% 100%;
			width: 1300px;
			right: 30%;
			background-position: 70%;
			height: 450px;
			top: -100px;
			position: absolute;
		}
		.auction-top-second {
			background: linear-gradient(
					to right,
					rgba(0, 0, 0, 1) 0%,
					rgba(0, 0, 0, 0.75) 25%,
					rgba(20, 20, 20, 0.5) 50%,
					rgba(0, 0, 0, 0.75) 75%,
					rgba(0, 0, 0, 0.9) 100%
				),
				url(../front-img/auction_top_img_02.jpeg);
			background-repeat: no-repeat;
			background-size: 50% 100%;
			width: 1200px;
			top: -150px;
			left: 20%;
			background-position: 70%;
			height: 400px;
			position: absolute;
		}
	}
	.auction-top-text-big {
		z-index: 100;
	}
	.auction-top-text {
		z-index: 100;
		line-height: 30px;
		text-align: center;
		font-weight: 400;
	}
}
.hidden-center {
	overflow: hidden;
	display: flex !important;
	justify-content: center !important;
}
.filter_btn_flow {
	flex-flow: row wrap;
	li {
		margin: 5px;
	}
}
.auction_filter_padding {
	padding: 0 15px;
}
.case_dia_filter_len {
	width: 280px !important;
}
.filter_input_flex_box {
	display: flex;
	.input_flex {
		display: flex;
		align-items: center;
	}
}
.filter_scroll_height {
	max-height: 350px;
	overflow: scroll;
}
.one_product_img_wrapper {
	width: 297px;
	height: 297px;
	margin: 17px auto;
	position: relative;
	overflow: hidden;
	img {
		width: 300px;
		height: 400px;
		position: absolute;
		top: -50px;
		left: 0%;
	}
}
.recent_end_timer_wrapper {
	margin: 5px 0 !important;
	font-weight: bold;
	font-size: 14px;
	line-height: 15px;
	color: #ff5353;
	text-decoration: underline;
}
.recent_watch_img {
	width: 74.29px;
	height: 84px;
}
.deadline-wrapper {
	padding: 52px 40px 32px;
}
.deadline_height {
	height: 440px;
}
.deadline_none {
	font-size: 19px;
	margin-top: 100px;
	font-weight: normal;
}
.auction-swiper-slide-plus {
	margin-right: 15px !important;
	position: relative;
	width: 385px;
	height: 250px !important;
}
.product_info_plus {
	padding: 20px 10px !important;
	.plus_text {
		height: 24px;
		font-size: 17px;
	}
	.option_plus {
		display: flex;
	}
}
.slider_img_size {
	width: 180px;
	height: 250px;
}

.auction-detail-block {
	display: block !important;
}
.dimmed_block {
	display: block !important;
}
.btn-margin {
	margin: 3px !important;
}
.watch_list_plus {
	height: 673px !important;
	.on_plus {
		width: 521px !important;
		height: 521px !important;
		position: relative !important;
		overflow: hidden !important;
	}
	.watch_img_plus {
		width: 521px !important;
		height: 692px !important;
		top: -86px !important;
		position: absolute !important;
		left: 0 !important;
	}
}
.on_plus {
	width: 521px !important;
	height: 521px !important;
	position: relative !important;
	overflow: hidden !important;
}
.watch_img_plus {
	width: 521px !important;
	height: 692px !important;
	top: -86px !important;
	position: absolute !important;
	left: 0 !important;
}
.buy_bidding_end {
	background: lightgray !important;
	cursor: default !important;
}
.store-round-space {
	margin: 1px;
}
.table-col-first {
	width: 300px;
}
.table-col-second {
	width: auto;
}
.buy-slider-wrapper-plus {
	width: 600px;
	overflow: hidden;
	height: 90px;
}
.buy_slider_flex {
	display: flex;
	.img_wrapper {
		width: 69px !important;
		height: 69px;
		border: 0;
		overflow: hidden;
		a {
			width: 69px;
			height: 69px;
			position: relative;
			overflow: hidden;
			img {
				width: 69px;
				height: 92px;
				position: absolute;
				top: -10px;
				left: 0;
			}
		}
	}
}

.care_sub_top {
	// background: url(../front-img/auction_top_img_01.png), url(../front-img/auction_top_img_02.jpeg);
	background-color: rgba(0, 0, 0, 1);
	// background-repeat: no-repeat, no-repeat;
	// background-position: right bottom, left top;
	padding: 0 100px;
	background-size: 20% 100%;
	width: 100%;
	position: relative;
	height: 400px;
	display: flex !important;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	color: #fff;
	overflow: hidden;
	.care_sub_top_img {
		background: linear-gradient(
				to right,
				rgba(0, 0, 0, 0.7) 5%,
				rgba(0, 0, 0, 0) 15%,
				rgba(20, 20, 20, 0) 50%,
				rgba(0, 0, 0, 0) 80%,
				rgba(0, 0, 0, 1) 100%
			),
			url(../front-img/007_banner.svg);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		width: 1300px;
		height: 450px;
		position: absolute;
		z-index: 1000;
	}
}
.faq {
	.faq_list div.answer-on {
		/*미현, none에서 block으로 변경함*/
		display: block;
		visibility: visible;
		padding: 40px 20px;
		border-bottom: 1px solid rgba(161, 172, 183, 0.4);
		/* line-height: 1.6; */
		/*미현, 토글 시 부드러운효과 */
		transition: all 100ms ease-in-out;
	}
	.faq_list div.onAnswer-close {
		// display: none;
		// visibility: hidden;
		opacity: 0;
		height: 0;
		padding: 0 20px;
		font-size: 0px;
		transition: all 100ms ease-in-out;
	}
}

.auction_slider_info_s {
	width: 30%;
}
.auction_slider_info_l {
	width: 40%;
}
.reference {
	overflow: hidden;
	text-overflow: ellipsis;
}
.mypage-cancel-info {
	font-family: 'Spoqa Han Sans';
	font-size: 13px;
	font-weight: bold;
	padding: 10px !important;
	text-align: end;
	opacity: 0.7;
}
.rolling-wrapper {
	background: transparent;
	.rolling-scroller {
		display: flex;
		align-items: center;
		position: relative !important;
		width: 100% !important;
	}
	.t2-plus {
		font-size: 13px;
		height: 20px;
		margin-top: 2px;
		display: flex;
		align-items: center;
	}
	.t3 {
		right: 12px;
		font-size: 18px;
		height: 20px;
		bottom: 10px;
		position: absolute;
		color: #dbb58e;
		line-height: 20px;
		text-align: right;
		opacity: 0.9;
	}
}
.main-iframe-title-wrapper {
	width: 1280px !important;
	margin: 80px auto 20px auto !important;
	display: flex !important;
	justify-content: space-between !important;
	.title {
		font-size: 24px;
		font-weight: 500;
	}
}
.main-iframe-content-wrapper {
	background-color: black;
	margin: 5px auto 30px;
	padding-bottom: 18px;
	.iframe-wrapper {
		position: relative;
		width: 1280px;
		height: 720px;
		margin: 0 auto;
		iframe {
			height: 100%;
			border: 0;
			width: 100%;
		}
	}
}
.guarantee-care-wrapper {
	position: relative;
	padding-top: 60px;
	width: 1280px;
	margin: 0 auto;
	.info_list_plus {
		display: flex;
		justify-content: space-between;
		li {
			cursor: pointer;
			height: 310px;
			border: 1px solid lightgray;
			.img_box_plus {
				height: 250px;
				width: 630px;
				overflow: hidden;
				position: relative;
				.guarantee-img {
					width: 100%;
					height: 100%;
				}
				.care-img {
					width: 100%;
					height: 100%;
				}
			}
			.guarantee-bottom-box {
				display: flex;
				align-items: center;
				justify-content: space-between;
				height: 60px;
				padding: 0 20px;
				.text {
					font-size: 21px;
				}
				.more {
					font-size: 15px;
					font-weight: bold;
					opacity: 0.7;
				}
			}
		}
	}
}
.new_youtube_title_wrapper {
	width: 1280px !important;
	margin: 80px auto 20px auto !important;
	display: flex !important;
	justify-content: space-between !important;
	.title {
		font-size: 24px;
		font-weight: 500;
	}
	.more {
		font-weight: bold;
		opacity: 0.7;
	}
}
.new_youtube_wrapper {
	width: 1280px !important;
	height: 310px !important;
	margin: 0px auto 80px auto !important;
	display: flex !important;
	justify-content: space-between !important;
	.img_wrapper {
		width: 418px;
		height: 298px;
		// background-color: black;
		position: relative;
		border: 1px solid lightgray;
		a {
			position: relative;
			width: 420px;
			height: 250px;
			overflow: hidden;
		}
		.btn-img {
			width: 84px;
			position: absolute;
			z-index: 100;
			top: 75px;
			left: 160px;
		}
		.content {
			position: absolute;
			height: 60px;
			width: 418px;
			bottom: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 15px;
			background-color: white;
			border-right: 1px solid lightgray;
		}
	}
}
.boutique-wrapper {
	background: #222527;
	height: 400px;
	// margin: 20px auto 120px;
	margin: 60px auto 0;
	.boutique-content-wrapper {
		width: 1280px;
		display: flex;
		justify-content: space-between;
		margin: 0 auto;
		.title {
			font-size: x-large;
			line-height: 44px;
			font-weight: 300;
			padding-top: 106px;
			margin-left: 60px;
			color: white;
			width: 346px;
			height: 88px;
			.big_text {
				color: #d0ad86;
				font-weight: 700;
			}
			.btn-wrapper {
				margin-top: 26px;
				width: 520px;
				display: flex;
				justify-content: space-between;
			}
		}
	}
	.img-box {
		width: 47%;
	}
}
.info_top_plus {
	width: 1076px !important;
	.preview-img {
		width: 507px !important;
		height: 547px !important;
	}
	.intro_box_plus {
		width: 505px !important;
		padding: 0 !important;
		display: flex !important;
		flex-direction: column !important;
		color: #343a40 !important;
	}
	.intro-title-wrapper {
		height: 42px;
		font-size: 28px;
		font-weight: 500;
		margin-top: 116px;
		margin-bottom: 12px;
		.bold {
			font-weight: 700;
		}
	}
}
.info_mid_plus {
	background-color: white !important;
	width: 1076px !important;
	margin: 0 auto !important;
	.store-slide-img {
		height: 459px !important;
		width: 100% !important;
	}
}
.store-btn-wrapper {
	width: 1076px !important;
	margin: 30px auto !important;
	display: flex !important;
	justify-content: space-between !important;
}
.find-watch-input-text {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex !important;
	-webkit-box-align: center !important;
	-ms-flex-align: center;
	align-items: center !important;
	padding: 0 16px !important;
	height: 48px !important;
	border: 1px solid #c2ccd5 !important;
	-webkit-box-sizing: border-box !important;
	box-sizing: border-box !important;
	font-size: 14px !important;
}

// .auction_sub_top_2 {
// 	background: url(../front-img/auction_top_img_02.jpeg);
// 	background-color: rgba(0, 0, 0, 0.7);
// 	background-repeat: no-repeat;
// 	background-position: 70%;
// 	background-size: 20% 100%;
// 	width: 100%;
// 	height: 240px;
// 	display: flex;
// 	align-items: center;
// 	flex-direction: column;
// 	// 프론트 추가 justify-content => 중앙정렬이 안되서
// 	justify-content: center;
// 	color: #fff;
// 	h1 {
// 		font-size: 36px;
// 		font-weight: 700;
// 	}
// 	p {
// 		margin-top: 10px;
// 		font-size: 16px;
// 		font-weight: 500;
// 	}
// }

// header {
// 	max-width: 1240px;
// 	margin: 0 auto;
// 	position: relative;
// 	background: rgba(247, 249, 251, 1);
// 	&::before {
// 		content: '';
// 		display: block;
// 		width: 100%;
// 		height: 64px;
// 		top: 0;
// 		left: -100%;
// 		position: absolute;
// 		background: rgba(247, 249, 251, 1);
// 	}
// 	&::after {
// 		content: '';
// 		display: block;
// 		width: 200%;
// 		height: 64px;
// 		background: rgba(247, 249, 251, 1);
// 		top: 0;
// 		right: -100%;
// 		position: absolute;
// 	}
// }
.new-popup-img-container {
	position: relative;
}
.new-popup-img {
	position: absolute;
	z-index: 100;
	top: 15px;
}
.kakao-popup {
	left: -178px;
}
.place-popup {
	right: -208px;
}
.new-popup-button {
	position: absolute;
	z-index: 100;
	right: -208px;
	top: 570px;
	width: 200px;
	height: 50px;
	cursor: pointer;
}
