@charset "UTF-8";
.mobile-wrapper {
	.mypage {
		> h1 {
			background-color: #fff;
			padding: 8.889vw 6.667vw 1.111vw;
			color: rgba(49, 56, 63, 1);
			font-size: $f24;
			font-weight: 700;
		}
		.card {
			background-color: #fff;
			border-bottom: 0.278vw solid #edf0f3;
		}
		.profile_info {
			display: flex;
			align-items: center;
			padding: 4.444vw 0 4.444vw 6.667vw;
			background: #fff url(../../img/common/ico_arrow_right.svg) no-repeat right 5.556vw top 50% /
				6.667vw;
			border-bottom: 0.278vw solid #edf0f3;
			margin-bottom: 4.444vw;
			img {
				width: 14.444vw;
			}
			> div {
				margin-left: 3.333vw;
				line-height: 110%;
				span {
					font-size: $f16;
					font-weight: 500;
					color: rgba(49, 56, 63, 1);
				}
				strong {
					font-size: $f16;
					font-weight: 700;
					color: rgba(49, 56, 63, 1);
				}
				b {
					font-size: $f14;
					font-weight: 700;
					color: rgba(161, 172, 183, 1);
					&.auction {
						color: rgba(190, 145, 98, 1);
					}
				}
			}
			&.logout {
				background: #fff url(../../img/common/ic_logout.svg) no-repeat right 5.556vw top 50% /
					6.667vw;
				margin-bottom: 0;
			}
		}
		.card_list {
			background-color: #fff;
			border-bottom: 0.278vw solid #edf0f3;
			border-top: 0.278vw solid #edf0f3;
			margin-bottom: 4.444vw;
			.btn_link {
				padding: 0 0 0 6.667vw;
				> div {
					padding: 4.444vw 0 4.444vw;
					border-bottom: 0.278vw solid rgba(237, 240, 243, 1);
				}
			}
			li {
				&:last-child {
					.btn_link {
						> div {
							border-bottom: 0 solid #000;
						}
					}
				}
			}
		}
		.link_list {
			border-top: 0.278vw solid #edf0f3;
			margin-bottom: 20vw;
			a {
				display: flex;
				align-items: center;
				padding: 4.444vw 0 4.444vw 6.667vw;
				font-size: $f15;
				font-weight: 500;
			}
		}
		.btn_link {
			display: flex;
			align-items: center;
			padding: 4.444vw 0 4.444vw 6.667vw;
			background: #fff url(../../img/common/ico_arrow_right.svg) no-repeat right 5.556vw top 50% /
				6.667vw;
			> div {
				width: 100%;
				color: rgba(49, 56, 63, 1);
				font-weight: 500;
				.t1 {
					font-size: $f16;
				}
				.t2 {
					font-size: $f13;
					opacity: 0.75;
					margin-top: 1.667vw;
				}
			}
		}

		.my_info {
			background-color: #fff;
			padding: 8.333vw 6.667vw 20vw;
			.grade_wrap {
				display: flex;
				justify-content: space-between;
				align-items: center;
				background: #f5f7fa;
				border: 0.278vw solid #edf0f3;
				border-radius: 1.111vw;
				padding: 4.444vw;
				b {
					color: rgba(161, 172, 183, 1);
					font-size: $f20;
					font-weight: 700;
				}
				a {
					display: inline-flex;
					justify-content: center;
					align-items: center;
					font-size: $f14;
					font-weight: 500;
					color: #fff;
					background: #31383f;
					border-radius: 1.111vw;
					padding: 3.333vw 4.444vw;
				}
				&.auction {
					b {
						color: rgba(190, 145, 98, 1);
					}
					a {
						background-color: #d6d9dc;
					}
				}
			}
			h3 {
				font-size: $f14;
				font-weight: 700;
				color: rgba(49, 56, 63, 1);
				padding-bottom: 2.222vw;
				margin: 8.333vw 0 5.556vw;
				border-bottom: 0.278vw solid rgba(237, 240, 243, 1);
				&:first-of-type {
					margin: 0;
					border-bottom: 0 solid #000;
				}
			}
			> .tit {
				font-size: $f13;
				font-weight: 500;
				color: rgba(49, 56, 63, 1);
				margin: 5.556vw 0 2.222vw;
			}
			.btn_modify {
				display: flex;
				justify-content: center;
				align-items: center;
				margin: 11.111vw 0 0;
				width: 100%;
				height: 13.333vw;
				background: #31383f;
				border-radius: 1.111vw;
				font-size: $f16;
				font-weight: 500;
				color: #fff;
			}
			.desc_txt {
				font-size: $f13;
				font-weight: 500;
				color: rgba(49, 56, 63, 1);
				opacity: 0.75;
				margin-top: 5.556vw;
				a {
					text-decoration: underline;
				}
			}
			.check_wrap {
				margin-top: 5.556vw;
				label {
					display: flex;
					align-items: center;
					input {
						margin-right: 2.222vw;
					}
					b {
						font-size: $f14;
						font-weight: 700;
						color: #3d454d;
					}
					span {
						font-size: $f14;
						font-weight: 500;
						color: #3d454d;
					}
					+ label {
						margin-top: 5.556vw;
					}
				}
				label + label {
					margin-top: 3.333vw;
				}
			}
			.input_div {
				input + input {
					margin-top: 2.222vw;
				}
			}
			.error_txt {
				margin-top: 5.556vw;
				padding-top: 3.333vw;
				color: rgba(255, 83, 83, 1);
				font-size: $f13;
				border-top: 0.278vw solid rgba(237, 240, 243, 1);
			}
		}

		> .auction {
			background-color: #fff;
			padding: 6.667vw;
			.text_box {
				background: #f5f7fa;
				border: 0.278vw solid #edf0f3;
				border-radius: 1.111vw;
				padding: 4.444vw;
				margin-bottom: 8.333vw;
				.t1 {
					font-size: $f13;
					font-weight: 500;
					color: rgba(49, 56, 63, 1);
					opacity: 0.75;
					b {
						font-weight: 700;
					}
				}
				.t2 {
					margin-top: 4.444vw;
					font-size: $f13;
					font-weight: 700;
					color: rgba(49, 56, 63, 1);
				}
			}
			.tit {
				font-size: $f13;
				font-weight: 500;
				color: #31383f;
				margin: 5.556vw 0 2.222vw;
				span {
					color: #ff5353;
					margin-left: 0.278vw;
				}
			}
			h3 {
				margin-top: 8.333vw;
				font-size: $f14;
				font-weight: 700;
				color: rgba(49, 56, 63, 0.75);
			}
			.deposit_box {
				margin-top: 2.778vw;
				background: #f5f7fa;
				border: 0.278vw solid #edf0f3;
				border-radius: 1.111vw;
				padding: 4.444vw;
				ul {
					li {
						display: flex;
						align-items: center;
						font-size: $f14;
						margin-bottom: 4.444vw;
						&:last-child {
							margin-bottom: 0;
						}
						span {
							width: 35%;
							color: rgba(49, 56, 63, 0.6);
						}
						b {
							width: 65%;
							color: rgba(49, 56, 63, 1);
							font-weight: 700;
						}
					}
				}
			}
			.error_txt {
				margin-top: 5.556vw;
				padding-top: 3.333vw;
				color: rgba(255, 83, 83, 1);
				font-size: $f13;
				border-top: 0.278vw solid rgba(237, 240, 243, 1);
			}
			.btn_ok {
				display: flex;
				justify-content: center;
				align-items: center;
				margin: 20vw 0 0;
				width: 100%;
				height: 13.333vw;
				background: #31383f;
				border-radius: 1.111vw;
				font-size: $f16;
				font-weight: 500;
				color: #fff;
			}
		}

		> .history {
			background-color: #fff;
			padding: 5.833vw 6.667vw;
			.tab_wrap {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				padding: 1.111vw;
				background: #f5f7fa;
				border: 0.278vw solid #edf0f3;
				border-radius: 1.111vw;
				a {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 48%;
					height: 11.667vw;
					font-size: $f14;
					font-weight: 500;
					color: rgba(49, 56, 63, 1);
					&.on {
						background-color: #fff;
						border: 0.278vw solid #edf0f3;
						box-shadow: 0 0.556vw 2.222vw rgba(0, 0, 0, 0.05);
						border-radius: 1.111vw;
					}
				}
			}
			.txt {
				margin-top: 6.667vw;
				text-align: right;
				font-size: $f12;
				font-weight: 500;
				padding-bottom: 3.333vw;
				border-bottom: 0.278vw solid rgba(237, 240, 243, 1);
				color: rgba(49, 56, 63, 0.75);
			}
			.history_list {
				li {
					position: relative;
					border-bottom: 0.278vw solid rgba(237, 240, 243, 1);
					padding: 5vw 0;
					.date {
						color: rgba(49, 56, 63, 0.75);
						font-size: $f13;
						font-weight: 500;
					}
					.watch_name {
						font-weight: 700;
						color: rgba(49, 56, 63, 1);
						font-size: $f14;
						margin-top: 3.333vw;
					}
					dl {
						margin-top: 5.556vw;
						div {
							display: flex;
							align-items: center;
							font-size: $f13;
							&:first-child {
								margin-top: 2.222vw;
							}
						}
					}
					dt {
						color: rgba(161, 172, 183, 1);
						width: 25%;
					}
					dd {
						color: rgba(49, 56, 63, 1);
						width: 75%;
						b {
							font-weight: 700;
							&.sell {
								color: rgba(255, 83, 83, 1);
							}
						}
					}
					button.status {
						display: inline-flex;
						align-items: center;
						position: absolute;
						right: 0;
						top: 3.333vw;
						border: 0.278vw solid #31383f;
						padding: 0 2.778vw;
						border-radius: 27.778vw;
						height: 7.222vw;
						font-size: $f12;
						font-weight: 500;
						&.cancel {
							opacity: 0.5;
						}
					}
					span.status {
						position: absolute;
						right: 0;
						top: 3.333vw;
						font-size: $f13;
						font-weight: 500;
						&.stay {
							color: rgba(58, 173, 104, 1);
						}
						&.end {
							color: rgba(170, 180, 196, 1);
						}
						&.ing {
							color: rgba(89, 96, 102, 1);
						}
					}
				}
			}
		}

		.filter_managemenr {
			padding-bottom: 23.333vw;
			background-color: #fff;
			.category_tit {
				display: flex;
				justify-content: space-between;
				align-items: center;
				background: #f5f7fa;
				padding: 6.667vw 5.278vw 3.333vw 6.667vw;
				border-bottom: 0.278vw solid rgba(237, 240, 243, 1);
				font-size: $f14;
				color: rgba(49, 56, 63, 1);
				b {
					font-weight: 700;
				}
				span {
					font-weight: 500;
				}
			}
			.btn_wrap {
				position: fixed;
				left: 0;
				bottom: 0;
				z-index: 100;
				height: 23.333vw;
				padding: 4.444vw 5.556vw 5.556vw;
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 100%;
				background-color: #fff;
				border-top: 0.278vw solid rgba(237, 240, 243, 1);
				button {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 48%;
					height: 13.333vw;
					background: #31383f;
					border-radius: 1.111vw;
					font-size: $f16;
					font-weight: 500;
					color: #fff;
					&.btn_cancel {
						color: rgba(49, 56, 63, 1);
						background: #f5f7fa;
					}
				}
			}
			.filter_list {
				li {
					padding-left: 6.667vw;
					label {
						display: flex;
						justify-content: space-between;
						align-items: center;
						border-bottom: 1px solid #edf0f3;
						padding: 4.444vw 6.667vw 4.444vw 0;
						span {
							font-size: $f15;
							color: #31383f;
							font-weight: 500;
						}
					}
				}
			}
		}

		.keyword_wrap {
			padding: 5.556vw 6.667vw;
			background-color: #fff;
			border-bottom: 0.278vw solid #edf0f3;
			.search_box {
				position: relative;
				button {
					position: absolute;
					right: 0;
					top: 0;
					border-top-right-radius: 1.111vw;
					border-bottom-right-radius: 1.111vw;
					width: 21.111vw;
					height: 13.333vw;
					background: #31383f;
					color: #fff;
					font-size: $f16;
				}
			}
			p {
				margin-top: 2.222vw;
				font-size: $f13;
				color: #31383f;
				opacity: 0.75;
				font-weight: 500;
			}
		}

		.keyword_list {
			.tit {
				display: flex;
				padding: 6.667vw 6.667vw 3.333vw;
				font-size: $f14;
				color: #31383f;
				border-bottom: 0.278vw solid #edf0f3;
				b {
					font-weight: 700;
				}
				span {
					opacity: 0.75;
				}
				.btn_del {
					margin-left: auto;
					font-size: $f13;
					font-weight: 500;
					color: #112211;
					opacity: 0.5;
					text-decoration: underline;
				}
			}
			> ul {
				background-color: #fff;
				padding: 0 0 20vw 6.667vw;
				li {
					padding: 4.722vw 0;
					font-size: $f15;
					font-weight: 500;
					border-bottom: 0.278vw solid #edf0f3;
					position: relative;
					button {
						display: block;
						width: 5.556vw;
						height: 5.556vw;
						position: absolute;
						right: 6.667vw;
						top: 50%;
						transform: translateY(-50%);
						background: url(../../img/common/ic_close_x2.svg) no-repeat 0 0 / 5.556vw;
					}
				}
			}
		}

		.branch_top {
			background: #343a40;
			width: 100%;
			height: 55.556vw;
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			img {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
			}
			p {
				position: relative;
				z-index: 1;
				color: #fff;
				font-weight: 500;
				text-align: center;
				line-height: 1.6;
				&.t1 {
					font-size: $f20;
				}
				&.t2 {
					font-size: $f14;
				}
			}
		}

		.branch_tab {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 5.556vw;
			a {
				font-size: $f14;
				color: #31383f;
				font-weight: 700;
				opacity: 0.5;
				padding: 2.5vw 0;
				position: relative;
				&.on {
					opacity: 1;
					&::after {
						content: '';
						display: block;
						width: 100%;
						height: 0.556vw;
						background: #d1ad86;
						position: absolute;
						left: 0;
						bottom: 0;
					}
				}
			}
		}

		.branch_img {
			//프론트 : height 주석, width 100%추가
			// height: 69.444vw;
			width: 100%;
			.swiper-slide img {
				width: 100%;
			}
			.swiper-pagination-bullet {
				width: 4.444vw;
				height: 0.5vw;
				display: inline-block;
				border-radius: 100%;
				background: #fff;
				opacity: 0.3;
			}
			.swiper-pagination-bullet-active {
				opacity: 0.75;
			}
		}

		.greetings {
			padding: 13.056vw 5vw;
			h3 {
				font-size: $f20;
				color: #31383f;
				font-weight: 400;
				margin-bottom: 6.111vw;
				span {
					color: #be9162;
				}
			}
			p {
				font-size: $f14;
				color: #000;
				margin-bottom: 4.167vw;
				font-weight: 400;
			}
		}

		.branch_intro {
			padding: 0 4.722vw 18.611vw;
			.t1 {
				font-size: $f13;
				font-weight: 500;
				color: #be9162;
				margin-bottom: 1.667vw;
			}
			h3 {
				font-size: $f20;
				color: #31383f;
				font-size: 400;
				margin-bottom: 1.667vw;
			}
			.adress {
				font-size: $f13;
				color: #596066;
				font-weight: 500;
			}

			.map_box {
				position: relative;
				margin-top: 7.778vw;
				padding-top: 75%;
				iframe {
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
				}
			}
			ul {
				margin-top: 13.333vw;
				font-size: $f13;
				li {
					display: flex;
					margin-bottom: 5.556vw;
					&:last-child {
						margin-bottom: 0;
					}
					> b {
						width: 21.111vw;
						color: #000;
						font-weight: 500;
					}
					span {
						color: #596066;
					}
					p {
						margin-bottom: 2.778vw;
						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}
		}

		.notice_list {
			background-color: #fff;
			padding-bottom: 20vw;
			ul {
				padding-left: 6.667vw;
				li {
					border-bottom: 1px solid #edf0f3;
					padding-right: 6.667vw;
					a {
						display: flex;
						width: 100%;
						padding: 6.111vw 0;
						flex-wrap: wrap;
					}
					.tit {
						width: 100%;
						margin-bottom: 4.444vw;
						font-size: $f15;
						color: #31383f;
						font-weight: 500;
					}
					.date {
						span {
							font-size: $f13;
							color: #636d77;
							opacity: 0.75;
							font-weight: 500;
							&.new {
								font-size: $f12;
								color: #ff5d5d;
								margin-left: 2.222vw;
								padding-left: 2.222vw;
								position: relative;
								opacity: 1;
								&::before {
									content: '';
									display: block;
									width: 1.111vw;
									height: 1.111vw;
									border-radius: 100%;
									background: #ff5d5d;
									position: absolute;
									left: 0;
									top: 50%;
									transform: translateY(-50%);
								}
							}
						}
					}
				}
			}
		}

		.notice_view {
			background-color: #fff;
			padding: 0 6.667vw 10vw;
			.view_top {
				padding: 6.111vw 0 4.444vw;
				border-bottom: 1px solid #edf0f3;
				.tit {
					width: 100%;
					margin-bottom: 4.444vw;
					font-size: $f15;
					color: #31383f;
					font-weight: 500;
				}
				.date {
					span {
						font-size: $f13;
						color: #636d77;
						opacity: 0.75;
						font-weight: 500;
						&.new {
							font-size: $f12;
							color: #ff5d5d;
							margin-left: 2.222vw;
							padding-left: 2.222vw;
							position: relative;
							opacity: 1;
							&::before {
								content: '';
								display: block;
								width: 1.111vw;
								height: 1.111vw;
								border-radius: 100%;
								background: #ff5d5d;
								position: absolute;
								left: 0;
								top: 50%;
								transform: translateY(-50%);
							}
						}
					}
				}
			}
			.view_cont {
				padding: 5.556vw 0;
				p {
					font-size: $f14;
					color: #31383f;
					opacity: 0.75;
					margin-bottom: 5.556vw;
					line-height: 1.6;
				}
			}
		}

		.faq_list {
			background-color: #fff;
			padding: 6.667vw 0 10vw 6.667vw;
			.tit {
				font-size: $f20;
				font-weight: 700;
				color: #31383f;
			}
			ul {
				margin-top: 3.333vw;
				li {
					a {
						display: flex;
						align-items: center;
						padding: 5.556vw 0 5.556vw 6.944vw;
						font-size: $f16;
						font-weight: 500;
						color: #31383f;
						border-bottom: 0.278vw solid #edf0f3;
						width: 100%;
						background: url(../../img/common/ico_arrow_right.svg) no-repeat right 5.556vw top 50% /
							6.667vw;
						position: relative;
						box-sizing: border-box;
						line-height: 1.4;
						&::before {
							content: 'Q.';
							color: #be9162;
							font-weight: 700;
							opacity: 0.8;
							margin-right: 2.222vw;
							position: absolute;
							left: 0;
							top: 5.556vw;
						}
					}
				}
			}
		}
		.faq_view {
			background-color: #fff;
			padding: 6.667vw 0 10vw 6.667vw;
			.tit {
				font-size: $f20;
				font-weight: 700;
				color: #31383f;
				margin-bottom: 5.556vw;
				line-height: 1.4;
			}
			.txt {
				font-size: $f14;
				color: #31383f;
				opacity: 0.75;
				margin-bottom: 5.556vw;
				line-height: 1.6;
			}
		}

		.setting_list {
			background-color: #fff;
			padding-bottom: 10vw;
			border-top: 0.278vw solid #edf0f3;
			margin-top: 3.333vw;
			.switch {
				display: inline-block;
				height: 8.889vw;
				position: relative;
				width: 14.722vw;
			}

			.switch input {
				display: none;
			}

			.slider {
				background-color: #f5f7fa;
				border: 0.278vw solid #edf0f3;
				bottom: 0;
				cursor: pointer;
				left: 0;
				position: absolute;
				right: 0;
				top: 0;
				transition: 0.4s;
			}

			.slider:before {
				background-color: #fff;
				top: 50%;
				content: '';
				height: 7.222vw;
				left: 1.111vw;
				position: absolute;
				transition: 0.4s;
				width: 7.222vw;
				box-shadow: 0 0.556vw 2.222vw 0.556vw rgba(0, 0, 0, 0.05);
				border: 0.278vw solid #edf0f3;
				transform: translateY(-50%);
			}

			input:checked + .slider {
				background-color: #31383f;
			}

			input:checked + .slider:before {
				transform: translate(4.889vw, -50%);
			}

			.slider.round {
				border-radius: 27.778vw;
			}

			.slider.round:before {
				border-radius: 50%;
			}
			ul {
				padding-left: 6.667vw;
				li {
					display: flex;
					align-items: center;
					height: 17.778vw;
					border-bottom: 0.278vw solid #edf0f3;
					font-size: $f16;
					font-weight: 500;
					> a {
						display: flex;
						justify-content: space-between;
						width: 100%;
						height: 100%;
						align-items: center;
						background: url(../../img/common/ico_arrow_right.svg) no-repeat right 5.556vw top 50% /
							6.667vw;
						padding-right: 14.444vw;
						div {
							font-size: $f18;
							color: rgba(49, 56, 63, 0.75);
							.new {
								font-size: $f12;
								color: #ff5d5d;
							}
						}
					}
					> div {
						margin: 0 6.667vw 0 auto;
					}
				}
			}
		}

		.version_info {
			background-color: #fff;
			padding: 36.944vw 0 49.167vw;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			.img_logo {
				width: 55.556vw;
				margin-bottom: 4.444vw;
			}
			.t1 {
				font-size: $f16;
				color: #31383f;
				opacity: 0.6;
				margin-bottom: 4.444vw;
			}
			.t2 {
				font-size: $f24;
				color: #31383f;
				margin-bottom: 2.222vw;
			}
			.t3 {
				font-size: $f16;
				color: #31383f;
				opacity: 0.8;
				margin-bottom: 10vw;
			}
			.btn_update {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 43.333vw;
				height: 13.333vw;
				font-weight: 500;
				color: #fff;
				font-size: $f16;
				background: #31383f;
				border-radius: 1.111vw;
				&.latest {
					background-color: #d6d9dc;
				}
			}
		}
	}
}
