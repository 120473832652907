.mobile-hapl-section {
	font-family: 'Roboto';
	font-style: normal;
	padding: 12.5vw 0vw 10vw;
	color: #31383f;
	.img-section {
		.title {
			padding: 0 0 5vw 5vw;
		}
		.img-wrapper {
			position: relative;
			img {
				width: 100%;
			}
			.bg {
				background-color: rgba(1, 1, 1, 0.2);
				width: 100%;
				height: 40vw;
				position: absolute;
				bottom: 0;
			}
			.first {
				color: white;
				text-align: center;
				font-size: 7vw;
				height: 14vw;
				margin: 5vw 0 4.75vw 0;
			}
			.second {
				color: white;
				text-align: center;
				font-size: 5vw;
				height: 17.5vw;
			}
		}
	}
	.reserve-section {
		display: flex;
		padding: 5vw;
		justify-content: space-between;
		.part {
			width: 28vw;

			font-size: 3.556vw;

			border-radius: 5px;

			.head {
				height: 7vw;

				background: #1c1c1c;
				border-radius: 5px;
				color: white;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			.body {
				.list {
					height: 21px;

					font-family: 'NanumGothic';
					font-weight: 400;
					text-align: center;
					font-size: 3.2vw;

					color: #000000;
					margin: 22px auto;
				}
			}
		}
	}
}
.other_list_paging {
	position: static;
	width: 100%;
	.swiper-pagination-bullet {
		width: 4.803vw;
		height: 1.772vw;
		border-radius: 0;
		margin: 0 1.111vw;
	}
	.swiper-pagination-bullet-active {
		background: #31383f;
	}
}
.review_list_paging {
	position: static;
	width: 100%;
	.swiper-pagination-bullet {
		width: 4.803vw;
		height: 1.772vw;
		border-radius: 0;
		margin: 0 1.111vw;
	}
	.swiper-pagination-bullet-active {
		background: #31383f;
	}
}

.title {
	padding: 5vw;
}
.review {
	padding: 5vw;
}
.other {
	margin-top: 5vw;
}
.review_list {
	padding: 5vw;
	.swiper-slide {
		width: 54.5vw;
		margin-right: 7vw;
	}
	.one-review {
		// width: 100vw !important;
		img {
			width: 54.5vw;
			height: 76.1589vw;
		}
		.desc {
			width: 54.5vw;
			margin-top: 5vw;
			font-size: 3.556vw;
			line-height: 5vw;
		}
	}
}
