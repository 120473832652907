@charset "UTF-8";
.mobile-wrapper {
	.join {
		background-color: #fff;
		.join_agree {
			padding: 6.667vw;
			h1 {
				font-size: $f20;
				font-weight: 700;
				color: #31383f;
			}
			.t1 {
				font-size: $f14;
				font-weight: 500;
				line-height: 157%;
				color: #343a40;
				opacity: 0.75;
				margin: 2.222vw 0 5.556vw;
				padding-bottom: 3.333vw;
				border-bottom: 1px solid #edf0f3;
			}
			.check_wrap {
				margin-top: 4.444vw;
				label {
					display: flex;
					align-items: center;
					input {
						margin-right: 2.222vw;
					}
					b {
						font-size: $f14;
						font-weight: 700;
						color: #3d454d;
					}
					span {
						font-size: $f14;
						font-weight: 500;
						color: #3d454d;
					}
					+ label {
						margin-top: 5.556vw;
					}
				}
			}
			.scroll_box {
				margin-top: 3.333vw;
				height: 38.889vw;
				overflow-y: auto;
				background: #ffffff;
				border: 0.278vw solid #e7ecf1;
				border-radius: 0.556vw;
				padding: 3.333vw;
				p {
					font-size: $f12;
					line-height: 150%;
					color: rgba(0, 0, 0, 0.5);
					margin-bottom: 3.333vw;
					font-weight: 500;
				}
			}
			.txt_error {
				font-size: $f13;
				font-weight: 500;
				color: #ff5353;
				margin-top: 4.444vw;
			}
			.btn_join {
				display: flex;
				justify-content: center;
				align-items: center;
				margin: 11.111vw 0 0;
				width: 100%;
				height: 13.333vw;
				background: #31383f;
				border-radius: 1.111vw;
				font-size: $f16;
				font-weight: 500;
				color: #fff;
			}
			.join_list {
				.tit {
					font-size: $f13;
					font-weight: 500;
					color: #31383f;
					margin: 5.556vw 0 2.222vw;
					span {
						color: #ff5353;
						margin-left: 0.278vw;
					}
				}
				.input_div {
					input + input {
						margin-top: 2.222vw;
					}
				}
				.check_wrap {
					margin-top: 5.556vw;
					label + label {
						margin-top: 3.333vw;
					}
				}
				.captcha_box {
					margin-top: 7.778vw;
					p {
						font-size: $f12;
						font-weight: 500;
						color: #343a40;
					}
					.captcha_wrap {
						display: flex;
						border: 0.278vw solid #77797b;
						border-radius: 1.111vw;
						height: 23.056vw;
						margin: 2.222vw 0;
						.img_box {
							width: 60%;
							display: flex;
							justify-content: center;
							align-items: center;
							border-right: 0.278vw solid #77797b;
							img {
								display: block;
								width: 78%;
							}
						}
						.btn_wrap {
							width: 40%;
							display: flex;
							flex-wrap: wrap;
							button {
								display: flex;
								width: 100%;
								font-size: $f12;
								color: rgba(49, 56, 63, 0.75);
								padding-left: 11.111vw;
								font-weight: 500;
								background: url(../../img/common/ic_voice.svg) no-repeat 2.778vw 50% / 6.667vw;
								&:nth-child(2) {
									border-top: 0.278vw solid #77797b;
									background: url(../../img/common/ic_refresh.svg) no-repeat 2.778vw 50% / 6.667vw;
								}
							}
						}
					}
				}
			}

			.txt {
				font-size: $f14;
				color: rgba(52, 58, 64, 1);
				opacity: 0.75;
			}
		}
	}
}
