/*미현 - 추가*/
.paging .num {
	color: #be9162;
}
/*미현, 페이지네이션 색*/
.faq .faq_list .paging .num {
	color: rgba(190, 146, 98, 0.75);
}
.faq .faq_list div.answer {
	/*미현, none에서 block으로 변경함*/
	display: block;
	padding: 40px 20px;
	border-bottom: 1px solid rgba(161, 172, 183, 0.4);
	/* line-height: 1.6; */
	/*미현, 토글 시 부드러운효과 */
	transition: all 200ms ease-in-out;
}
/*미현, 토글 시 부드러운효과 */
.faq .faq_list div.onAnswer {
	display: none;
	opacity: 0;
	padding: 0 20px;
	font-size: 0px;
	transition: all 200ms ease-in-out;
}
/*영광 mainslider dots custom*/
.main .custom {
	position: absolute;
	left: 100px;
	bottom: 81px;
	z-index: 1;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.main .custom li {
	font-size: 12px;
	font-family: 'Popins';
	color: rgba(43, 48, 53, 0.5);
	font-weight: 600;
}
.main .custom li.active {
	color: #2b3035;
}
