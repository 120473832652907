.mobile-hapl-wrapper {
	.select-store {
		height: 10vw;
		margin: 10px;
		background: #ffffff;
		border: 1px solid #c4c4c4;
		font-family: Roboto;
		box-sizing: border-box;
		border-radius: 2px;
		position: relative;
		cursor: pointer;
	}
	.selected-name {
		position: absolute;
		left: 4vw;
		top: 2.5vw;

		font-family: Roboto;
		font-style: normal;
		font-weight: 300;
		font-size: 4vw;
		line-height: 19px;
		/* identical to box height */

		color: #000000;
	}
	.triangle-img {
		position: absolute;
		width: 3vw;
		height: 3vw;
		right: 3vw;
		background: url(../../assets-pc/img/common/btn_paging_prev.svg) no-repeat 50% 50%;
		top: 3vw;
		transform: rotate(-90deg);
	}
	.up {
		transform: rotate(-270deg);
	}
	.select-list {
		position: absolute;
		width: 94vw;
		top: 10vw;
		padding: 4vw 4vw;
		font-family: Roboto;

		background: #ffffff;
		border: 1px solid #c4c4c4;
		box-sizing: border-box;
		overflow: scroll;
		.oneSelect {
			width: 100%;
			font-family: Roboto;
			font-style: normal;
			font-weight: 300;
			font-size: 4vw;
			line-height: 25px;

			color: #828282;
			cursor: pointer;
			&:hover {
				font-weight: 400;
			}
		}
	}
	.btn_back {
		top: 25%;
	}
	.top-photo-box {
		background-image: url(../../assets-pc/front-img/m-hapl-bg.png);
		height: 56vw;
		background-repeat: no-repeat;
		background-size: cover;
		width: 100vw;
		position: relative;
		.top-photo {
			position: absolute;
			height: 56vw;
			width: 100%;
		}
		.title-box {
			width: 100%;
			display: flex;
			justify-content: center;
		}
		.big-title {
			color: white;
			position: absolute;
			font-weight: 600;
			top: 32%;
			font-family: Roboto;
			font-size: 5.5556vw;
			.normal {
				font-weight: 300;
			}
		}
		.small-title {
			color: white;
			position: absolute;
			font-weight: 600;
			font-size: 3.8889vw;
			font-family: Roboto;
			top: 47%;
		}
		.small-title2 {
			top: 56%;
			color: white;
			position: absolute;
			font-size: 3.8889vw;
			font-weight: 600;
			font-family: Roboto;
		}
		.btn-wrapper {
			position: absolute;
			width: 100%;
			top: 68%;
		}
		.btn-box {
			// padding-top: 120px;
			display: flex;
			justify-content: center;
			.reservation-btn {
				background-color: rgba(126, 150, 185, 1);

				// height: 54px;
				width: 30vw;
				left: 0px;
				top: 0px;
				border-radius: 0px;
				margin-right: 2px;
				position: relative;
				.content {
					position: absolute;
					left: 8.75%;
					top: 44.04%;

					font-family: Roboto;
					font-weight: normal;
					font-size: 3.4vw;
					/* identical to box height */

					display: flex;
					align-items: center;
					text-align: center;

					color: #fcfcfc;
				}
				.line1 {
					position: absolute;
					left: 9.75%;
					right: 5.21%;
					top: 85.19%;
					bottom: 14.81%;
					width: 84%;

					border: 1px solid rgba(255, 255, 255, 0.46);
				}
				.line2 {
					position: absolute;
					left: 84%;
					right: 4%;
					top: 75.61%;
					bottom: 33.33%;

					border: 1px solid rgba(255, 255, 255, 0.46);
					transform: rotate(37.57deg);
				}
			}
			.introduce-btn {
				background-color: rgba(145, 151, 207, 1);
				height: 11vw;
				width: 28vw;
				left: 0px;
				top: 0px;
				border-radius: 0px;
				margin-left: 2px;
				position: relative;
				.content {
					position: absolute;
					left: 8.75%;
					top: 44.04%;

					font-family: Roboto;
					font-weight: normal;
					font-size: 3.4vw;
					/* identical to box height */

					display: flex;
					align-items: center;
					text-align: center;
					color: rgba(255, 255, 255, 0.5);
				}
				.line1 {
					position: absolute;
					left: 9.75%;
					right: 5.21%;
					top: 85.19%;
					bottom: 14.81%;
					width: 84%;

					border: 1px solid rgba(255, 255, 255, 0.46);
				}
				.line2 {
					position: absolute;
					left: 84%;
					right: 4%;
					top: 75.61%;
					bottom: 33.33%;

					border: 1px solid rgba(255, 255, 255, 0.46);
					transform: rotate(37.57deg);
				}
			}
			.left-top-logo {
				position: absolute;
				// left: 5.21%;
				right: 5.21%;
				top: 12.96%;
				bottom: 64.81%;

				font-family: Roboto;
				font-style: normal;
				font-weight: normal;
				font-size: 1.88vw;
				line-height: 12px;
				/* identical to box height */

				display: flex;
				align-items: center;
				text-align: center;

				color: rgba(252, 252, 252, 0.3);
			}
		}
	}
	.all-store {
		height: 10vw;
		background-color: #ffffff;
		border: 1px solid #b9a07e;
		border-radius: 5px;
		color: #b9a07e;
		margin: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 4vw;
	}
	.store-box {
		display: flex;
		justify-content: space-around;
		button {
			background: linear-gradient(108.45deg, #d7bda1 37.49%, #b08c71 81.87%);
			border-radius: 5px;
			width: 30vw;
			height: 10vw;
			margin: 1vw;
			font-size: 3vw;
			color: white;
		}
		.selected-store {
			background: #574b3a;
		}
	}
	.bar {
		border: 2px solid #d7bda1;
		margin: 19px 10px 10px 10px;
	}
	.list-box {
		margin: 10px;
		font-size: 2.556vw;
		.list {
			width: 100vw;
			height: 12vw;
			display: flex;
			align-items: center;
			border-bottom: 1px solid #edf0f3;
			.name {
				width: 12%;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
			}
			.store-name {
				width: 23%;
				text-align: center;
			}
			.day {
				width: 20%;
				text-align: center;
			}
			.time-str {
				width: 20%;
				text-align: center;
			}
			.arrow {
				width: 3vw;
				height: 6vw;
				background: url(../../assets-mobile/img/arrow-right.svg) no-repeat 0 0 / cover;
			}
			.mini-bar {
				border: 1px solid #cecece;
				height: 7px;
				width: 2px;
			}
		}
	}
	.paging-box {
		font-size: 3vw;
		margin-top: 7vw;
		margin-bottom: 15vw;
	}

	// detail
	.detail-title {
		border-top: 2px solid #000000;
		border-bottom: 1px solid #cecece;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #f9fafb;
		margin-top: 2vw;
		height: 14vw;
		font-size: 4.8vw;
	}
	.detail-wrapper {
		margin: 10px;
		.img-wrapper {
			img {
				width: 95vw;
				height: 50vw;
				margin-bottom: 20px;
			}
		}
		.reservation-info {
			.title {
				margin-top: 12vw;
				margin-bottom: 5vw;
			}
			.tbl {
				width: 95vw;
				border-top: 1px solid #31383f;
				border-radius: 1px;
				.table-col-first {
					width: 30%;
				}
				.table-col-second {
					width: 70%;
				}
				thead {
					border-top: 2px solid black;
				}
				th,
				td {
					border-bottom: 1px solid rgba(161, 172, 183, 0.35);
					text-align: left;
					font-size: 4vw;
					font-weight: 500;
				}
				td {
					padding-left: 24px;
				}
				th {
					border-right: 1px solid rgba(161, 172, 183, 0.35);
				}
				td:nth-child(1n) {
				}
				tr {
					height: 20vw;
					th {
						height: 20vw;
					}
				}

				th {
					font-weight: 500;
					background: rgba(249, 250, 251, 1);
					display: flex;
					justify-content: center;
					align-items: center;
				}
				.long {
					height: 50vw;
				}
			}
			.notice {
				color: rgba(185, 160, 126, 1);
				font-size: 4vw;
				margin: 4vw 2vw;
				// display: flex;
				// justify-content: center;
				// align-content: center;
			}
			.brown-text {
				color: rgba(185, 160, 126, 1);
			}
		}
		.btn-box {
			margin-bottom: 10vw;
			button {
				margin-top: 4vw;
				width: 95vw;
				height: 12vw;
				border-radius: 5px;
			}
			.cancel {
				background: #3865e0;
				color: white;
			}
			.change {
				background: rgba(170, 156, 224, 1);
				color: white;
			}
			.list {
				background: white;
				border: 1px solid #b9a07e;
				color: #b9a07e;
			}
		}
	}
	.step1-numbering {
		width: 100vw;
		height: 30vw;
		margin: 6vw;
		position: relative;
		.light-brown {
			width: 13vw;
			height: 13vw;

			background: #b9a07e;
			border: 10px solid #ded6cb;
			box-sizing: border-box;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;

			margin-left: 18vw;
		}
		.num {
			width: 7px;
			height: 19px;

			font-family: Roboto;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 19px;
			/* identical to box height */

			display: flex;
			align-items: center;

			color: #ffffff;
		}
		.line {
			width: 24vw;
			height: 0px;

			border: 1px solid #dfdfdf;
			position: absolute;
			left: 30vw;
			top: 7vw;
		}
		.light-gray {
			position: absolute;
			width: 13vw;
			height: 13vw;

			background: #898989;
			border: 10px solid #e5e5e5;
			box-sizing: border-box;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			left: 54vw;
			top: 0px;
		}
		.step1-text {
			position: absolute;
			bottom: 10vw;
			left: 13vw;

			font-family: Roboto;
			font-style: normal;
			font-weight: 500;
			font-size: 4vw;
			/* identical to box height */

			display: flex;
			align-items: center;

			color: #b9a07e;
		}
		.step2-text {
			position: absolute;
			height: 21px;
			right: 32vw;
			bottom: 8vw;

			font-family: Roboto;
			font-style: normal;
			font-weight: 500;
			font-size: 3.9vw;
			/* identical to box height */

			display: flex;
			align-items: center;

			color: #000000;
		}
	}
	.reservation {
		.mini-title {
			border-bottom: 1px solid #cecece;
			padding: 6vw 0 2vw 3vw;
			font-size: 4vw;
		}
		.notice {
			background: #ffffff;
			border: 3px solid #edf0f3;
			box-sizing: border-box;
			border-radius: 10px;
			position: relative;
			margin: 4vw;
			height: 51vw;
			.purple-icon {
				width: 6vw;
				height: 6vw;
				border-radius: 50%;
				background: #aa9ce0;
				position: absolute;
				margin-left: 6.5vw;
				margin-top: 5.5vw;
			}
			span {
				color: white;
				position: absolute;
				font-size: 4vw;
				width: 3vw;
				height: 6vw;
				margin-left: 9vw;
				margin-top: 6.5vw;
			}

			.title {
				font-size: 4vw;
				/* identical to box height */

				display: flex;
				align-items: center;

				color: #000000;
				margin-left: 15vw;
				margin-top: 7vw;
			}
			p {
				width: 80vw;
				margin-left: 5vw;
				margin-top: 10vw;
				height: 14vw;

				font-size: 3vw;
				line-height: 6vw;
				display: flex;
				align-items: center;

				color: #000000;
			}
		}
		.calendar {
			.control {
				margin-top: 4vw;
				.left {
					display: flex;
					align-items: center;
					margin: 2vw;
					button {
						font-weight: 900;
						font-size: 3vw;
					}
					span {
						height: 5vw;
						font-weight: 600;
						font-size: 4vw;
						display: flex;
						align-items: center;
						text-align: right;
					}
					.year-month {
						margin-left: 3vw;
						margin-right: 3vw;
					}
					.desc {
						font-weight: 500;
						font-size: 3.4vw;
						margin-left: 4vw;
					}
				}
				.right {
					display: flex;
					align-items: center;
					justify-content: space-around;
					margin-top: 4vw;
					margin-bottom: 4vw;
					position: relative;
					.not-possible {
						width: 23vw;
						height: 9vw;
						color: #3865e0;
						background: #d7e0f9;
						font-size: 3.8vw;

						border-radius: 5px;
						display: flex;
						align-items: center;
						justify-content: center;
					}
					.possible {
						width: 15vw;
						height: 9vw;
						color: #ffffff;
						background: #3865e0;
						font-size: 3.8vw;

						border-radius: 5px;
						display: flex;
						align-items: center;
						justify-content: center;
					}
					.select {
						width: 50vw;
						height: 9vw;
						color: #ffffff;
						background: #3865e0;
						font-size: 3.8vw;

						border-radius: 5px;
						display: flex;
						align-items: center;
						justify-content: center;
						position: relative;
					}
					.triangle {
						position: absolute;
						right: 2vw;
						width: 5vw;
						height: 5vw;
						top: 3.5vw;
					}
					.down {
						background: url(../../assets-pc/front-img/white-triangle.svg) no-repeat 0 0;
					}
					.up {
						background: url(../../assets-pc/front-img/white-triangle.svg) no-repeat 0 0;
						transform: rotate(180deg);
					}
					.time-select {
						position: absolute;
						width: 70vw;

						background: #ffffff;
						border: 1px solid #f0f0f1;
						box-sizing: border-box;
						right: 2vw;
						top: 10vw;
						padding: 17px 24px 22px 24px;
						li {
							font-family: Inter;
							font-style: normal;
							font-weight: 500;
							font-size: 4vw;
							line-height: 30px;
							display: flex;
							align-items: center;

							color: black;
							cursor: pointer;
							position: relative;
							.selected-content {
								position: absolute;
								width: 19px;
								height: 19px;

								background: #aa9ce0;
								top: 3px;
								left: -15px;
								border-radius: 50%;
								color: white;
								display: flex;
								justify-content: center;
								align-items: center;
							}
						}
						.disabled {
							color: #c4c4c4;
							cursor: not-allowed;
						}
					}
				}
			}
			.container-gray {
				height: 130vw;
				background: #f0f0f1;
				padding: 5vw;
				width: 100vw;
				.full {
					border-left: none;
					background: #d7e0f9;
					border-radius: 5px;
					div {
						color: #007aff;
					}
				}
				.day-table {
					background-color: rgba(255, 255, 255, 1);
					border-radius: 5px;
					border-collapse: separate;
					border-spacing: 2vw 7vw;
					.full {
						border-left: none;
						background: #d7e0f9;
						border-radius: 5px;
						div {
							color: #007aff;
						}
					}
					.day-td {
						width: 10vw;
						height: 10vw;

						font-family: Inter;
						font-style: normal;
						font-weight: 500;
						font-size: 3vw;
						line-height: 4vw;
						text-align: center;
						/* Black */

						color: #000000;
						div {
							font-family: Inter;
							font-style: normal;
							font-weight: 500;
							font-size: 3vw;
							line-height: 3vw;
							text-align: center;
						}
						.day {
							font-size: 3vw !important;
							line-height: 5vw !important;
						}
					}
					.today {
						width: 10vw;
						height: 10vw;
						color: white !important;

						background-color: #3865e0 !important;
						border-radius: 5px;
						div {
							color: white !important;
						}
						.day {
							font-size: 4vw !important;
							line-height: 4vw !important;
						}
					}
					.full {
						border-left: none;
						background: #d7e0f9;
						border-radius: 5px;
						color: #007aff;
						div {
							color: #007aff;
						}
					}
					.non-show {
						visibility: hidden;
					}
				}
				.week-string {
					.one-title {
						width: 10vw;
						height: 10vw;
						margin-bottom: 3vw;

						font-size: 4vw;
						line-height: 4vw;
						text-align: center;

						color: #2e3543;
					}
				}
			}
		}
	}
	.user-info-title {
		// border-bottom: 1px solid #cecece;
		padding: 6vw 0 2vw 3vw;
		font-size: 4vw;
	}
	.step1-notice {
		background: #ffffff;
		border: 3px solid #edf0f3;
		box-sizing: border-box;
		border-radius: 10px;
		position: relative;
		margin: 4vw;
		height: 51vw;
		.purple-icon {
			width: 6vw;
			height: 6vw;
			border-radius: 50%;
			background: #aa9ce0;
			position: absolute;
			margin-left: 6.5vw;
			margin-top: 5.5vw;
		}
		span {
			color: white;
			position: absolute;
			font-size: 4vw;
			width: 3vw;
			height: 6vw;
			margin-left: 9vw;
			margin-top: 6.5vw;
		}

		.title {
			font-size: 4vw;
			/* identical to box height */

			display: flex;
			align-items: center;

			color: #000000;
			margin-left: 15vw;
			margin-top: 7vw;
		}
		p {
			width: 80vw;
			margin-left: 5vw;
			margin-top: 10vw;
			height: 14vw;

			font-size: 3vw;
			line-height: 6vw;
			display: flex;
			align-items: center;

			color: #000000;
		}
	}
	.step1-wrapper {
		// 이용자정보
		// width: 95vw;
		// margin: 2.5vw;
		.user-info {
			margin-top: 74.28px;
			.user-info-title {
				height: 10vw;

				font-weight: 500;
				font-size: 5vw;
				display: flex;
				align-items: center;

				color: #000000;

				margin-bottom: 7vw;
				margin: 2.5vw;
			}
			.tbl {
				width: 95vw;
				margin: 2.5vw;
				border-top: 1px solid #31383f;
				border-radius: 1px;
				font-size: 3vw;
				.table-col-first-m {
					width: 30vw !important;
				}

				thead {
					border-top: 2px solid black;
				}
				th,
				td {
					border-bottom: 1px solid rgba(161, 172, 183, 0.35);
					text-align: left;
					font-size: 4vw;
					font-weight: 500;
					font-size: 3vw;
				}
				th {
					border-right: 1px solid rgba(161, 172, 183, 0.35);
				}
				td:nth-child(1n) {
				}
				tr:nth-child(1n) {
					height: 15vw;
					th {
						height: 15vw;
					}
				}
				tr:nth-child(2n) {
					height: 20vw;
					th {
						height: 20vw;
					}
				}
				tr:nth-child(3n) {
					height: 20vw;
					th {
						height: 20vw;
					}
				}
				tr:nth-child(4n) {
					height: 50vw;
					th {
						height: 50vw;
					}
				}
				th {
					font-weight: 500;
					background: rgba(249, 250, 251, 1);
					display: flex;
					justify-content: center;
					align-items: center;
				}
				ul {
					display: flex;
					span {
						margin-left: 15px;
					}
					li:nth-child(1n) {
						margin-left: 16.5px;
					}
					li:nth-child(2n) {
						margin-left: 39px;
					}
				}
				input[type='checkbox'].round {
					outline: none;
					appearance: none;
					display: inline-block;
					width: 26px;
					height: 26px;
					border: 0px;
					background: url(../../assets-pc/img/common/ico_radio_off.svg) no-repeat 0 0 / cover !important;
					&:checked {
						background: url(../../assets-pc/img/common/ico_radio_on.svg) no-repeat 0 0 / cover !important;
						border: 0 solid #000;
					}
				}
				.user-name {
					margin: 3vw 0 3vw 3vw !important;
					width: 30vw !important;
					height: 10vw;

					background: #ffffff;
					border: 1px solid #c4c4c4;
					box-sizing: border-box;
					padding: 22px;
				}
				.people-num {
					margin: 3vw 0 3vw 3vw !important;
					width: 30vw !important;
					height: 10vw;

					background: #ffffff;
					border: 1px solid #c4c4c4;
					box-sizing: border-box;
					text-indent: 19px;
					padding: 2vw;
				}
				.people-num-text {
					margin-left: 2vw;
				}
				.email-id {
					margin: 3vw 3vw 0vw 3vw !important;
					padding: 2vw 3vw;
					width: 40vw;
					height: 10vw;

					background: #ffffff;
					border: 1px solid #c4c4c4;
					box-sizing: border-box;
				}
				.email-address {
					margin: 3vw !important;
					padding: 2vw 3vw;
					width: 50vw;
					height: 10vw;

					background: #ffffff;
					border: 1px solid #c4c4c4;
					box-sizing: border-box;
				}
				.email-select {
					width: 30vw;
					height: 10vw;

					background: #ffffff;
					border: 1px solid #c4c4c4;
					box-sizing: border-box;
					margin-left: 3vw;

					position: relative;
				}
				.arrow {
					position: absolute;
					width: 10vw;
					height: 10vw;
					left: 23vw;
					bottom: 5vw;
					background: url(../../assets-pc/img/common/btn_paging_prev.svg) no-repeat 50% 50%;
					transform: rotate(270deg);
				}
				.email-tr {
					position: relative;
				}
			}
		}
		//거래정보
		// step1 - 거래정보

		.deal-info {
			margin-top: 86px;
			font-family: Roboto;
			font-style: normal;

			color: #000000;
			.deal-info-title {
				height: 10vw;
				font-weight: 500;
				font-size: 5vw;
				line-height: 28px;
				display: flex;
				align-items: center;
				margin: 2.5vw;
				// margin-bottom: 7vw;
			}
			.tbl {
				width: 95vw;
				margin: 2.5vw;
				border-top: 1px solid #31383f;
				border-radius: 1px;
				.table-col-first-m {
					width: 30vw !important;
				}

				th,
				td {
					border-bottom: 1px solid rgba(161, 172, 183, 0.35);
					text-align: left;
					font-size: 3vw;
					font-weight: 500;
				}
				th {
					font-weight: bold;
					background: #f5f7fa;
					font-size: 3vw;
				}
				thead {
					border-top: 2px solid black;
				}
				th,
				td {
					border-bottom: 1px solid rgba(161, 172, 183, 0.35);
					text-align: left;
					font-size: 3vw;
					font-weight: 500;
				}
				th {
					border-right: 1px solid rgba(161, 172, 183, 0.35);
				}
				td:nth-child(1n) {
				}
				tr:nth-child(1n) {
					height: 20vw;
					th {
						height: 20vw;
					}
				}
				tr:nth-child(2n) {
					height: 20vw;
					th {
						height: 20vw;
					}
				}
				tr:nth-child(3n) {
					height: 30vw;
					th {
						height: 30vw;
					}
				}
				tr:nth-child(4n) {
					height: 50vw;
					th {
						height: 50vw;
					}
				}
				th {
					font-weight: 500;
					background: rgba(249, 250, 251, 1);
					display: flex;
					justify-content: center;
					align-items: center;
				}
				ul {
					display: flex;
					li {
						margin-left: 2vw;
						span {
							margin-left: 2vw;
						}
					}
					.consignment {
						margin-left: 2vw;
					}
					.purchase {
						margin-left: 2vw;
					}
					.normal-checkbox:nth-child(2n) {
						margin-left: 2vw;
					}
					.normal-checkbox:nth-child(3n) {
						margin-left: 2vw;
					}
				}
				.desc {
					margin-left: 2vw;
					margin-top: 2vw;
				}
				input[type='radio'] {
					outline: none;
					appearance: none;
					display: inline-block;
					width: 15vw;
					height: 15vw;
					border: 0 solid #000;
					background: url(../../assets-pc/img/common/ico_radio_off.svg) no-repeat 0 0 / cover;
					&:checked {
						background: url(../../assets-pc/img/common/ico_radio_on.svg) no-repeat 0 0 / cover;
						border: 0 solid #000;
					}
				}
				input[type='checkbox'].pay-service {
					outline: none;
					appearance: none;
					display: inline-block;
					border: 0 solid #000;
					width: 4vw;
					height: 4vw;
					background: url(../../assets-pc/front-img/ico_checkbox_brown_off.svg) no-repeat 0 0 /
						cover;
					&:checked {
						background: url(../../assets-pc/front-img/ico_checkbox_brown_on.svg) no-repeat 0 0 /
							cover;
						border: 0 solid #000;
						background-color: #edf0f3;
						border: 1px solid #c4c4c4;
						box-sizing: border-box;

						background-size: 60%;
						background-position: 5px 6px;
					}
				}
				.place-holder {
					height: 5vw;

					font-weight: 500;
					font-size: 3vw;
					line-height: 3vw;
					/* identical to box height */

					display: flex;
					align-items: center;

					color: #c4c4c4;
					margin-left: 1vw;
				}
				.description-email {
					width: 60vw;
					height: 40vw;
					margin-left: 2vw;

					background: #ffffff;
					border: 1px solid #c4c4c4;
					box-sizing: border-box;
					padding: 2vw;
					overflow-y: scroll;
					resize: none;
				}
				textarea::placeholder {
					font-size: 3vw !important;
				}
				textarea::-webkit-input-placeholder {
					font-size: 3vw !important;
				}
				textarea:-ms-input-placeholder {
					font-size: 3vw !important;
				}
			}
		}
		//버튼
	}
	.step1-btn-box {
		width: 95vw;
		display: flex;
		flex-direction: column;
		.btn-cancel {
			background: #aa9ce0;
			border-radius: 5px;
			color: white;
			height: 10vw;
			margin: 2vw;
			width: 95vw;
		}
		.btn-reservation {
			background: #ffffff;
			border: 1px solid #b9a07e;
			box-sizing: border-box;
			border-radius: 5px;
			color: #b9a07e;
			height: 10vw;
			margin: 2vw;
			width: 95vw;
		}
	}
	.step2-wrapper {
		.step2-numbering {
			width: 100vw;
			height: 30vw;
			margin: 6vw;
			position: relative;
			.light-brown {
				position: absolute;
				top: 0px;

				width: 13vw;
				height: 13vw;

				background: #b9a07e;
				border: 10px solid #ded6cb;
				box-sizing: border-box;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				left: 54vw;
				position: absolute;
			}
			.num {
				width: 7px;
				height: 19px;

				font-family: Roboto;
				font-style: normal;
				font-weight: 500;
				font-size: 16px;
				line-height: 19px;
				/* identical to box height */

				display: flex;
				align-items: center;

				color: #ffffff;
			}
			.line {
				width: 24vw;
				height: 0px;

				border: 1px solid #dfdfdf;
				position: absolute;
				left: 30vw;
				top: 7vw;
			}
			.light-gray {
				width: 13vw;
				height: 13vw;

				background: #898989;
				border: 10px solid #e5e5e5;
				box-sizing: border-box;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				// left: 54vw;
				margin-left: 18vw;
			}
			.step1-text {
				position: absolute;
				bottom: 10vw;
				left: 13vw;

				font-family: Roboto;
				font-style: normal;
				font-weight: 500;
				font-size: 4vw;
				/* identical to box height */

				display: flex;
				align-items: center;

				color: #000000;
			}
			.step2-text {
				position: absolute;
				height: 21px;
				right: 32vw;
				bottom: 10.5vw;

				font-family: Roboto;
				font-style: normal;
				font-weight: 500;
				font-size: 3.9vw;
				/* identical to box height */

				display: flex;
				align-items: center;
				color: #b9a07e;
			}
		}
		.complete-txt {
			border-top: 2px solid #000000;
			height: 10vw;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 4vw;
			background: #f9fafb;
		}
		.tbl {
			width: 95vw;
			border-top: 2px solid #31383f;
			border-radius: 1px;
			margin: 2.5vw;
			margin-top: 4vw;
			.table-col-first {
				width: 30%;
			}
			.table-col-second {
				width: 70%;
			}
			thead {
				border-top: 2px solid black;
			}
			th,
			td {
				border-bottom: 1px solid rgba(161, 172, 183, 0.35);
				text-align: left;
				font-size: 3vw;
				font-weight: 500;
			}
			td {
				padding-left: 24px;
			}
			th {
				border-right: 1px solid rgba(161, 172, 183, 0.35);
			}
			td:nth-child(1n) {
			}
			tr {
				height: 20vw;
				th {
					height: 20vw;
				}
			}

			th {
				font-weight: 500;
				background: rgba(249, 250, 251, 1);
				display: flex;
				justify-content: center;
				align-items: center;
			}
			.long {
				height: 50vw;
			}
		}
	}
	.step1-wrapper {
		// 이용자정보
		// width: 95vw;
		// margin: 2.5vw;
		.user-info {
			margin-top: 74.28px;
			.mini-title {
				height: 10vw;

				font-weight: 500;
				font-size: 5vw;
				display: flex;
				align-items: center;

				color: #000000;

				margin-bottom: 7vw;
				margin: 2.5vw;
			}
			.tbl {
				width: 95vw;
				margin: 2.5vw;
				border-top: 1px solid #31383f;
				border-radius: 1px;
				font-size: 3vw;
				.table-col-first-m {
					width: 30vw !important;
				}

				thead {
					border-top: 2px solid black;
				}
				th,
				td {
					border-bottom: 1px solid rgba(161, 172, 183, 0.35);
					text-align: left;
					font-size: 4vw;
					font-weight: 500;
					font-size: 3vw;
				}
				th {
					border-right: 1px solid rgba(161, 172, 183, 0.35);
				}
				td:nth-child(1n) {
				}
				tr:nth-child(1n) {
					height: 15vw;
					th {
						height: 15vw;
					}
				}
				tr:nth-child(2n) {
					height: 20vw;
					th {
						height: 20vw;
					}
				}
				tr:nth-child(3n) {
					height: 20vw;
					th {
						height: 20vw;
					}
				}
				tr:nth-child(4n) {
					height: 50vw;
					th {
						height: 50vw;
					}
				}
				th {
					font-weight: 500;
					background: rgba(249, 250, 251, 1);
					display: flex;
					justify-content: center;
					align-items: center;
				}
				ul {
					display: flex;
					span {
						margin-left: 15px;
					}
					li:nth-child(1n) {
						margin-left: 16.5px;
					}
					li:nth-child(2n) {
						margin-left: 39px;
					}
				}
				input[type='checkbox'].round {
					outline: none;
					appearance: none;
					display: inline-block;
					width: 26px;
					height: 26px;
					border: 0px;
					background: url(../../assets-pc/img/common/ico_radio_off.svg) no-repeat 0 0 / cover !important;
					&:checked {
						background: url(../../assets-pc/img/common/ico_radio_on.svg) no-repeat 0 0 / cover !important;
						border: 0 solid #000;
					}
				}
				.user-name {
					margin: 3vw 0 3vw 3vw !important;
					width: 30vw !important;
					height: 10vw;

					background: #ffffff;
					border: 1px solid #c4c4c4;
					box-sizing: border-box;
					padding: 22px;
				}
				.people-num {
					margin: 3vw 0 3vw 3vw !important;
					width: 30vw !important;
					height: 10vw;

					background: #ffffff;
					border: 1px solid #c4c4c4;
					box-sizing: border-box;
					text-indent: 19px;
					padding: 2vw;
				}
				.people-num-text {
					margin-left: 2vw;
				}
				.email-id {
					margin: 3vw 3vw 0vw 3vw !important;
					padding: 2vw 3vw;
					width: 40vw;
					height: 10vw;

					background: #ffffff;
					border: 1px solid #c4c4c4;
					box-sizing: border-box;
				}
				.email-address {
					margin: 3vw !important;
					padding: 2vw 3vw;
					width: 50vw;
					height: 10vw;

					background: #ffffff;
					border: 1px solid #c4c4c4;
					box-sizing: border-box;
				}
				.email-select {
					width: 30vw;
					height: 10vw;

					background: #ffffff;
					border: 1px solid #c4c4c4;
					box-sizing: border-box;
					margin-left: 3vw;

					position: relative;
				}
				.arrow {
					position: absolute;
					width: 10vw;
					height: 10vw;
					left: 23vw;
					bottom: 5vw;
					background: url(../../assets-pc/img/common/btn_paging_prev.svg) no-repeat 50% 50%;
					transform: rotate(270deg);
				}
				.email-tr {
					position: relative;
				}
			}
		}
		//거래정보
		// step1 - 거래정보

		.deal-info {
			margin-top: 86px;
			font-family: Roboto;
			font-style: normal;

			color: #000000;
			.deal-info-title {
				height: 10vw;
				font-weight: 500;
				font-size: 5vw;
				line-height: 28px;
				display: flex;
				align-items: center;
				margin: 2.5vw;
				// margin-bottom: 7vw;
			}
			.tbl {
				width: 95vw;
				margin: 2.5vw;
				border-top: 1px solid #31383f;
				border-radius: 1px;
				.table-col-first-m {
					width: 30vw !important;
				}

				th,
				td {
					border-bottom: 1px solid rgba(161, 172, 183, 0.35);
					text-align: left;
					font-size: 3vw;
					font-weight: 500;
				}
				th {
					font-weight: bold;
					background: #f5f7fa;
					font-size: 3vw;
				}
				thead {
					border-top: 2px solid black;
				}
				th,
				td {
					border-bottom: 1px solid rgba(161, 172, 183, 0.35);
					text-align: left;
					font-size: 3vw;
					font-weight: 500;
				}
				th {
					border-right: 1px solid rgba(161, 172, 183, 0.35);
				}
				td:nth-child(1n) {
				}
				tr:nth-child(1n) {
					height: 20vw;
					th {
						height: 20vw;
					}
				}
				tr:nth-child(2n) {
					height: 25vw;
					th {
						height: 25vw;
					}
				}
				tr:nth-child(3n) {
					height: 30vw;
					th {
						height: 30vw;
					}
				}
				tr:nth-child(4n) {
					height: 50vw;
					th {
						height: 50vw;
					}
				}
				th {
					font-weight: 500;
					background: rgba(249, 250, 251, 1);
					display: flex;
					justify-content: center;
					align-items: center;
				}
				ul {
					display: flex;
					li {
						margin-left: 2vw;
						span {
							margin-left: 2vw;
						}
					}
					.consignment {
						margin-left: 2vw;
					}
					.purchase {
						margin-left: 2vw;
					}
					.normal-checkbox:nth-child(2n) {
						margin-left: 2vw;
					}
					.normal-checkbox:nth-child(3n) {
						margin-left: 2vw;
					}
				}
				input[type='radio'] {
					outline: none;
					appearance: none;
					display: inline-block;
					width: 15vw;
					height: 15vw;
					border: 0 solid #000;
					background: url(../../assets-pc/img/common/ico_radio_off.svg) no-repeat 0 0 / cover;
					&:checked {
						background: url(../../assets-pc/img/common/ico_radio_on.svg) no-repeat 0 0 / cover;
						border: 0 solid #000;
					}
				}
				input[type='checkbox'].pay-service {
					outline: none;
					appearance: none;
					display: inline-block;
					border: 0 solid #000;
					width: 4vw;
					height: 4vw;
					background: url(../../assets-pc/front-img/ico_checkbox_brown_off.svg) no-repeat 0 0 /
						cover;
					&:checked {
						background: url(../../assets-pc/front-img/ico_checkbox_brown_on.svg) no-repeat 0 0 /
							cover;
						border: 0 solid #000;
						background-color: #edf0f3;
						border: 1px solid #c4c4c4;
						box-sizing: border-box;

						background-size: 60%;
						background-position: 5px 6px;
					}
				}
				.place-holder {
					height: 5vw;

					font-weight: 500;
					font-size: 3vw;
					line-height: 3vw;
					/* identical to box height */

					display: flex;
					align-items: center;

					color: #c4c4c4;
					margin-left: 1vw;
				}
				.description-email {
					width: 60vw;
					height: 40vw;
					margin-left: 2vw;

					background: #ffffff;
					border: 1px solid #c4c4c4;
					box-sizing: border-box;
					padding: 2vw;
					overflow-y: scroll;
					resize: none;
				}
				.description-email::placeholder {
					font-size: 3vw;
				}
				.description-email::-webkit-input-placeholder {
					font-size: 3vw;
				}
				.description-email:-ms-input-placeholder {
					font-size: 3vw;
				}
			}
		}
		//버튼
	}
}
input[type='checkbox'].round-hapl {
	outline: none;
	appearance: none;
	display: inline-block;
	width: 26px;
	height: 26px;
	border: 0px;
	background: url(../../assets-pc/img/common/ico_radio_off.svg) no-repeat 0 0 / cover !important;
	&:checked {
		background: url(../../assets-pc/img/common/ico_radio_on.svg) no-repeat 0 0 / cover !important;
		border: 0 solid #000;
	}
}
