/* font-size vw */
$f28: 7.778vw;
$f24: 6.667vw;
$f22: 6.111vw;
$f20: 5.556vw;
$f18: 5vw;
$f16: 4.444vw;
$f15: 4.167vw;
$f14: 3.889vw;
$f13: 3.611vw;
$f12: 3.333vw;
$f11: 3.056vw;
$f10: 2.778vw;
$f9: 2.5vw;
@import './layout/main.scss';
@import './layout/warranty.scss';
@import './layout/hpi.scss';
@import './layout/buy.scss';
@import './layout/sales.scss';
@import './layout/auction.scss';
@import './layout/mypage.scss';
@import './layout/join.scss';
.mobile-wrapper {
	.back_wrap {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 15.556vw;
		border-bottom: 0.278vw solid #edf0f3;
		background-color: #fff;
		h2 {
			font-size: $f18;
			font-weight: 500;
			color: #31383f;
		}
		.btn_back {
			position: absolute;
			left: 3.889vw;
			top: 50%;
			transform: translateY(-50%);
			display: flex;
			justify-content: center;
			width: 10vw;
			height: 10vw;
			background: url(../img/common/btn_back.svg) no-repeat 50% 50% / 4.444vw;
		}
		.btn_closed {
			position: absolute;
			left: 5.556vw;
			top: 50%;
			transform: translateY(-50%);
			display: flex;
			justify-content: center;
			width: 6.667vw;
			height: 6.667vw;
			background: url(../img/common/ic_close2.svg) no-repeat 50% 50% / 6.667vw;
		}
		&.search {
			height: 21.111vw;
			padding-right: 5.556vw;
			.search_box {
				position: relative;
				margin-left: auto;
				width: 78.889vw;
				.btn_del {
					position: absolute;
					top: 50%;
					right: 2.778vw;
					transform: translateY(-50%);
					width: 6.667vw;
					height: 6.667vw;
					// background: url(../img/common/ic_close.svg) no-repeat 0 0 / cover;
					background: url(../img/common/ic_search.svg) no-repeat 0 0 / cover;
				}
			}
		}
	}

	.graph_box {
		margin: 5.556vw auto 6.667vw;
		position: relative;
		width: 86.667vw;
		height: 38.889vw;
		background: #f5f7fa;
		border: 0.278vw solid #edf0f3;
		border-radius: 0.556vw;
		p {
			position: absolute;
			left: 4.444vw;
			top: 4.444vw;
			font-weight: bold;
			font-size: $f14;
			z-index: 1;
		}
		.graph {
			position: absolute;
			left: 5.556vw;
			top: 9.028vw;
			width: 75.556vw;
			height: 24.306vw;
		}
	}
	.login_wrap {
		position: fixed;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 88%;
		background: #fff;
		box-shadow: 0 0 6.667vw rgba(0, 0, 0, 0.22);
		border-radius: 2.222vw;
		padding: 6.667vw;
		z-index: 1001;
		.btn_closed {
			width: 3.889vw;
			height: 3.889vw;
			background: url(../img/common/btn_x.svg) no-repeat 0 0 / cover;
			position: absolute;
			top: 6.944vw;
			right: 6.944vw;
		}
		h2 {
			font-size: 6.667vw;
			font-weight: 700;
			line-height: 117%;
			margin-bottom: 4.444vw;
		}
		.input_div {
			margin-bottom: 2.222vw;
		}
		.auto_login {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-top: 4.444vw;
			padding-bottom: 4.722vw;
			border-bottom: 1px solid#EDF0F3;
			label {
				display: flex;
				align-items: center;
				span {
					margin-left: 2.222vw;
					font-size: $f14;
					font-weight: 500;
				}
			}
			a {
				font-size: $f14;
				color: #3d454d;
				opacity: 0.75;
				font-weight: 500;
				text-decoration: underline;
			}
		}
		.btn_login {
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 14.167vw 0 2.222vw;
			width: 100%;
			height: 13.333vw;
			background: #31383f;
			border-radius: 1.111vw;
			font-size: $f16;
			font-weight: 500;
			color: #fff;
		}
		.btn_join {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 13.333vw;
			background: #f5f7fa;
			border-radius: 1.111vw;
			font-size: $f16;
			font-weight: 500;
			color: #31383f;
			margin-bottom: 2.222vw;
		}
	}
	.fliter_pop {
		position: fixed;
		left: 0;
		bottom: 0;
		width: 100%;
		background-color: #fff;
		z-index: 1000;
		border-top-left-radius: 5.556vw;
		border-top-right-radius: 5.556vw;
		ul {
			padding: 3.333vw 0 0 6.667vw;
			li {
				padding: 1.111vw 0;
				border-bottom: 0.278vw solid #edf0f3;
				&:last-child {
					border-bottom: 0 solid #000;
				}
				button {
					display: flex;
					width: 100%;
					font-size: $f15;
					font-weight: 500;
					color: #31383f;
					padding: 4.167vw 0 4.167vw 1.111vw;
				}
			}
		}
	}
}
