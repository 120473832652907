.top-photo-box {
	background-image: url(../../assets-pc/front-img/m-store-bg.png);
	height: 56vw;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100vw;
	position: relative;
	.top-photo {
		position: absolute;
		height: 56vw;
		width: 100%;
	}
	.title-box {
		width: 100%;
		display: flex;
		justify-content: center;
	}
	.big-title {
		color: white;
		position: absolute;
		font-weight: 600;
		top: 32%;
		font-family: Roboto;
		font-size: 5.5556vw;
		.normal {
			font-weight: 300;
		}
	}
	.small-title {
		color: white;
		position: absolute;
		font-weight: 600;
		font-size: 3.8889vw;
		font-family: Roboto;
		top: 47%;
	}
	.small-title2 {
		top: 56%;
		color: white;
		position: absolute;
		font-size: 3.8889vw;
		font-weight: 600;
		font-family: Roboto;
	}
	.btn-wrapper {
		position: absolute;
		width: 100%;
		top: 68%;
	}
	.btn-box {
		// padding-top: 120px;
		display: flex;
		justify-content: center;
		.reservation-btn {
			// background-color: #b9a07e;
			background-color: rgba(126, 150, 185, 1);

			// height: 54px;
			width: 30vw;
			left: 0px;
			top: 0px;
			border-radius: 0px;
			margin-right: 2px;
			position: relative;
			.content {
				position: absolute;
				left: 8.75%;
				top: 44.04%;

				font-family: Roboto;
				font-weight: normal;
				font-size: 3.4vw;
				/* identical to box height */

				display: flex;
				align-items: center;
				text-align: center;

				// color: #fcfcfc;
				color: rgba(255, 255, 255, 0.5);
			}
			.line1 {
				position: absolute;
				left: 9.75%;
				right: 5.21%;
				top: 85.19%;
				bottom: 14.81%;
				width: 84%;

				border: 1px solid rgba(255, 255, 255, 0.46);
			}
			.line2 {
				position: absolute;
				left: 84%;
				right: 4%;
				top: 75.61%;
				bottom: 33.33%;

				border: 1px solid rgba(255, 255, 255, 0.46);
				transform: rotate(37.57deg);
			}
		}
		.introduce-btn {
			// background-color: #574b3a;
			background-color: rgba(145, 151, 207, 1);
			height: 11vw;
			width: 28vw;
			left: 0px;
			top: 0px;
			border-radius: 0px;
			margin-left: 2px;
			position: relative;
			.content {
				position: absolute;
				left: 8.75%;
				top: 44.04%;

				font-family: Roboto;
				font-weight: normal;
				font-size: 3.4vw;
				/* identical to box height */

				display: flex;
				align-items: center;
				text-align: center;
				color: #fcfcfc;
				// color: rgba(255, 255, 255, 0.5);
			}
			.line1 {
				position: absolute;
				left: 9.75%;
				right: 5.21%;
				top: 85.19%;
				bottom: 14.81%;
				width: 84%;

				border: 1px solid rgba(255, 255, 255, 0.46);
			}
			.line2 {
				position: absolute;
				left: 84%;
				right: 4%;
				top: 75.61%;
				bottom: 33.33%;

				border: 1px solid rgba(255, 255, 255, 0.46);
				transform: rotate(37.57deg);
			}
		}
		.left-top-logo {
			position: absolute;
			// left: 5.21%;
			right: 5.21%;
			top: 12.96%;
			bottom: 64.81%;

			font-family: Roboto;
			font-style: normal;
			font-weight: normal;
			font-size: 1.88vw;
			line-height: 12px;
			/* identical to box height */

			display: flex;
			align-items: center;
			text-align: center;

			color: rgba(252, 252, 252, 0.3);
		}
	}
}
