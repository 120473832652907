.input_t_auction {
	width: 100%;
}
.rolling_mobile_wrapper {
	height: 60px;
	overflow: hidden;
}
// .swiper-slide {
// 	display: flex !important;
// }
// .youtube-swiper-slide {
// 	display: inline-block !important;
// }
@media (max-width: 280px) {
	.mobile_sigan_iframe {
		width: 160px !important;
		height: 90px;
		margin: 0 auto !important;
	}
}
@media (min-width: 280px) and (max-width: 320px) {
	.mobile_sigan_iframe {
		width: 280px !important;
		height: 157.5px;
		margin: 0 auto !important;
	}
}
@media (min-width: 320px) and (max-width: 375px) {
	.mobile_sigan_iframe {
		width: 320px !important;
		height: 180px;
		margin: 0 auto !important;
	}
}
@media (min-width: 375px) and (max-width: 414px) {
	.mobile_sigan_iframe {
		width: 375px !important;
		height: 210.9px;
		margin: 0 auto !important;
	}
}
@media (min-width: 414px) and (max-width: 480px) {
	.mobile_sigan_iframe {
		width: 414px !important;
		height: 232.8px;
		margin: 0 auto !important;
	}
}
@media (min-width: 480px) and (max-width: 540px) {
	.mobile_sigan_iframe {
		width: 480px !important;
		height: 270px;
		margin: 0 auto !important;
	}
}
@media (min-width: 540px) and (max-width: 640px) {
	.mobile_sigan_iframe {
		width: 540px !important;
		height: 303.7px;
		margin: 0 auto !important;
	}
}
@media (min-width: 640px) and (max-width: 768px) {
	.mobile_sigan_iframe {
		width: 640px !important;
		height: 360px;
		margin: 0 auto !important;
	}
}
@media (min-width: 768px) and (max-width: 1024px) {
	.mobile_sigan_iframe {
		width: 768px !important;
		height: 432px;
		margin: 0 auto !important;
	}
}

#AuctionCarouselWrapper {
	position: relative;
	.swiper-wrapper {
		// display: flex;
		// width: 300%;
	}
}

.buy_model_filter_search_btn {
	position: absolute;
	right: 2.778vw;
	top: 2.778vw;
	display: block;
	width: 6.667vw;
	height: 6.667vw;
	background: url(../img/common/ic_search.svg) no-repeat 0 0 / cover;
}
.care-wrapper {
	width: 100%;
	margin: 0 auto;
	.img-wrapper {
		width: 200%;
		.care_img {
			width: 50%;
		}
	}
	.care-second-wrapper {
		margin: 10vw 5vw;
		margin-top: 50px;
		font-size: 4vw;
		line-height: 18px;
		letter-spacing: 1px;
		.title-wrapper {
			font-size: 6.799vw;
			font-weight: bold;
			margin-bottom: 22px;
			.beige {
				color: rgb(206, 173, 137);
			}
		}
		.normal-title-wrapper {
			font-size: 6.799vw;
			font-weight: normal;
			margin-bottom: 22px;
		}
		.content-box {
			margin-bottom: 20px;
			li {
				list-style: inside !important;
			}
		}
	}
	.care-third-wrapper {
		margin: 10vw 5vw;
		margin-top: 50px;
		font-size: 4vw;
		letter-spacing: 1px;
		.title-wrapper {
			font-size: 6.799vw;
			font-weight: normal;
			margin-bottom: 22px;
			.red {
				color: rgb(255, 3, 2);
			}
		}
		.last-title-wrapper {
			font-size: 6vw;
			font-weight: normal;
			margin-bottom: 22px;
		}
		.content-box {
			margin-bottom: 20px;
			font-size: 4vw;
		}
		.bold-content-box {
			margin-bottom: 20px;
			font-weight: bold;
			font-size: 4vw;
		}
	}
}
.buy_reservation {
	margin-top: 40px;
	width: 100%;
	height: 64px;
	background: #31383f;
	border-radius: 1px;
	font-weight: 500;
	font-size: 18px;
	color: #fff;
}
.buy_bidding_end {
	background: lightgray !important;
	cursor: default !important;
}
