@charset "UTF-8";
.mobile-wrapper {
	.buy {
		.buy_top {
			padding: 8.889vw 6.667vw 5.566vw;
			margin-bottom: 4.444vw;
			background-color: #fff;
			border-bottom: 0.278vw solid #edf0f3;
			h2 {
				font-size: $f24;
				font-weight: 700;
				color: #31383f;
			}
			.search_wrap {
				margin: 3.333vw 0 5.556vw;
				position: relative;
				button {
					position: absolute;
					right: 2.778vw;
					top: 2.778vw;
					display: block;
					width: 6.667vw;
					height: 6.667vw;
					background: url(../../img/common/ic_search.svg) no-repeat 0 0 / cover;
				}
			}
			h3 {
				font-size: $f16;
				font-weight: 700;
				color: #31383f;
			}
			.best_brand {
				display: flex;
				flex-wrap: wrap;
				margin-top: 2.222vw;
				li {
					display: inline-flex;
					background: rgba(161, 172, 183, 0.1);
					border: 0.278vw solid rgba(161, 172, 183, 0.15);
					border-radius: 55.556vw;
					font-size: $f14;
					font-weight: 500;
					padding: 2.5vw 4.444vw 1.944vw;
					margin: 0 1.667vw 2.222vw 0;
				}
			}
		}
		.product_list {
			background-color: #fff;
			margin-bottom: 25%;
			.total_txt {
				font-size: $f14;
				padding: 5vw 6.944vw 4vw;
				color: #31383f;
				border-top: 1px solid #edf0f3;
				display: flex;
				justify-content: space-between;
				align-items: center;
				b {
					font-weight: 600;
				}
				button {
					display: inline-flex;
					background: #212529;
					color: white;
					border: 0.278vw solid rgba(161, 172, 183, 0.15);
					border-radius: 55.556vw;
					font-size: $f14;
					font-weight: 500;
					padding: 2.5vw 4.444vw 1.944vw;
					margin: 0 1.667vw 2.222vw 0;
				}
				.off {
					background-color: gray;
				}
			}
			> ul {
				display: flex;
				flex-wrap: wrap;
				border-top: 1px solid #edf0f3;
				> li {
					width: 50%;
					border-top: 1px solid #edf0f3;
					&:nth-child(odd) {
						border-right: 1px solid #edf0f3;
					}
					&:nth-child(-n + 2) {
						border-top: 0 solid #edf0f3;
					}
				}
				.top {
					position: relative;
					padding-top: 8.889vw;
					img {
						display: block;
						margin: 0 auto;
						width: 73.333%;
					}
					.info {
						position: absolute;
						left: 0;
						top: 0;
						display: flex;
						width: 100%;
						padding: 2.778vw 2.778vw 0;
						color: #fff;
						.sale {
							display: flex;
							align-items: center;
							background: #be9162;
							border-radius: 0.278vw;
							font-size: $f9;
							padding: 0 1.667vw;
							height: 5.556vw;
						}
						.option {
							display: flex;
							align-items: center;
							position: absolute;
							height: 5.556vw;
							top: 2.778vw;
							right: 8.444vw;
							img {
								width: 4.044vw;
								margin-right: 2.5vw;
								&:nth-child(2) {
									width: 4.3vw;
									margin-right: 1.8vw;
								}
							}
						}
						.fluctuations {
							position: absolute;
							right: 2.778vw;
							top: 2.778vw;
							display: flex;
							align-items: center;
							justify-content: center;
							width: 5.556vw;
							height: 5.556vw;
							border-radius: 0.556vw;
							font-size: $f9;
							font-weight: 500;
							&.up {
								background: #309d5c;
							}
							&.down {
								background: #d74c4c;
							}
						}
					}
				}
				.btm {
					padding: 3.333vw 4.444vw;
					.watch_name {
						font-size: $f13;
						line-height: 138%;
						font-weight: 500;
						color: #2b3035;
						opacity: 0.6;
						height: 10vw;
						width: 90%;
					}
					.price {
						margin-top: 2.222vw;
						line-height: 108%;
						font-size: $f13;
						font-weight: 700;
						color: #31383f;
					}
					ul {
						margin-top: 5vw;
						display: flex;
						flex-wrap: wrap;
						li {
							font-size: $f11;
							color: #31383f;
							opacity: 0.6;
							font-weight: 500;
							&:nth-child(1)::after {
								content: '';
								display: inline-block;
								width: 1px;
								height: 2.778vw;
								margin: 0 1.667vw;
								background: #a1acb7;
								opacity: 0.5;
								transform: translateY(0.2vw);
							}
							&:last-child {
								width: 100%;
								margin-top: 1.267vw;
							}
						}
					}
				}
			}
			.nodata {
				padding: 15.278vw 0 30vw;
				text-align: center;
				.t1 {
					font-size: $f18;
					font-weight: 700;
					color: #31383f;
					line-height: 142%;
					margin-bottom: 3.333vw;
				}
				.t2 {
					font-size: $f13;
					font-weight: 500;
					color: #31383f;
					line-height: 140%;
					b {
						font-weight: 700;
						color: #be9162;
					}
				}
				.link {
					display: inline-flex;
					justify-content: center;
					align-items: center;
					padding: 0 4.444vw;
					margin: 9.444vw auto 0;
					height: 12.222vw;
					font-size: $f14;
					color: #fff;
					font-weight: 500;
					background: #31383f;
					border-radius: 1.111vw;
				}
			}
		}

		.filter_wrap {
			position: fixed;
			left: 50%;
			bottom: 5.556vw;
			transform: translateX(-50%);
			background: #fff;
			border: 0.278vw solid #f3f5f7;
			box-shadow: 0 1.111vw 1.111vw rgba(0, 0, 0, 0.15);
			border-radius: 27.778vw;
			display: flex;
			align-items: center;
			padding: 2.778vw 4.444vw;
			button {
				display: flex;
				justify-content: center;
				align-items: center;
				padding-left: 6.667vw;
				font-size: $f12;
				line-height: 5.556vw;
				&.btn_filter {
					background: url(../../img/common/ic_filter.svg) no-repeat 0 0 / 5.556vw;
					&::after {
						content: '';
						display: block;
						width: 0.278vw;
						height: 4.444vw;
						background: #d1d9df;
						margin: 0 3.333vw;
					}
					span {
						font-size: $f11;
						color: #fff;
						font-weight: 500;
						display: flex;
						justify-content: center;
						align-items: center;
						width: 5vw;
						height: 5vw;
						background: #31383f;
						border-radius: 100%;
						margin-left: 0.556vw;
					}
				}
				&.btn_sort {
					background: url(../../img/common/ic_align.svg) no-repeat 0 0 / 5.556vw;
				}
			}
		}

		.product_detail {
			padding-bottom: 25.556vw;
			.product_top {
				margin-bottom: 2.222vw;
				padding: 4.444vw;
				background: #fff;
				position: relative;
				.img_box {
					padding: 0 14.444vw;
					img {
						width: 100%;
					}
				}
				.swiper-pagination {
					position: static;
					margin: 4.167vw 0;
					.swiper-pagination-bullet {
						width: 1.667vw;
						height: 1.667vw;
						margin: 0 1.111vw;
						background: #31383f;
						opacity: 0.5;
					}
					.swiper-pagination-bullet-active {
						opacity: 1;
					}
				}
				.t1 {
					font-size: $f13;
					font-weight: 500;
					color: #31383f;
					opacity: 0.75;
					b {
						font-weight: 700;
						color: #be9162;
					}
				}
				.sale {
					position: absolute;
					left: 4.444vw;
					top: 4.444vw;
					display: inline-flex;
					align-items: center;
					background: #be9162;
					border-radius: 0.278vw;
					font-size: $f9;
					padding: 0 1.667vw;
					height: 5.556vw;
					color: #fff;
					z-index: 10;
				}
			}

			.product_info {
				background: #fff;
				margin-bottom: 3.333vw;
				.info_box {
					padding: 5.556vw 6.667vw;
					border-bottom: 0.278vw solid #edf0f3;
					margin-bottom: 5.556vw;
					color: #31383f;
					font-weight: 500;
					.t1 {
						font-weight: 500;
						font-size: $f16;
						opacity: 0.6;
						margin-bottom: 2.222vw;
					}
					.t2 {
						font-size: $f24;
						font-weight: 700;
						margin-bottom: 3.333vw;
					}
					.t3 {
						font-size: $f14;
						line-height: 4.444vw;
						margin-bottom: 7.778vw;
					}
					.price {
						font-size: $f24;
						font-weight: 700;
					}
				}

				.info_list {
					padding: 0 7.778vw;
					display: flex;
					justify-content: space-between;
					li {
						text-align: center;
						b {
							font-weight: 500;
							font-size: $f12;
							color: #31383f;
							opacity: 0.75;
						}
						div {
							display: flex;
							justify-content: center;
							align-items: center;
							height: 7.778vw;
							margin-top: 3.333vw;
							.fluctuations {
								display: flex;
								justify-content: center;
								align-items: center;
								width: 7.778vw;
								height: 7.778vw;
								border-radius: 0.556vw;
								color: #fff;
								font-size: $f11;
								&.up {
								}
								&.down {
									background: #d74c4c;
								}
							}
						}
						img {
							width: 7.778vw;
						}
					}
				}
			}

			.branch_list {
				padding: 0 6.667vw 6.667vw;
				h3 {
					font-size: $f14;
					font-weight: 700;
					color: #31383f;
				}
				ul {
					margin-top: 3.333vw;
					display: flex;
					justify-content: space-between;
					li {
						opacity: 0.5;
						&.checked {
							opacity: 1;
						}
					}

					label {
						display: flex;
						align-items: center;
						font-size: $f14;
						span {
							color: #31383f;
							margin-left: 1.667vw;
						}
					}
				}
			}

			.info_wrap {
				background: #fff;
				padding: 6.667vw 6.667vw 16.667vw;

				h3 {
					font-size: $f20;
					font-weight: 700;
					color: #31383f;
					margin-bottom: 5.556vw;
				}
				ul {
					padding-bottom: 6.667vw;
					margin-bottom: 6.667vw;
					border-bottom: 0.278vw solid #edf0f3;
					li {
						display: flex;
						justify-content: space-between;
						align-items: center;
						font-size: $f14;
						font-weight: 500;
						color: #31383f;
						margin-bottom: 4.444vw;
						&:last-child {
							margin-bottom: 0;
						}
						b {
							opacity: 0.6;
							font-weight: 500;
						}
					}
				}
				.txt {
					font-size: $f14;
					font-weight: 500;
					color: #31383f;
					opacity: 0.6;
					line-height: 143%;
				}
			}

			.btn_wrap {
				display: flex;
				align-items: center;
				width: 100%;
				height: 25.556vw;
				position: fixed;
				left: 0;
				bottom: 0;
				padding: 0 6.667vw;
				border-top: 0.278vw solid #edf0f3;
				background-color: #fff;
				z-index: 10;

				.btn {
					width: 100%;
					height: 13.333vw;
					display: flex;
					justify-content: center;
					align-items: center;
					background: #31383f;
					border-radius: 1.111vw;
					color: #fff;
					font-size: $f16;
					font-weight: 500;
				}
			}
			.btn_wrap_2 {
				display: flex;
				align-items: center;
				justify-content: space-evenly;
				width: 100%;
				height: 32.556vw;
				position: fixed;
				left: 0;
				bottom: 0;
				padding: 0 6.667vw;
				border-top: 0.278vw solid #edf0f3;
				background-color: #fff;
				z-index: 10;
				flex-direction: column;

				.btn {
					width: 100%;
					height: 13.333vw;
					display: flex;
					justify-content: center;
					align-items: center;
					background: #31383f;
					border-radius: 1.111vw;
					color: #fff;
					font-size: $f16;
					font-weight: 500;
					&:not([disabled]) {
							&:active {
								text-decoration: none;
								color: #be9162;
								outline: none;
							}
						}
					
						&:disabled {
							background: lightgray !important;
							cursor: default !important;
						}
				}
			}
		}

		.find_watch {
			background: #fff;
			padding: 6.667vw 6.667vw 5.556vw;
			> .t1 {
				font-size: $f13;
				font-weight: 500;
				color: #31383f;
				opacity: 0.75;
			}
			h3 {
				font-size: $f13;
				font-weight: 500;
				color: #31383f;
				margin: 5.556vw 0 1.667vw;
				span {
					color: #ff5353;
				}
			}
			textarea {
				width: 100%;
				height: 33.333vw;
				padding: 4.444vw;
				border: 0.278vw solid #e7ecf1;
				border-radius: 1.111vw;
				font-size: $f14;
				color: #31383f;
				font-weight: 500;
				&::placeholder {
					opacity: 0.5;
				}
			}
			.agree_box {
				display: flex;
				border-bottom: 0.278vw solid #edf0f3;
				padding-bottom: 5.556vw;
				margin: 5.556vw 0 3.333vw;
				input {
					margin-right: 2.222vw;
				}
				div {
					width: 78.889vw;
					.t1 {
						font-size: $f14;
						font-weight: 500;
						color: #3d454d;
						a {
							text-decoration: underline;
						}
					}
					.t2 {
						margin-top: 1.944vw;
						font-size: $f12;
						font-weight: 500;
						color: #31383f;
						opacity: 0.75;
					}
				}
			}
			.error_txt {
				font-size: $f13;
				font-weight: 500;
				color: #ff5353;
			}
			.btn {
				margin-top: 21.111vw;
				width: 100%;
				height: 13.333vw;
				display: flex;
				justify-content: center;
				align-items: center;
				background: #31383f;
				border-radius: 1.111vw;
				color: #fff;
				font-size: $f16;
				font-weight: 500;
			}
		}

		.suggested_wrap {
			padding: 5.556vw 6.667vw;
			margin-bottom: 2.222vw;
			background-color: #fff;
			h3 {
				font-size: $f16;
				font-weight: 700;
				color: #31383f;
			}
			.best_brand {
				display: flex;
				flex-wrap: wrap;
				margin-top: 2.222vw;

				li {
					display: inline-flex;
					border: 0.278vw solid rgba(161, 172, 183, 0.3);
					border-radius: 55.556vw;
					font-size: $f14;
					font-weight: 500;
					padding: 2.5vw 4.444vw 1.944vw;
					margin: 0 1.667vw 2.222vw 0;
				}
			}
		}

		> .recently_searched_brands {
			padding: 5.556vw;
			background-color: #fff;
			position: relative;
			h3 {
				font-size: $f16;
				font-weight: 700;
				color: #31383f;
			}
			.btn_del {
				position: absolute;
				top: 5.833vw;
				right: 6.667vw;
				font-size: $f12;
				font-weight: 500;
				color: #112211;
				opacity: 0.5;
				text-decoration: underline;
			}
			ul {
				margin-top: 2.222vw;
				li {
					padding: 3.333vw 0;
					font-size: $f14;
					font-weight: 500;
					color: #31383f;
					border-bottom: 0.278vw solid #edf0f3;
				}
			}
		}

		.searched_keyword {
			padding: 0 6.667vw 20vw;
			background-color: #fff;
			ul {
				li {
					padding: 3.333vw 0 3.333vw 8.333vw;
					font-size: $f14;
					font-weight: 500;
					color: #31383f;
					border-bottom: 0.278vw solid #edf0f3;
					background: url(../../img/common/ic_search2.svg) no-repeat 0 50% / 6.667vw;
				}
			}
		}

		.nodata_wrap {
			padding: 15.278vw 0 30vw;
			text-align: center;
			background-color: #fff;
			.t1 {
				font-size: $f18;
				font-weight: 700;
				color: #31383f;
				line-height: 142%;
				margin-bottom: 3.333vw;
			}
			.t2 {
				font-size: $f13;
				font-weight: 500;
				color: #31383f;
				line-height: 140%;
				b {
					font-weight: 700;
					color: #be9162;
				}
			}
			.link {
				display: inline-flex;
				justify-content: center;
				align-items: center;
				padding: 0 4.444vw;
				margin: 9.444vw auto 0;
				height: 12.222vw;
				font-size: $f14;
				color: #fff;
				font-weight: 500;
				background: #31383f;
				border-radius: 1.111vw;
			}
		}

		.fliter_list {
			position: relative;
			margin-top: 3.333vw;
			padding-bottom: 23.333vw;
			background-color: #fff;
			border-top: 0.278vw solid #edf0f3;
			.card_list {
				background-color: #fff;
				border-bottom: 0.278vw solid #edf0f3;
				border-top: 0.278vw solid #edf0f3;
				margin-bottom: 4.444vw;
				.btn_link {
					padding: 0 0 0 6.667vw;
					> div {
						padding: 0 0 4.444vw;
						border-bottom: 0.278vw solid rgba(237, 240, 243, 1);
					}
				}
				li {
					&:last-child {
						.btn_link {
							> div {
								border-bottom: 0 solid #000;
							}
						}
					}
				}
				.btn_link {
					display: flex;
					align-items: center;
					padding: 4.167vw 0 4.167vw 6.667vw;
					background: #fff url(../../img/common/ico_arrow_right.svg) no-repeat right 5.556vw top 50% /
						6.667vw;
					> div {
						width: 80%;
						color: rgba(49, 56, 63, 1);
						font-weight: 500;
						.t1 {
							font-size: $f16;
						}
						.t2 {
							font-size: $f14;
							opacity: 0.75;
							margin-top: 1.667vw;
						}
					}
				}
			}
			.btn_wrap {
				position: fixed;
				left: 0;
				bottom: 0;
				z-index: 100;
				height: 23.333vw;
				padding: 4.444vw 5.556vw 5.556vw;
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 100%;
				background-color: #fff;
				border-top: 0.278vw solid rgba(237, 240, 243, 1);
				button {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 48%;
					height: 13.333vw;
					background: #31383f;
					border-radius: 1.111vw;
					font-size: $f16;
					font-weight: 500;
					color: #fff;
					&.btn_cancel {
						color: rgba(49, 56, 63, 1);
						background: #f5f7fa;
					}
				}
			}

			.quick_search {
				position: absolute;
				right: 0;
				top: 0;
				width: 8.333vw;
				padding-top: 1.944vw;
				button {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 5.556vw;
					height: 5.556vw;
					border-radius: 100%;
					color: #31383f;
					opacity: 0.5;
					margin: 1.667vw auto;
					font-size: $f12;
					&.on {
						color: #fff;
						opacity: 1;
						background: #31383f;
					}
				}
			}
			.searched_brands {
				padding: 0 12.222vw 0 6.667vw;
				.cont_top {
					padding: 4.444vw 0 1.111vw;
					.t1 {
						font-size: $f14;
						font-weight: 700;
						color: #343a40;
					}
					.t2 {
						font-size: $f13;
						color: #31383f;
						opacity: 0.75;
					}
					ul {
						li {
							display: flex;
							justify-content: flex-end;
							align-items: center;
							padding: 2.778vw 0;
							b {
								margin-right: auto;
								font-size: $f14;
								font-weight: 500;
								color: #3d454d;
							}
							span {
								margin-right: 3.333vw;
								font-size: $f12;
								color: #31383f;
								opacity: 0.75;
							}
						}
					}
				}

				.search_order_list {
					padding-bottom: 10vw;
					h3 {
						margin-top: 3.333vw;
						font-size: $f14;
						font-weight: 700;
						color: #343a40;
						padding-bottom: 1.111vw;
						border-bottom: 0.278vw solid #edf0f3;
					}
					ul {
						li {
							display: flex;
							justify-content: flex-end;
							align-items: center;
							padding: 2.778vw 0;
							b {
								margin-right: auto;
								font-size: $f14;
								font-weight: 500;
								color: #3d454d;
							}
							span {
								margin-right: 3.333vw;
								font-size: $f12;
								color: #31383f;
								opacity: 0.75;
							}
						}
					}
				}
			}
		}

		.purchase_reservation {
			background-color: #fff;
			padding: 6.667vw 6.667vw 0;
			> .t1 {
				font-size: $f13;
				font-weight: 500;
				color: #31383f;
				opacity: 0.75;
			}
			h3 {
				font-size: $f13;
				font-weight: 500;
				color: #31383f;
				margin: 6.667vw 0 1.667vw;
				span {
					color: #ff5353;
				}
			}
			.branch_list {
				display: flex;
				flex-wrap: wrap;
				margin-top: 3.889vw;
				li {
					width: 50%;
					margin-bottom: 3.444vw;
					label {
						display: flex;
						align-items: center;
					}
					span {
						font-size: $f16;
						font-weight: 500;
						color: #31383f;
						margin-left: 2.222vw;
					}
				}
			}
			.visit_schedule {
				display: flex;
				justify-content: space-between;
				input.date {
					width: 50vw;
					background: url(../../img/common/ic_calendar.svg) no-repeat right 3.333vw top 50% /
						6.667vw;
				}
				select {
					background: #fff url(../../img/common/ic_drop_down.svg) no-repeat right 3.333vw top 50% /
						6.667vw;
					border: 0.278vw solid #e7ecf1;
					border-radius: 1.111vw;
					height: 13.333vw;
					width: 34.444vw;
					color: rgba(49, 56, 63, 0.5);
					font-size: $f14;
					font-weight: 500;
				}
			}
			.btn_wrap {
				display: flex;
				align-items: center;
				width: 100%;
				height: 25.556vw;
				background-color: #fff;
				margin-top: 27.222vw;
				.btn {
					width: 100%;
					height: 13.333vw;
					display: flex;
					justify-content: center;
					align-items: center;
					background: #31383f;
					border-radius: 1.111vw;
					color: #fff;
					font-size: $f16;
					font-weight: 500;
				}
			}

			.agree_box {
				display: flex;
				border-bottom: 0.278vw solid #edf0f3;
				padding-bottom: 5.556vw;
				margin: 5.556vw 0 3.333vw;
				input {
					margin-right: 2.222vw;
				}
				div {
					width: 78.889vw;
					.t1 {
						font-size: $f14;
						font-weight: 500;
						color: #3d454d;
						a {
							text-decoration: underline;
						}
					}
					.t2 {
						margin-top: 1.944vw;
						font-size: $f12;
						font-weight: 500;
						color: #31383f;
						opacity: 0.75;
					}
				}
			}
			.error_txt {
				font-size: $f13;
				font-weight: 500;
				color: #ff5353;
			}
		}
	}
}
