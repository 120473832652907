@charset "UTF-8";
.pc-wrapper {
	html,
	body {
		height: 100%;
		-webkit-text-size-adjust: none;
		font-family: 'Spoqa Han Sans', dotum, '돋움', Arial, Sans-serif;
		font-size: 18px;
		color: #343a40;
	}

	body,
	input,
	select,
	button,
	textarea,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	table {
		line-height: 1.5;
		font: inherit;
		color: inherit;
	}

	html,
	body,
	div,
	form,
	input,
	select,
	button,
	textarea,
	legend,
	fieldset,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	ul,
	ol,
	dl,
	li,
	dt,
	dd,
	blockquote,
	address,
	p,
	th,
	td,
	caption {
		margin: 0;
		padding: 0;
		font-style: normal;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}

	ol,
	ul,
	li {
		list-style: none;
	}

	img,
	fieldset {
		vertical-align: middle;
		border: 0 none;
	}

	input,
	select,
	textarea {
		vertical-align: middle;
		resize: none;
		outline: none;
	}

	input[type='text'],
	input[type='password'],
	input[type='submit'],
	input[type='search'],
	input[type='email'],
	input[type='tel'],
	textarea {
		-webkit-appearance: none;
		border-radius: 0;
	}

	select {
		padding: 5px 20px 5px 10px;
		border-radius: 0;
		-webkit-appearance: none;
		border-radius: 0;
		border: 1px solid #c9c9c9;
	}

	/* IE 에서 Select box 화살표 제거 */
	select::-ms-expand {
		display: none;
	}

	button {
		border: 0 none;
		background: transparent;
		cursor: pointer;
		outline: none;
		border-radius: 1px;
	}

	hr {
		display: none;
	}

	legend,
	.hide {
		position: absolute;
		left: -9999px;
	}

	table caption {
		width: 0;
		height: 0;
		visibility: hidden;
		text-indent: -9999px;
		font-size: 0;
		line-height: 0;
	}

	table {
		border-collapse: collapse;
		border-spacing: 0;
		width: 100%;
		table-layout: fixed;
	}

	header,
	footer,
	section,
	article,
	aside,
	nav,
	hgroup,
	details,
	menu,
	figure,
	figcaption {
		display: block;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}

	/* common */
	.left_box {
		float: left;
	}

	.right_box {
		float: right;
	}

	.blind {
		width: 1px;
		height: 1px;
		font-size: 0;
		line-height: 0;
		position: absolute;
		top: 0;
		left: -1000%;
		overflow: hidden;
	}
	.ellipsis {
		text-overflow: ellipsis;
		white-space: nowrap;
		word-wrap: normal;
		width: 100%;
		overflow: hidden;
	}
	a {
		color: inherit;
		text-decoration: none;
		&:active,
		&:hover,
		&:focus {
			text-decoration: none;
			color: #be9162;
			outline: none;
		}
		border-radius: 1px;
	}

	input[type='checkbox'] {
		outline: none;
		appearance: none;
		display: inline-block;
		width: 20px;
		height: 20px;
		background: url(../img/common/ico_checkbox_off.svg) no-repeat 0 0 / cover;
		&:checked {
			background: url(../img/common/ico_checkbox_on.svg) no-repeat 0 0 / cover;
			border: 0 solid #000;
		}
	}
	input[type='radio'] {
		outline: none;
		appearance: none;
		display: inline-block;
		width: 24px;
		height: 24px;
		background: url(../img/common/ico_radio_off.svg) no-repeat 0 0 / cover;
		&:checked {
			background: url(../img/common/ico_radio_on.svg) no-repeat 0 0 / cover;
			border: 0 solid #000;
		}
	}
	input[type='checkbox'].round {
		outline: none;
		appearance: none;
		display: inline-block;
		width: 24px;
		height: 24px;
		background: url(../img/common/ico_radio_off.svg) no-repeat 0 0 / cover;
		&:checked {
			background: url(../img/common/ico_radio_on.svg) no-repeat 0 0 / cover;
			border: 0 solid #000;
		}
	}
	.clear:after {
		content: '';
		display: block;
		clear: both;
	}

	.fl {
		float: left;
	}

	.fr {
		float: right;
	}

	.m_br {
		display: none;
	}

	.pc_br {
		display: block;
	}

	.wrap {
		position: relative;
		width: 100%;
	}

	body {
		overflow-x: hidden;
	}

	.bg_dimmed {
		display: none;
		position: fixed;
		left: 0;
		top: 0;
		background: rgba(0, 0, 0, 0.2);
		width: 100%;
		height: 100%;
		z-index: 100;
	}

	.poppins {
		font-family: 'Poppins';
	}

	header {
		// 프론트 : width 100%, .header_inner_wrap 추가
		// max-width: 1920px;
		width: 100%;
		// margin:0 auto;
		// display: flex;
		// justify-content: space-between;
		// align-items: center;
		// padding:0 36px;
		// height:64px;
		// color:#31383F;
		position: relative;
		.header_inner_wrap {
			max-width: 1920px;
			margin: 0 auto;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 0 36px;
			height: 64px;
			color: #31383f;
			position: relative;
		}
		&:after {
			content: '';
			display: block;
			width: 100%;
			height: 1px;
			background: rgba(18, 18, 18, 0.5);
			opacity: 0.2;
			position: absolute;
			left: 0;
			bottom: 0;
		}
		h1 img {
			width: 124px;
		}
		.gnb {
			display: flex;
			font-size: 15px;
			font-weight: 500;
			li {
				margin: 0 22px;
			}
		}
		.lnb {
			display: flex;
			font-size: 13px;
			font-weight: 500;
			li {
				margin: 0 6px;
				position: relative;
				&:first-child {
					padding-right: 12px;
					:after {
						content: '';
						display: block;
						width: 1px;
						height: 10px;
						background: rgba(49, 56, 63, 0.75);
						position: absolute;
						right: 0;
						top: 50%;
						transform: translateY(-50%);
					}
				}
			}
			//프론트 open-chat 추가
			.open-chat {
				padding-right: 12px;
				:after {
					content: '';
					display: block;
					width: 1px;
					height: 10px;
					background: rgba(49, 56, 63, 0.75);
					position: absolute;
					right: 0;
					top: 50%;
					transform: translateY(-50%);
				}
			}
		}
	}
	footer {
		max-width: 1920px;
		margin: 0 auto;
		padding: 36px 40px;
		background: #212529;
		color: #fff;
		font-size: 13px;
		font-weight: 500;
		position: relative;
		display: flex;
		justify-content: center;
		&::after {
			content: '';
			display: block;
			width: 200%;
			height: 250px;
			background: #212529;
			position: absolute;
			top: 0;
			left: -100%;
			z-index: -1;
		}
		&::before {
			content: '';
			display: block;
			width: 200%;
			height: 250px;
			background: #212529;
			position: absolute;
			top: 0;
			right: -100%;
			z-index: -1;
		}
		a:hover {
			color: #fff;
		}
		nav {
			display: flex;
			justify-content: space-between;
			.link {
				display: flex;
				align-items: center;
				li {
					padding-right: 20px;
					margin-right: 20px;
					position: relative;
					opacity: 0.9;
					&:after {
						content: '';
						display: block;
						width: 1px;
						height: 10px;
						background-color: #979797;
						position: absolute;
						right: 0;
						top: 50%;
						transform: translateY(-50%);
					}
					&:last-child {
						padding-right: 0;
						margin-right: 0;
						&:after {
							display: none;
						}
					}
				}
			}
			.sns_list {
				display: flex;
				justify-content: space-between;
				width: 248px;
			}
		}
		address {
			margin-top: 20px;
			p {
				margin-bottom: 12px;
				&:last-child {
					margin-bottom: 0;
				}
				span {
					opacity: 0.9;
					margin-right: 12px;
					&:first-child {
						opacity: 0.75;
					}
					&:nth-child(2) {
						padding-right: 20px;
						margin-right: 20px;
						position: relative;
						&:after {
							content: '';
							display: block;
							width: 1px;
							height: 10px;
							background-color: #979797;
							position: absolute;
							right: 0;
							top: 50%;
							transform: translateY(-50%);
						}
					}
				}
				a {
					text-decoration: underline;
					opacity: 0.9;
				}
				// &:last-child {
				span {
					&:nth-child(even) {
						padding-right: 20px;
						margin-right: 20px;
						position: relative;
						&:after {
							content: '';
							display: block;
							width: 1px;
							height: 10px;
							background-color: #979797;
							position: absolute;
							right: 0;
							top: 50%;
							transform: translateY(-50%);
						}
					}
					&:last-child {
						&:after {
							content: '';
							display: none;
						}
					}
				}
				// }
			}
		}
		.copy {
			margin-top: 40px;
			opacity: 0.9;
		}
	}

	.paging {
		display: flex;
		justify-content: center;
		a {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 32px;
			height: 32px;
			font-size: 14px;
			margin: 0 6px;
			&:hover {
				color: #fff;
				background-color: #31383f;
			}
		}
		.btn.prev {
			background: url(../img/common/btn_paging_prev.svg) no-repeat 50% 50%;
		}
		.btn.next {
			background: url(../img/common/btn_paging_next.svg) no-repeat 50% 50%;
		}
	}
	.auto_regist {
		display: flex;
		margin-top: 5px;
		.img_box {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 98px;
			height: 48px;
			border: 1px solid rgba(52, 58, 64, 1);
			margin-right: 8px;
		}
		input {
			width: 160px;
			margin-right: 10px;
			border: 1px solid #c2ccd5;
		}
		button {
			width: 48px;
			height: 48px;
		}
	}
	.popup {
		width: 440px;
		padding: 28px 30px 30px;
		position: absolute;
		left: 50%;
		top: 50%;
		background-color: #fff;
		transform: translate(-50%, -50%);
		z-index: 1000;
		box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
		border-radius: 2px;
		h2 {
			font-size: 20px;
			font-weight: 700;
			& + p {
				margin-top: 9px;
				font-size: 14px;
				color: rgba(52, 58, 64, 0.75);
				line-height: 1.55;
			}
		}
		input:not[type='checkbox'] {
			display: flex;
			align-items: center;
			padding: 0 16px;
			height: 48px;
			border: 1px solid #c2ccd5;
			box-sizing: border-box;
			font-size: 14px;
		}
		input[type='text'] {
			display: flex;
			align-items: center;
			padding: 0 16px;
			height: 48px;
			border: 1px solid #c2ccd5;
			box-sizing: border-box;
			font-size: 14px;
		}
		p + input {
			margin-top: 10px;
			width: 100%;
		}
		input + p {
			font-size: 12px;
			margin-top: 24px;
		}
		.btn_wrap {
			margin-top: 80px;
			button {
				min-width: 90px;
				padding: 0 30px;
				height: 48px;
				border: 1px solid rgba(49, 56, 63, 1);
				font-size: 16px;
				margin-left: 8px;
				border-radius: 1px;
				&.ok {
					background-color: rgba(49, 56, 63, 1);
					color: #fff;
				}
				&.full {
					width: 100%;
					background-color: rgba(49, 56, 63, 1);
					color: #fff;
					margin-left: 0;
				}
			}
			&.right {
				justify-content: flex-end;
			}
		}
	}

	.alert {
		position: absolute;
		width: 368px;
		height: 200px;
		padding: 24px;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		background-color: #fff;
		z-index: 1000;
		box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
		border-radius: 2px;
		p {
			font-weight: 500;
			font-size: 16px;
			color: #31383f;
			line-height: 1.6;
		}
		p.t1 {
			font-weight: bold;
			font-size: 20px;
		}
		.btn_wrap {
			position: absolute;
			right: 24px;
			bottom: 24px;
			button {
				width: 90px;
				height: 48px;
				border: 1px solid rgba(49, 56, 63, 1);
				font-size: 16px;
				margin-left: 8px;
				&.ok {
					background-color: rgba(49, 56, 63, 1);
					color: #fff;
				}
			}
			&.right {
				justify-content: flex-end;
			}
		}
	}
}
/*# sourceMappingURL=common.css.map */
