@charset "UTF-8";
.mobile-wrapper {
	.auction {
		.auction_top {
			padding: 8.889vw 6.667vw 3.889vw;
			border-bottom: 0.278vw solid #edf0f3;
			background-color: #fff;
			h2 {
				font-size: $f24;
				font-weight: 700;
				color: #31383f;
			}
		}
		.recently_products {
			padding: 6.389vw 6.667vw 6.667vw;
			position: relative;
			h3 {
				margin-bottom: 3.333vw;
				font-size: $f20;
				font-weight: 700;
			}
			.swiper-slide {
				display: flex;
				background: #fff;
				border: 1px solid #edf0f3;
				border-radius: 1.111vw;
				padding: 4.444vw 0 5.556vw;
				position: relative;
				.img_box {
					display: flex;
					align-items: center;
					width: 42%;
					img {
						width: 90%;
					}
				}
				.info_box {
					width: 58%;
					padding: 0 4.631vw;
					.count_box {
						display: flex;
						align-items: center;
						color: #ff5353;
						margin: 0 0 3.333vw;
						span {
							display: flex;
							justify-content: center;
							align-items: center;
							width: 6.667vw;
							height: 6.111vw;
							font-size: $f13;
							font-weight: 500;
							color: #fff;
							background: #ff5353;
							border-radius: 0.556vw;
							margin: 0 1.111vw;
							&:first-child {
								margin-left: 0;
								// 프론트 : 추가 width와 padding
								width: fit-content;
								min-width: 6.667vw;
								padding: 0 1%;
							}
						}
						b {
							font-size: $f13;
							margin-left: 1.111vw;
						}
					}
					.sale {
						display: inline-flex;
						align-items: center;
						height: 5.786vw;
						background: #be9162;
						border-radius: 0.278vw;
						padding: 0 1.736vw;
						font-size: $f9;
						color: #fff;
						position: absolute;
						left: 2.222vw;
						top: 2.222vw;
					}
					.watch_name {
						font-size: $f13;
						font-weight: 500;
						color: #2b3035;
						opacity: 0.6;
						line-height: 138%;
						margin-top: 1.736vw;
					}
					.user {
						font-size: $f13;
						line-height: 108%;
						color: #31383f;
						margin-top: 2.314vw;
						font-weight: 500;
						b {
							font-weight: 700;
						}
					}
					ul {
						margin-top: 4.631vw;
						display: flex;
						flex-wrap: wrap;
						li {
							font-size: $f11;
							color: #31383f;
							opacity: 0.6;
							font-weight: 500;
							&:nth-child(1)::after {
								content: '';
								display: inline-block;
								width: 1px;
								height: 2.778vw;
								margin: 0 1.667vw;
								background: #a1acb7;
								opacity: 0.5;
								transform: translateY(0.2vw);
							}
							&:last-child {
								width: 100%;
								margin-top: 1.267vw;
							}
						}
					}
					> div {
						display: flex;
						align-items: center;
						margin-top: 3.472vw;
					}
					.option {
						display: inline-flex;
						align-items: center;
						height: 5.556vw;
						img {
							width: 4.214vw;
							margin-right: 3.178vw;
							&:nth-child(2) {
								width: 4.478vw;
								margin-right: 2.392vw;
							}
						}
					}
					.fluctuations {
						display: inline-flex;
						align-items: center;
						justify-content: center;
						width: 5.786vw;
						height: 5.786vw;
						border-radius: 0.556vw;
						font-size: $f9;
						font-weight: 500;
						color: #fff;
						&.up {
							background: #309d5c;
						}
						&.down {
							background: #d74c4c;
						}
					}
				}
			}

			.swiper-pagination {
				position: static;
				right: 5vw;
				top: 12.5vw;
			}
			.swiper-pagination-bullet {
				width: 4.444vw;
				height: 0.556vw;
				background: #31383f;
				opacity: 0.3;
				margin: 0 0.5vw;
				border-radius: 0;
			}
			.swiper-pagination-bullet-active {
				opacity: 1;
			}
		}

		.product_list {
			background-color: #fff;
			.total_txt {
				font-size: $f14;
				padding: 5vw 6.944vw 4vw;
				color: #31383f;
				b {
					font-weight: 600;
				}
			}
			> ul {
				display: flex;
				flex-wrap: wrap;
				border-top: 1px solid #edf0f3;
				> li {
					width: 50%;
					border-top: 1px solid #edf0f3;
					&:nth-child(odd) {
						border-right: 1px solid #edf0f3;
					}
					&:nth-child(-n + 2) {
						border-top: 0 solid #edf0f3;
					}
				}
				.top {
					position: relative;
					padding-top: 8.889vw;
					img {
						display: block;
						margin: 0 auto;
						width: 73.333%;
					}
					.info {
						position: absolute;
						left: 0;
						top: 0;
						display: flex;
						width: 100%;
						padding: 2.778vw 2.778vw 0;
						color: #fff;
						.sale {
							display: flex;
							align-items: center;
							background: #be9162;
							border-radius: 0.278vw;
							font-size: $f9;
							padding: 0 1.667vw;
							height: 5.556vw;
						}
						.option {
							display: flex;
							align-items: center;
							position: absolute;
							height: 5.556vw;
							top: 2.778vw;
							right: 8.444vw;
							img {
								width: 4.044vw;
								margin-right: 2.5vw;
								&:nth-child(2) {
									width: 4.3vw;
									margin-right: 1.8vw;
								}
							}
						}
						.fluctuations {
							position: absolute;
							right: 2.778vw;
							top: 2.778vw;
							display: flex;
							align-items: center;
							justify-content: center;
							width: 5.556vw;
							height: 5.556vw;
							border-radius: 0.556vw;
							font-size: $f9;
							font-weight: 500;
							&.up {
								background: #309d5c;
							}
							&.down {
								background: #d74c4c;
							}
						}
					}
				}
				.btm {
					padding: 3.333vw 4.444vw;
					.watch_name {
						font-size: $f13;
						line-height: 138%;
						font-weight: 500;
						color: #2b3035;
						opacity: 0.6;
						height: 10vw;
						width: 90%;
					}
					.price {
						margin-top: 2.222vw;
						line-height: 108%;
						font-size: $f13;
						font-weight: 700;
						color: #31383f;
					}
					ul {
						margin-top: 5vw;
						display: flex;
						flex-wrap: wrap;
						li {
							font-size: $f11;
							color: #31383f;
							opacity: 0.6;
							font-weight: 500;
							&:nth-child(1)::after {
								content: '';
								display: inline-block;
								width: 1px;
								height: 2.778vw;
								margin: 0 1.667vw;
								background: #a1acb7;
								opacity: 0.5;
								transform: translateY(0.2vw);
							}
							&:last-child {
								width: 100%;
								margin-top: 1.267vw;
							}
						}
					}
				}
			}
			.nodata {
				padding: 15.278vw 0 90vw;
				text-align: center;
				.t1 {
					font-size: $f18;
					font-weight: 700;
					color: #31383f;
					line-height: 142%;
					margin-bottom: 3.333vw;
				}
				.t2 {
					font-size: $f13;
					font-weight: 500;
					color: #31383f;
					line-height: 140%;
					b {
						font-weight: 700;
						color: #be9162;
					}
				}
				.link {
					display: inline-flex;
					justify-content: center;
					align-items: center;
					padding: 0 4.444vw;
					margin: 9.444vw auto 0;
					height: 12.222vw;
					font-size: $f14;
					color: #fff;
					font-weight: 500;
					background: #31383f;
					border-radius: 1.111vw;
				}
			}
		}

		.product_detail {
			padding-bottom: 25.556vw;
			.product_top {
				margin-bottom: 2.222vw;
				padding: 4.444vw;
				background: #fff;
				position: relative;
				.img_box {
					padding: 0 14.444vw;
					img {
						width: 100%;
					}
				}
				.count_box {
					display: flex;
					align-items: center;
					color: #ff5353;
					margin: 0 0 3.333vw;
					span {
						display: flex;
						justify-content: center;
						align-items: center;
						width: 6.667vw;
						height: 6.111vw;
						font-size: $f13;
						font-weight: 500;
						color: #fff;
						background: #ff5353;
						border-radius: 0.556vw;
						margin: 0 1.111vw;
						&:first-child {
							margin-left: 0;
							// 프론트 : 추가 width와 padding
							width: fit-content;
							min-width: 6.667vw;
							padding: 0 1%;
						}
					}
					b {
						font-size: $f13;
						margin-left: 1.111vw;
					}
				}
				.swiper-pagination {
					position: static;
					margin: 4.167vw 0;
					.swiper-pagination-bullet {
						width: 1.667vw;
						height: 1.667vw;
						margin: 0 1.111vw;
						background: #31383f;
						opacity: 0.5;
					}
					.swiper-pagination-bullet-active {
						opacity: 1;
					}
				}
				.t1 {
					font-size: $f13;
					font-weight: 500;
					color: #31383f;
					opacity: 0.75;
					b {
						font-weight: 700;
						color: #be9162;
					}
				}
				.sale {
					position: absolute;
					left: 4.444vw;
					top: 4.444vw;
					display: inline-flex;
					align-items: center;
					background: #be9162;
					border-radius: 0.278vw;
					font-size: $f9;
					padding: 0 1.667vw;
					height: 5.556vw;
					color: #fff;
					z-index: 10;
				}
			}

			.product_info {
				background: #fff;
				margin-bottom: 3.333vw;
				.info_box {
					padding: 5.556vw 6.667vw 7.778vw;
					border-bottom: 0.278vw solid #edf0f3;
					margin-bottom: 5.556vw;
					color: #31383f;
					font-weight: 500;
					.t1 {
						font-weight: 500;
						font-size: $f16;
						opacity: 0.6;
						margin-bottom: 2.222vw;
					}
					.t2 {
						font-size: $f24;
						font-weight: 700;
						margin-bottom: 3.333vw;
					}
					.t3 {
						font-size: $f14;
						line-height: 4.444vw;
						margin-bottom: 7.778vw;
					}
					.price {
						font-size: $f24;
						font-weight: 700;
					}
					.bidding_box {
						.t1 {
							font-size: $f14;
							font-weight: 500;
							opacity: 1;
							b {
								font-weight: 700;
							}
						}
						.btn {
							width: 100%;
							height: 13.333vw;
							display: flex;
							justify-content: center;
							align-items: center;
							background: #31383f;
							border-radius: 1.111vw;
							color: #fff;
							font-size: $f16;
							font-weight: 500;
							margin-top: 2.222vw;
						}
						.error_txt {
							font-size: $f13;
							font-weight: 500;
							color: #ff5353;
							margin: 2.222vw 0 4.444vw;
						}
					}
				}

				.info_list {
					padding: 0 7.778vw;
					display: flex;
					justify-content: space-between;
					li {
						text-align: center;
						b {
							font-weight: 500;
							font-size: $f12;
							color: #31383f;
							opacity: 0.75;
						}
						div {
							display: flex;
							justify-content: center;
							align-items: center;
							height: 7.778vw;
							margin-top: 3.333vw;
							.fluctuations {
								display: flex;
								justify-content: center;
								align-items: center;
								width: 7.778vw;
								height: 7.778vw;
								border-radius: 0.556vw;
								color: #fff;
								font-size: $f11;
								&.up {
								}
								&.down {
									background: #d74c4c;
								}
							}
						}
						img {
							width: 7.778vw;
						}
					}
				}
			}

			.branch_list {
				padding: 0 6.667vw 6.667vw;
				h3 {
					font-size: $f14;
					font-weight: 700;
					color: #31383f;
				}
				ul {
					margin-top: 3.333vw;
					display: flex;
					justify-content: space-between;
					li {
						opacity: 0.5;
						&.checked {
							opacity: 1;
						}
					}

					label {
						display: flex;
						align-items: center;
						font-size: $f14;
						span {
							color: #31383f;
							margin-left: 1.667vw;
						}
					}
				}
			}

			.info_wrap {
				background: #fff;
				padding: 6.667vw 6.667vw 16.667vw;

				h3 {
					font-size: $f20;
					font-weight: 700;
					color: #31383f;
					margin-bottom: 5.556vw;
				}
				ul {
					padding-bottom: 6.667vw;
					margin-bottom: 6.667vw;
					border-bottom: 0.278vw solid #edf0f3;
					li {
						display: flex;
						justify-content: space-between;
						align-items: center;
						font-size: $f14;
						font-weight: 500;
						color: #31383f;
						margin-bottom: 4.444vw;
						&:last-child {
							margin-bottom: 0;
						}
						b {
							opacity: 0.6;
							font-weight: 500;
						}
					}
				}
				.txt {
					font-size: $f14;
					font-weight: 500;
					color: #31383f;
					opacity: 0.6;
					line-height: 143%;
				}
			}

			.btn_wrap {
				display: flex;
				align-items: center;
				width: 100%;
				height: 25.556vw;
				position: fixed;
				left: 0;
				bottom: 0;
				padding: 0 6.667vw;
				border-top: 0.278vw solid #edf0f3;
				background-color: #fff;
				z-index: 10;

				.btn {
					width: 100%;
					height: 13.333vw;
					display: flex;
					justify-content: center;
					align-items: center;
					background: #31383f;
					border-radius: 1.111vw;
					color: #fff;
					font-size: $f16;
					font-weight: 500;
				}
			}
		}

		.filter_wrap {
			position: fixed;
			left: 50%;
			bottom: 5.556vw;
			transform: translateX(-50%);
			background: #fff;
			border: 0.278vw solid #f3f5f7;
			box-shadow: 0 1.111vw 1.111vw rgba(0, 0, 0, 0.15);
			border-radius: 27.778vw;
			display: flex;
			align-items: center;
			padding: 2.778vw 4.444vw;
			button {
				display: flex;
				justify-content: center;
				align-items: center;
				padding-left: 6.667vw;
				font-size: $f12;
				line-height: 5.556vw;
				&.btn_filter {
					background: url(../../img/common/ic_filter.svg) no-repeat 0 0 / 5.556vw;
					&::after {
						content: '';
						display: block;
						width: 0.278vw;
						height: 4.444vw;
						background: #d1d9df;
						margin: 0 3.333vw;
					}
					span {
						font-size: $f11;
						color: #fff;
						font-weight: 500;
						display: flex;
						justify-content: center;
						align-items: center;
						width: 5vw;
						height: 5vw;
						background: #31383f;
						border-radius: 100%;
						margin-left: 0.556vw;
					}
				}
				&.btn_sort {
					background: url(../../img/common/ic_align.svg) no-repeat 0 0 / 5.556vw;
				}
			}
		}
		//프론트 추가 -> 옥션 시계 찾아줘
		.nodata_wrap {
			padding: 15.278vw 0 30vw;
			text-align: center;
			background-color: #fff;
			.t1 {
				font-size: $f18;
				font-weight: 700;
				color: #31383f;
				line-height: 142%;
				margin-bottom: 3.333vw;
			}
			.t2 {
				font-size: $f13;
				font-weight: 500;
				color: #31383f;
				line-height: 140%;
				b {
					font-weight: 700;
					color: #be9162;
				}
			}
			.link {
				display: inline-flex;
				justify-content: center;
				align-items: center;
				padding: 0 4.444vw;
				margin: 9.444vw auto 0;
				height: 12.222vw;
				font-size: $f14;
				color: #fff;
				font-weight: 500;
				background: #31383f;
				border-radius: 1.111vw;
			}
		}
	}
}
