@charset "UTF-8";
.pc-wrapper {
	.container {
		padding-bottom: 100px;
		&.main {
			padding-bottom: 0;
		}
	}
	.sub_top {
		width: 100%;
		height: 240px;
		display: flex;
		align-items: center;
		flex-direction: column;
		// 프론트 justify-content => 중앙정렬이 안되서
		justify-content: center;
		color: #fff;
		h1 {
			font-size: 36px;
			font-weight: 700;
		}
		p {
			margin-top: 10px;
			font-size: 16px;
			font-weight: 500;
		}
	}
	.intro {
		height: 100vh;
		position: relative;
		display: flex;
		flex-direction: column;
		text-align: center;
		align-items: center;
		padding-top: 48px;
		background: url(../img/common/mobile_bg.png) no-repeat 0 0 / cover;
		.t1 {
			font-size: 6.667vw;
			font-weight: 500;
			margin-top: 28px;
			color: #2b2f32;
		}
		.t2 {
			font-size: 7.778vw;
			font-weight: 500;
			margin-top: 8px;
			color: #2b2f32;
			b {
				font-weight: 700;
			}
		}
		.t3 {
			width: 90vw;
			text-align: center;
			background: #31383f;
			border-radius: 4px;
			color: #fff;
			font-size: 6.286vw;
			font-weight: 400;
			line-height: 38px;
			padding: 24px 0;
			position: absolute;
			left: 50%;
			bottom: 5%;
			transform: translateX(-50%);
		}
		.logo img {
			width: 34.444vw;
		}
	}
	.info {
		height: 100vh;
		display: flex;
		flex-direction: column;
		text-align: center;
		align-items: center;
		justify-content: center;
		padding: 110px 0;
		.top_img_box {
			width: 400px;
			& img {
				margin-left: 40px;
			}
		}
		.t1 {
			font-size: 20px;
			font-weight: 700;
			margin-top: 28px;
		}
		.t2 {
			font-size: 13px;
			font-weight: 500;
			margin-top: 12px;
			line-height: 1.55;
		}
		.t3 {
			font-size: 14px;
			font-weight: 700;
			color: #be9162;
			margin-top: 48px;
		}
		.t4 {
			font-size: 14px;
			font-weight: 700;
			margin-top: 12px;
		}
		.logo {
			margin-top: 112px;
		}
	}
	.faq {
		.sub_top {
			// 프론트 수정 시작

			// background: url(../img/common/sub_top_notice.png) no-repeat 0 0 / cover;
			background: rgb(0, 0, 0);
			position: relative;
			overflow: hidden;

			.sub_top_mini_img {
				position: absolute;
				background: linear-gradient(
						to right,
						rgba(0, 0, 0, 1) 16%,
						rgba(0, 0, 0, 0.75) 30%,
						rgba(20, 20, 20, 0.5) 50%,
						rgba(0, 0, 0, 0.7) 65%,
						rgba(0, 0, 0, 1) 100%
					),
					url(../front-img/faq_new_banner.png);
				background-size: 90% 88%;
				background-position: 17% 53%;
				width: 1200px;
				height: 1700px;
				background-repeat: no-repeat;
			}
			// 프론트 수정끝. .sub_top_mini_img 추가 및 나머지 다 추가함
		}

		.inner {
			width: 1024px;
			margin: 0 auto;
		}
		.search_box {
			margin: 36px auto;
			width: 420px;
			height: 56px;
			position: relative;
			border-bottom: 1px solid rgba(49, 56, 63, 0.8);
			input {
				display: flex;
				border: 0 solid #000;
				width: 100%;
				height: 100%;
				&::placeholder {
					color: #31383f;
				}
			}
			button {
				width: 36px;
				height: 36px;
				background: url(../img/common/ico_search.svg) no-repeat 50% 50%;
				position: absolute;
				right: 10px;
				top: 50%;
				transform: translateY(-50%);
			}
		}
		.keyword_list {
			margin-top: 40px;
			display: flex;
			flex-wrap: wrap;
			font-size: 14px;
			color: #31383f;
			li {
				margin: 0 8px 8px 0;
				button {
					padding: 11px 16px;
					background: #f5f7fa;
					border: 1px solid #edf0f3;
					box-sizing: border-box;
					border-radius: 2px;
					&.on {
						background: #31383f;
						border: 1px solid #31383f;
						color: #fff;
					}
				}
			}
		}
		.faq_list {
			margin: 40px 0;
			font-size: 16px;
			font-weight: 500;
			color: #31383f;
			button {
				display: flex;
				align-items: center;
				width: 100%;
				height: 68px;
				padding-left: 12px;
				text-align: left;
				outline: none;
				background: url(../img/common/ico_arrow_down.svg) no-repeat right 31px top 50%;
				border-bottom: 1px solid rgba(161, 172, 183, 0.4);
				&::before {
					content: 'Q.';
					display: inline-block;
					margin-right: 12px;
				}
			}
			button.on {
				border-bottom: 1px solid rgba(161, 172, 183, 1);
			}
			.answer {
				// 프론트 none 주석
				// display: none;
				padding: 40px 20px;
				border-bottom: 1px solid rgba(161, 172, 183, 0.4);
				line-height: 1.6;
			}
			.empty {
				height: 200px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}

	.notice {
		.sub_top {
			//프론트 수정 시작

			// background: url(../img/common/sub_top_notice.png) no-repeat 0 0 / cover;
			background: rgb(0, 0, 0);
			position: relative;
			overflow: hidden;

			.sub_top_mini_img {
				position: absolute;
				background: linear-gradient(
						to right,
						rgba(0, 0, 0, 1) 16%,
						rgba(0, 0, 0, 0.75) 30%,
						rgba(20, 20, 20, 0.5) 50%,
						rgba(0, 0, 0, 0.5) 65%,
						rgba(0, 0, 0, 1) 100%
					),
					url(../front-img/notice_new_banner.jpg);
				background-size: 120% 104%;
				background-position: 102% -630%;
				width: 756px;
				height: 557px;
				background-repeat: no-repeat;
			}
			// 프론트 수정끝. .sub_top_mini_img 추가 및 나머지 다 추가함
		}

		.inner {
			width: 1024px;
			margin: 0 auto;
		}
		.notice_list {
			margin: 72px 0 45px;
			font-size: 16px;
			font-weight: 500;
			li {
				display: flex;
				justify-content: space-between;
				padding: 24px 40px 24px 12px;
				border-bottom: 1px solid rgba(161, 172, 183, 0.4);
				&.tit {
					padding: 10px 40px 10px 12px;
					border-bottom: 1px solid rgba(49, 56, 63, 0.8);
					&:hover {
						background: #fff;
					}
					span {
						&:last-child {
							width: 85px;
						}
					}
				}
				&:hover {
					background-color: #f7f9fb;
				}
			}
			.ico {
				background-color: #ff5d5d;
				font-size: 11px;
				border-radius: 2px;
				padding: 7px 6px 5px;
				margin-right: 10px;
				color: #fff;
			}
		}
	}

	.notice_view {
		width: 1024px;
		margin-bottom: 56px;
		.view_top {
			margin-top: 64px;
			color: #31383f;
			.tit {
				display: flex;
				align-items: center;
				font-size: 32px;
				font-weight: 700;
				.ico {
					display: inline-flex;
					justify-content: center;
					align-items: center;
					font-weight: 500;
					margin-right: 12px;
					padding: 6px;
					font-size: 11px;
					color: #fff;
					background-color: #ff5d5d;
					border-radius: 2px;
				}
			}
			.date {
				margin: 16px 0;
				font-size: 20px;
				color: #31383f;
			}
		}
		.text_box {
			font-size: 16px;
			color: #31383f;
			line-height: 24px;
			padding: 40px 20px;
			border-top: 1px solid rgba(49, 56, 63, 0.8);
			border-bottom: 1px solid rgba(161, 172, 183, 0.4);
		}
		& + .btn_wrap .btn_list {
			font-size: 14px;
		}
	}

	.btn_wrap {
		display: flex;
		justify-content: center;
	}
	.btn_list {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		margin: 0 auto;
		padding: 16px 30px;
		border: 1px solid rgba(49, 56, 63, 0.5);
	}

	.shop {
		.sub_top {
			background: url(../img/common/sub_top_shop.png) no-repeat 0 0 / cover;
		}
		.shop_info {
			margin: 68px 0 120px;
			.info_top {
				width: 1000px;
				margin: 0 auto;
				display: flex;
				justify-content: space-between;
				& > p {
					font-size: 28px;
					line-height: 42px;
				}
				.intro_box {
					width: 585px;
					font-size: 16px;
					line-height: 22px;
					padding-bottom: 80px;
					p {
						margin-bottom: 20px;
						line-height: 1.6;
						&:last-child {
							margin: 40px 0 0;
							span {
								display: block;
								color: #d1ad86;
								font-size: 18px;
								margin-bottom: 5px;
							}
							strong {
								color: #31383f;
							}
						}
					}
				}
			}
			.info_mid {
				position: relative;
				padding-top: 72px;
				.in_cont {
					width: 1000px;
					margin: 0 auto;
					h2 {
						text-align: center;
						font-size: 32px;
						font-weight: 600;
						& + p {
							text-align: center;
							font-size: 18px;
							line-height: 29px;
						}
					}
					.tab_list {
						margin: 40px 0;
						display: flex;
						justify-content: center;
						a {
							display: block;
							position: relative;
							margin: 0 20px;
							padding-bottom: 8px;
							font-size: 20px;
							font-weight: 700;
							color: rgba(52, 58, 64, 0.5);
							&.on {
								color: rgba(52, 58, 64, 1);
								&:after {
									content: '';
									display: block;
									width: 100%;
									height: 4px;
									background-color: #d1ad86;
									position: absolute;
									left: 0;
									bottom: 0;
								}
							}
						}
					}
					.address_box {
						width: 900px;
						margin: 0 auto;
						transform: translateY(-35px);
						padding: 32px 36px 0;
						background-color: #fff;
						h3 {
							font-size: 28px;
							font-weight: 700;
						}
						dl {
							display: flex;
							flex-wrap: wrap;
							font-size: 16px;
							margin-top: 25px;
							div {
								width: 65%;
								display: flex;
								margin-bottom: 20px;
								&:nth-child(even) {
									width: 35%;
								}
							}
							dt {
								color: #343a40;
								font-weight: 700;
								width: 100px;
							}
							dd {
								color: #596066;
							}
						}
					}
					.shop_map {
						width: 836px;
						margin: 0 auto;
					}
				}
				&::after {
					content: '';
					display: block;
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 415px;
					//프론트 : background-color 수정
					// background-color: #edf0f3;
					background-color: white;
					z-index: -1;
				}
			}
		}
	}

	.login {
		.inner {
			width: 340px;
			margin: 80px auto;
		}
		h2 {
			text-align: center;
			font-size: 32px;
			font-weight: 700;
			border-bottom: 2px solid #31383f;
			border-radius: 1px;
			padding-bottom: 8px;
		}
		.login_input {
			margin-top: 30px;
			input:not([type='checkbox']) {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 100%;
				height: 48px;
				border: 1px solid #c2ccd5;
				border-radius: 1px;
				margin-bottom: 8px;
				padding: 0 16px;
				&:first-child {
					margin-top: 0;
				}
				&::placeholder {
					font-size: 16px;
					color: #31383f;
					opacity: 0.5;
				}
			}
			.error_txt {
				margin: 8px 0 0;
				font-size: 14px;
				color: #ff5353;
			}
			.btn_login {
				width: 100%;
				height: 48px;
				margin-top: 16px;
				background-color: #31383f;
				color: #fff;
				font-size: 16px;
				font-weight: 500;
			}
			.auto_login {
				margin-top: 12px;
				display: flex;
				align-items: center;
				font-size: 14px;
				font-weight: 500;
				input {
					outline: none;
					appearance: none;
					display: block;
					width: 20px;
					height: 20px;
					background: #fff;
					border: 1px solid #c2ccd5;
					border-radius: 1px;
					&:checked {
						background: url(../img/common/ico_check_on.svg) no-repeat 0 0 / cover;
						border: 0 solid #000;
					}
				}
				span {
					margin-left: 8px;
				}
			}
		}
		.link_wrap {
			display: flex;
			justify-content: center;
			margin-top: 30px;
			padding-top: 16px;
			border-top: 1px solid rgba(161, 172, 183, 0.35);
			font-size: 14px;
			a {
				position: relative;
				&:last-child {
					padding-left: 16px;
					margin-left: 16px;
				}
				&:last-child::after {
					display: block;
					content: '';
					width: 1px;
					height: 12px;
					background-color: #a1acb7;
					position: absolute;
					left: 0;
					top: 50%;
					transform: translateY(-50%);
					opacity: 0.35;
				}
			}
		}
	}

	.join_agree {
		p {
			margin-top: 8px;
			font-size: 14px;
			opacity: 0.75;
			line-height: 1.6;
			color: rgba(52, 58, 64, 1);
		}
		label {
			display: flex;
			align-items: center;
			margin-top: 20px;
			@at-root p + label span {
				font-weight: 700;
			}
			input {
				outline: none;
				appearance: none;
				display: block;
				width: 20px;
				height: 20px;
				background: #fff;
				border: 1px solid #c2ccd5;
				border-radius: 1px;
				&:checked {
					background: url(../img/common/ico_check_on.svg) no-repeat 0 0 / cover;
					border: 0 solid #000;
				}
			}
			span {
				margin-left: 8px;
				font-size: 14px;
			}
		}
		.scroll_box {
			width: 100%;
			height: 140px;
			margin-top: 12px;
			padding: 12px;
			overflow-y: auto;
			border: 1px solid #c2ccd5;
			font-size: 12px;
			line-height: 18px;
			color: rgba(0, 0, 0, 0.5);
		}
		.btn_join {
			margin-top: 40px;
			width: 100%;
			height: 48px;
			background-color: #31383f;
			border-radius: 1px;
			color: #fff;
			font-size: 16px;
			font-weight: 500;
		}
		.btn_home {
			display: block;
			font-size: 14px;
			margin: 20px auto 0;
			text-align: center;
		}
	}

	.join_member {
		padding-top: 20px;
		input + .t1 {
			margin-top: 12px;
		}
		.t1 {
			font-weight: 500;
			font-size: 12px;
			line-height: 26px;
			color: #343a40;
		}
		& > div {
			margin-bottom: 20px;
		}
		& > div input {
			width: 100%;
			display: flex;
			align-items: center;
			padding: 0 16px;
			height: 48px;
			border: 1px solid #c2ccd5;
			border-radius: 1px;
			font-weight: 500;
			font-size: 14px;
			& + input {
				margin-top: 8px;
			}
			&::placeholder {
				color: rgba(49, 56, 63, 0.5);
			}
		}
		& > label {
			display: flex;
			width: 100%;
			margin-bottom: 12px;
			span {
				margin-left: 8px;
				font-size: 14px;
				font-weight: 500;
			}
		}
		.txt {
			margin-top: 32px;
			font-size: 12px;
			color: rgba(52, 58, 64, 1);
			font-weight: 500;
		}
		.auto_regist {
			padding-bottom: 30px;
			margin-bottom: 8px;
			border-bottom: 1px solid rgba(161, 172, 183, 0.35);
			input {
				width: 150px;
			}
			button {
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
		.error_txt {
			margin: 8px 0 0;
			font-size: 14px;
			color: #ff5353;
		}
		.btn_join {
			margin-top: 28px;
			width: 100%;
			height: 48px;
			background-color: #31383f;
			border-radius: 1px;
			color: #fff;
			font-size: 16px;
			font-weight: 500;
		}
		.btn_home {
			display: block;
			font-size: 14px;
			margin: 20px auto 0;
			text-align: center;
			font-weight: 500;
		}
	}

	.mypage {
		.password_check {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			height: calc(100vh - 412px);
			text-align: center;
			color: #31383f;
			h2 {
				font-size: 20px;
				font-weight: 700;
			}
			.stit {
				margin-top: 12px;
				font-weight: 500;
				font-size: 13px;
				line-height: 18px;
			}
			.input_box {
				width: 340px;
				margin: 32px auto 0;
				p {
					text-align: left;
					font-weight: 500;
					font-size: 12px;
					line-height: 14px;
				}
				input {
					width: 100%;
					display: flex;
					align-items: center;
					margin-top: 6px;
					padding: 0 16px;
					height: 48px;
					border: 1px solid #c2ccd5;
					border-radius: 1px;
					font-weight: 500;
					font-size: 14px;
					&::placeholder {
						color: rgba(49, 56, 63, 0.5);
					}
				}
				.error_txt {
					margin: 8px 0 0;
					font-size: 14px;
					color: #ff5353;
				}
			}
			.btn_wrap {
				margin-top: 20px;
				display: flex;
				justify-content: center;
				.ok {
					display: flex;
					justify-content: center;
					align-items: center;
					font-weight: 500;
					font-size: 14px;
					color: #fff;
					width: 120px;
					height: 48px;
					background: #31383f;
					border-radius: 1px;
				}
			}
		}
	}
	.mypage_info {
		display: flex;
		justify-content: space-between;
		max-width: 1280px;
		padding: 0 40px;
		margin: 60px auto 20px;
		.profile_nav {
			width: 180px;
			margin-right: 30px;
			.user_wrap {
				font-size: 20px;
				color: #31383f;
				font-weight: 500;
				line-height: 1.2;
				strong {
					display: block;
					font-weight: 700;
				}
				&::before {
					content: '';
					display: block;
					width: 54px;
					height: 54px;
					margin-bottom: 17px;
					background: url(../img/common/ico_profile.svg) no-repeat 0 0 / cover;
				}
			}
			ul {
				margin-top: 20px;
				li:nth-child(1) {
					margin-bottom: 8px;
					border-bottom: 1px solid rgba(161, 172, 183, 0.35);
					padding-bottom: 24px;
					line-height: 1.2;
					.grade {
						display: block;
						font-size: 14px;
						color: #a1acb7;
						font-weight: 700;
					}
					a {
						font-size: 12px;
						font-weight: 500;
						color: #31383f;
						text-decoration: underline;
					}
				}
				li:nth-child(n + 2):nth-child(-n + 5) {
					padding: 15px 0;
					font-weight: 500;
					font-size: 16px;
					height: 50px;
					color: rgba(49, 56, 63, 0.75);
				}
				li:last-child {
					margin-top: 8px;
					padding-top: 23px !important;
					height: 58px !important;
					border-top: 1px solid rgba(161, 172, 183, 0.35);
				}
				li.on a {
					font-weight: 700;
					color: #31383f;
				}
			}
			.btn_logout {
				display: block;
				width: 100%;
				margin: 24px 0 15px;
				padding: 11px 0;
				height: 48px;
				border: 1px solid #31383f;
				border-radius: 1px;
				font-weight: 500;
				font-size: 16px;
			}
			.btn_withdrawal {
				font-weight: 500;
				font-size: 13px;
				color: rgba(49, 56, 63, 0.75);
			}
		}
		.mypage_cont {
			width: calc(100% - 210px);
			h2 {
				font-size: 24px;
				font-weight: 700;
				color: #31383f;
			}

			.btn_wrap {
				margin-top: 35px;
				display: flex;
				justify-content: center;
				button {
					display: flex;
					justify-content: center;
					align-items: center;
					margin: 0 4px;
					width: 120px;
					height: 48px;
					border: 1px solid #31383f;
					border-radius: 1px;
					font-weight: 500;
					font-size: 16px;
					&.ok {
						background: #31383f;
						color: #fff;
					}
				}
			}
			.error_txt {
				margin: 8px 0 0;
				font-size: 14px;
				color: #ff5353;
				text-align: center;
			}
			.change_box {
				margin-bottom: 40px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 440px;
			}
			h2 + .info_editor {
				margin-top: 9px;
				border-top: 1px solid #31383f;
				strong {
					font-size: 20px;
					color: #a1acb7;
					font-weight: 700;
				}
				button {
					display: inline-flex;
					align-items: center;
					justify-self: center;
					height: 40px;
					background: #31383f;
					border-radius: 1px;
					color: #fff;
					font-weight: 500;
					font-size: 14px;
					padding: 0 16px;
					margin-left: 16px;
				}
			}
			.info_editor {
				display: flex;
				padding: 30px 0 40px;
				border-bottom: 1px solid #cbcdcf;
				h3 {
					width: 275px;
					font-size: 20px;
					font-weight: 700;
				}
				input + .t1 {
					margin-top: 12px;
				}
				& > div {
					width: 440px;
				}
				.t1 {
					font-weight: 500;
					font-size: 12px;
					line-height: 26px;
					color: #343a40;
				}
				& > div input {
					width: 100%;
					display: flex;
					align-items: center;
					padding: 0 16px;
					height: 48px;
					border: 1px solid #c2ccd5;
					border-radius: 1px;
					font-weight: 500;
					font-size: 14px;
					& + input {
						margin-top: 8px;
					}
					&::placeholder {
						color: rgba(49, 56, 63, 0.5);
					}
				}
				& + .error_txt {
					margin-top: 16px;
				}
				.profile_img {
					margin-top: 28px;
					p {
						font-weight: 500;
						font-size: 12px;
						margin-bottom: 15px;
					}
				}
				label {
					display: flex;
					width: 100%;
					margin-bottom: 12px;
					input {
						outline: none;
						appearance: none;
						display: block;
						width: 20px;
						height: 20px;
						background: #fff;
						border: 1px solid #c2ccd5;
						border-radius: 1px;
						padding: 0;
						&:checked {
							background: url(../img/common/ico_check_on.svg) no-repeat 0 0 / cover;
							border: 0 solid #000;
						}
					}
					span {
						margin-left: 8px;
						font-size: 14px;
						font-weight: 500;
					}
				}
				.filebox {
					display: flex;
				}
				.filebox input[type='file'] {
					position: absolute;
					width: 1px;
					height: 1px;
					padding: 0;
					margin: -1px;
					overflow: hidden;
					clip: rect(0, 0, 0, 0);
					border: 0;
				}
				.upload-hidden {
					display: none;
				}
				.filebox label {
					display: inline-block;
					width: 84px;
					height: 48px;
					padding: 14px 0.75em;
					font-size: 14px;
					text-align: center;
					cursor: pointer;
					color: #fff;
					background: #31383f;
					border-radius: 1px;
					box-sizing: border-box;
					margin-bottom: 0;
				}
				/* named upload */
				.filebox .upload-name {
					display: flex;
					width: 274px;
					justify-content: center;
					align-items: center;
					padding: 0 16px;
					height: 48px;
					font-weight: 500;
					font-size: 14px;
					background-color: #fff;
					border: 1px solid #c2ccd5;
					box-sizing: border-box;
					border-radius: 1px;
				}
			}

			.list_wrap {
				position: relative;
				& + .list_wrap {
					margin-top: 60px;
				}
				.btn_all {
					position: absolute;
					right: 0;
					top: 7px;
					font-weight: 500;
					font-size: 12px;
					color: #31383f;
					text-decoration: underline;
				}
				.tbl {
					margin-top: 10px;
					border-top: 2px solid #31383f;
					th {
						font-size: 13px;
						text-align: left;
						padding: 18px 0 18px 10px;
						font-weight: 500;
						border-bottom: 1px solid #a1acb7;
					}
					td {
						font-size: 13px;
						padding: 20px 0 20px 10px;
						border-bottom: 1px solid #a1acb7;
					}
					.state {
						display: flex;
						justify-content: center;
						align-items: center;
						width: 45px;
						height: 26px;
						border: 1px solid #31383f;
						border-radius: 100px;
						font-weight: 500;
						font-size: 13px;
						&.cancel {
							opacity: 0.5;
						}
						&.complete {
							opacity: 0.8;
						}
					}
					.state_txt {
						font-weight: 500;
						font-size: 13px;
						color: #596066;
						&.t1 {
							color: #aab4c4;
						}
						&.stay {
							color: #3aad68;
						}
					}
					.type {
						color: #a1acb7;
					}
					.empty p {
						height: 300px;
						display: flex;
						justify-content: center;
						align-items: center;
						font-weight: bold;
						font-size: 14px;
						color: #31383f;
					}
					.price {
						color: #ff5353;
					}
				}
				.paging {
					margin-top: 44px;
				}
				.tab_list {
					margin-top: 10px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					p {
						font-weight: 500;
						font-size: 12px;
						color: rgba(49, 56, 63, 0.75);
						text-align: right;
					}
					.btn_box {
						display: flex;
					}
					a {
						display: flex;
						justify-content: center;
						align-items: center;
						width: 88px;
						height: 36px;
						font-weight: 500;
						font-size: 15px;
						border: 1px solid rgba(161, 172, 183, 0.35);
						border-radius: 1px;
						&.on {
							background: #31383f;
							border: 1px solid #31383f;
							color: #fff;
						}
					}
				}
			}
			.check_wrap {
				position: relative;
				.check_list {
					margin-top: 10px;
					border-top: 1px solid #31383f;
					display: flex;
					flex-wrap: wrap;
					label {
						width: calc(100% / 5);
						display: flex;
						align-items: center;
						padding: 26px 0;
						input {
							outline: none;
							appearance: none;
							display: block;
							width: 20px;
							height: 20px;
							background: #fff;
							border: 1px solid #c2ccd5;
							border-radius: 1px;
							&:checked {
								background: url(../img/common/ico_check_on.svg) no-repeat 0 0 / cover;
								border: 0 solid #000;
							}
						}
						span {
							margin-left: 12px;
							font-weight: 500;
							font-size: 16px;
						}
					}
				}
				& + .check_wrap {
					margin-top: 60px;
				}
				& + .btn_wrap {
					padding-top: 28px;
					border-top: 1px solid #c7cdd2;
				}
			}
		}
	}
	.main {
		.chart_slider {
			background: #2b3035;
			height: 100px;
			ul {
				width: 1240px;
				height: 100px;
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				overflow: hidden;
				li {
					position: absolute;
				}
			}
			.price_box {
				width: 280px;
				height: 100px;
				padding: 18px 0 0 16px;
				.t1 {
					font-size: 12px;
					font-weight: 500;
					color: #be9162;
				}
				.t2 {
					font-size: 16px;
					color: #fff;
					opacity: 0.9;
					margin-top: 5px;
				}
				.graph_box {
					position: absolute;
					right: 18px;
					bottom: 12px;
					width: 122px;
					height: 54px;
				}
			}
		}
		.main_top {
			max-width: 1240px;
			margin: 0 auto;
			position: relative;
			background: #f7f9fb;
			&::before {
				content: '';
				display: block;
				width: 500%;
				height: 560px;
				top: 0;
				left: -500%;
				position: absolute;
				background: #f7f9fb;
			}
			&::after {
				content: '';
				display: block;
				width: 500%;
				height: 560px;
				top: 0;
				right: -500%;
				position: absolute;
				background: #f7f9fb;
			}
		}
		.main_cont {
			height: 560px;
			padding: 64px 0 0 100px;
			background: #f7f9fb;
			// 프론트 opacity 0=> 1
			opacity: 1 !important;

			.t1 {
				font-size: 20px;
				font-weight: 500;
				color: #31383f;
				opacity: 0.6;
			}
			.t2 {
				font-size: 36px;
				font-weight: 700;
				color: #31383f;
				line-height: 52px;
				margin-top: 8px;
			}
			.link_shop {
				background: #2b3035 url(../img/common/ico_arrow_right2.svg) no-repeat right 26px top 50%;
				font-size: 16px;
				font-family: 'Poppins';
				color: #fff;
				width: 184px;
				height: 52px;
				display: flex;
				padding-left: 24px;
				align-items: center;
				margin-top: 28px;
				&:hover {
					background: #212529 url(../img/common/ico_arrow_right2.svg) no-repeat right 26px top 50%;
				}
			}
			.big {
				position: absolute;
				right: 0;
				top: 0;
			}
			.small {
				position: absolute;
				left: 414px;
				bottom: 42px;
			}
			&.swiper-slide-active {
				opacity: 1 !important;
			}
		}
		//프론트 : swiper앞에 main- 추가 <메인 슬라이더>
		.main-swiper-pagination.bar {
			left: 100px;
			bottom: 126px;
			width: 280px;
			height: 1px;
			background: rgba(43, 48, 53, 0.25);
			z-index: 1;
		}
		//프론트 swiper앞에 main- 추가
		.main-swiper-pagination-progressbar .main-swiper-pagination-progressbar-fill {
			height: 3px;
			top: -1px;
			background: #2b3035;
		}
		//프론트 swiper앞에 main- 추가
		.main-swiper-button-prev,
		.main-swiper-button-next {
			outline: none;
			&::after {
				display: none;
			}
		}
		//프론트 swiper앞에 main- 추가
		.main-swiper-button-prev,
		.main-swiper-button-next {
			width: 28px;
			height: 28px;
			border: 0.75px solid #2b3035;
			transform: matrix(-1, 0, 0, 1, 0, 0);
			border-radius: 100%;
			background-position: 50% 50%;
			background-repeat: no-repeat;
			&:hover {
				background-color: #2b3035;
			}
		}
		//프론트 swiper앞에 main- 추가
		.main-swiper-button-prev {
			background-image: url(../img/common/ico_arrow_left.svg);
			transform: rotate(0);
			left: 0;

			&:hover {
				background-image: url(../img/common/ico_arrow_right.svg);
				transform: rotate(180deg);
			}
		}
		//프론트 swiper앞에 main- 추가
		.main-swiper-button-next {
			background-image: url(../img/common/ico_arrow_left.svg);
			right: 0;
			&:hover {
				background-image: url(../img/common/ico_arrow_right.svg);
				transform: rotate(0deg);
			}
		}
		.arrow_box {
			width: 68px;
			position: absolute;
			left: 312px;
			bottom: 74px;
			display: flex;
		}
		.paginations {
			position: absolute;
			left: 100px;
			bottom: 81px;
			z-index: 1;
			display: flex;
			li {
				margin-right: 16px;
				font-size: 12px;
				font-family: 'Popins';
				color: rgba(43, 48, 53, 0.5);
				font-weight: 600;
				&.active {
					color: #2b3035;
				}
			}
		}
		.rank_list {
			width: 1280px;
			padding: 92px 120px;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
			& > div {
				width: 320px;
				border-top: 1px solid #31383f;
				padding-top: 24px;
				.tit {
					display: flex;
					justify-content: space-between;
					align-items: center;
					color: #31383f;
					margin-bottom: 18px;
					strong {
						font-size: 20px;
						font-weight: 900;
					}
					span {
						font-size: 14px;
						font-weight: 500;
					}
				}
				li {
					display: flex;
					align-items: center;
					padding: 10px 0;
					.rank {
						margin-right: 16px;
						font: {
							family: Poppins;
							weight: 500;
							size: 16px;
						}
						color: rgba(49, 56, 63, 0.5);
					}
					p {
						font : {
							family: Poppins;
							weight: 500;
							size: 16px;
						}
						line-height: 20px;
						.fluctuations {
							margin-left: 12px;
							padding-left: 24px;
							&.up {
								color: #2ad66f;
								background: url(../img/common/ico_up.svg) no-repeat 0 0;
							}
							&.down {
								color: #ff5353;
								background: url(../img/common/ico_down.svg) no-repeat 0 0;
							}
						}
					}
					.count {
						margin-left: auto;
						font-weight: 500;
						font-size: 24px;
						color: #be9162;
					}
				}
			}
		}
		.main_info {
			position: relative;
			color: #31383f;
			padding-top: 60px;
			width: 1280px;
			margin: 0 auto;
			&::before {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				display: block;
				width: 100%;
				height: 360px;
				background-color: #f7f9fb;
				z-index: -1;
			}
			&::after {
				content: '';
				position: absolute;
				left: -250%;
				top: 0;
				display: block;
				width: 500%;
				height: 360px;
				// background-color: #f7f9fb;
				background-color: #212529;
				// 프론트 background-color바꿈
				z-index: -1;
			}
			.tit {
				margin-left: 100px;
				font-weight: 500;
				font-size: 32px;
				line-height: 46px;
			}
			.store_list {
				margin-top: 40px;
				display: flex;
				justify-content: flex-end;
				li {
					margin-left: 24px;
					height: 292px;
					background: #2b3035;
					.img_box {
						width: 100%;
						height: 168px;
						overflow: hidden;
						img {
							width: 100%;
							transition: all 1.2s;
						}
					}
					a {
						position: absolute;
						right: 24px;
						top: 24px;
						display: block;
						width: 24px;
						height: 24px;
						border-radius: 100%;
						background: rgba(190, 146, 98, 0.75) url(../img/common/ico_arrow_right.svg) no-repeat
							50% 50%;
						opacity: 0;
						transition: all 1.2s;
					}
					&:hover .img_box img {
						transform: scale(1.1);
					}
					&:hover a {
						opacity: 1;
					}
					.txt_box {
						position: relative;
						padding: 24px;
						color: #fff;
						.t1 {
							font-size: 20px;
							line-height: 24px;
							font-weight: 700;
						}
						.t2 {
							margin-top: 10px;
							font-weight: 500;
							font-size: 15px;
							line-height: 22px;
							opacity: 0.75;
						}
					}
				}
			}
			.info_list {
				margin: 120px auto 140px;
				padding: 0 108px;
				display: flex;
				justify-content: space-between;
				li {
					width: 520px;
					height: 200px;
					position: relative;
					padding: 28px 32px;
					.img_box {
						position: absolute;
						left: 0;
						top: 0;
						overflow: hidden;
						width: 520px;
						height: 200px;
						z-index: -1;
						img {
							transition: all 1.2s;
						}
					}
					&:hover .img_box img {
						transform: scale(1.1);
					}
					&:hover a {
						text-decoration: underline;
					}
					dl {
						color: #31383f;
					}
					dt {
						font-weight: bold;
						font-size: 32px;
						line-height: 46px;
					}
					dd {
						margin-top: 8px;
						font-weight: 500;
						font-size: 16px;
						line-height: 20px;
						opacity: 0.9;
					}
					a {
						display: block;
						margin-top: 26px;
						font-weight: bold;
						font-size: 14px;
						line-height: 16px;
					}
				}
			}
		}
	}
	.main_pop {
		position: absolute;
		//프론트 - 주석처리
		// left: 50%;
		// top: 90px;
		// transform: translateX(-50%);
		// width: 420px;
		background: #fff;
		border: 1px solid rgba(194, 204, 213, 0.2);
		box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
		border-radius: 2px;
		z-index: 10;
		.pop_cont {
			width: 100%;
			height: 464px;
		}
		.option {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 20px;
			border-top: 1px solid #f5f7fa;
			label {
				font-weight: 500;
				font-size: 13px;
				line-height: 16px;
				input {
					margin-right: 8px;
				}
			}
			button {
				width: 72px;
				height: 36px;
				border: 1px solid rgba(49, 56, 63, 0.5);
				font-weight: 500;
				font-size: 13px;
				line-height: 16px;
			}
		}
	}
	.warranty {
		.sub_top {
			height: 260px;
			background: url(../img/common/sub_top_warranty.png) no-repeat 50% 50% / cover;
		}
		.inner {
			width: 1040px;
			margin: 0 auto;
			padding-top: 80px;
		}
		.cont {
			display: flex;
			justify-content: space-between;
			margin-top: 72px;
			&:first-child {
				margin-top: 0;
			}
			h2 {
				width: 200px;
				padding-top: 15px;
				font-weight: bold;
				font-size: 24px;
				line-height: 28px;
				color: #be9162;
				border-top: 2px solid #be9162;
			}
			h2 + div {
				width: 810px;
				padding-top: 16px;
				border-top: 1px solid rgba(49, 56, 63, 0.25);
				color: #31383f;
			}
			.t1 {
				font-weight: 500;
				font-size: 24px;
				line-height: 28px;
			}
			.t2 {
				margin-top: 8px;
				font-weight: 500;
				font-size: 16px;
				line-height: 20px;
			}
			.info_box {
				margin-top: 25px;
			}
			&:last-child {
				margin-bottom: 20px;
			}
		}
	}
	.hpi {
		.sub_top {
			height: 260px;
			background: url(../img/common/sub_top_hpi.png) no-repeat 0 0 / cover;
		}
		.inner {
			width: 920px;
			margin: 0 auto;
			padding: 102px 0 20px;
			text-align: center;
			color: #31383f;
		}
		h2 {
			font-size: 24px;
			line-height: 28px;
			b {
				font-weight: 700;
			}
			strong {
				color: #be9162;
			}
		}
		.t1 {
			font-weight: bold;
			font-size: 16px;
			line-height: 20px;
			margin-top: 24px;
		}
		.t2 {
			margin-top: 12px;
			font-weight: 500;
			font-size: 16px;
			line-height: 26px;
			opacity: 0.75;
		}
		.t3 {
			margin: 80px 0 30px;
			font-weight: 500;
			font-size: 20px;
			line-height: 28px;
		}
		ul {
			margin-top: 100px;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			li {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				width: 448px;
				height: 325px;
				margin-top: 36px;
				padding-top: 12px;
				border-top: 1px solid rgba(49, 56, 63, 0.25);
				&:nth-child(-n + 2) {
					margin-top: 0;
					height: 296px;
				}
				h3 {
					font-weight: bold;
					font-size: 18px;
					line-height: 26px;
					text-align: left;
				}
			}
		}
	}
	.buy {
		.most_viewed {
			width: 1280px;
			position: relative;
			margin: 0 auto;
			padding: 52px 40px 40px;
			// background: #edf0f3; 프론트 : 주석
			&::before {
				content: '';
				display: block;
				width: 500%;
				height: 416px;
				background: #edf0f3;
				position: absolute;
				left: -250%;
				top: 0;
			}
			h2 {
				font-weight: bold;
				font-size: 24px;
				line-height: 28px;
				text-align: center;
				margin-bottom: 20px;
				position: relative;
			}
			// 프론트 swiper 앞에 buy- 추가 <구매-많이 본 상품>
			.buy-swiper-container {
				padding-bottom: 36px;
			}
			// 프론트 swiper 앞에 buy- 추가
			.buy-swiper-pagination {
				bottom: 0;
			}
			// 프론트 swiper 앞에 buy- 추가
			.buy-swiper-pagination-bullet {
				width: 20px;
				height: 2px;
				background: #31383f;
				opacity: 0.25;
				border-radius: 0;
			}
			// 프론트 swiper 앞에 buy- 추가
			.buy-swiper-pagination-bullet-active {
				opacity: 1;
			}
			// 프론트 swiper 앞에 buy- 추가
			.buy-swiper-slide {
				display: flex;
				background: #fff;
				border-radius: 2px;
				overflow: hidden;
				.product_info {
					width: calc(100% - 180px);
					padding: 20px 24px;
					position: relative;
					.comment {
						position: absolute;
						left: 24px;
						bottom: 24px;
						line-height: 20px;
						width: 162px;
						font-weight: 500;
						font-size: 14px;
						// height: 40px;
						display: block;
						text-overflow: ellipsis;
						overflow: hidden;
						white-space: nowrap;
						// display: -webkit-box;
						// word-wrap:break-word;
						// flex-direction: column-reverse;
						// -webkit-line-clamp: 2;
						// -webkit-box-orient: vertical;
					}
					.watch_name {
						font-weight: 500;
						font-size: 16px;
						line-height: 20px;
						opacity: 0.6;
					}
					.price {
						margin-top: 8px;
						font-weight: 500;
						font-size: 20px;
						line-height: 24px;
					}
					.fluctuations {
						margin-top: 20px;
						display: inline-flex;
						padding: 0 7px;
						justify-content: center;
						align-items: center;
						width: auto;
						min-width: 36px;
						height: 24px;
						border-radius: 2px;
						background: #a1acb7;
						font-weight: 500;
						font-size: 12px;
						line-height: 14px;
						color: #fff;
						&.up {
							background: #309d5c;
							span {
								padding-left: 12px;
								background: url(../img/common/ico_polygon_up.svg) no-repeat 0 50%;
							}
						}
						&.down {
							background: #d74c4c;
							span {
								padding-left: 12px;
								background: url(../img/common/ico_polygon_down.svg) no-repeat 0 50%;
							}
						}
					}
					.option {
						margin-top: 10px;
						img {
							margin-right: 8px;
						}
					}
				}
				.sale {
					position: absolute;
					right: 0;
					top: 0;
					display: flex;
					justify-content: center;
					align-items: center;
					width: 94px;
					height: 30px;
					background: #be9162;
					font-weight: 500;
					font-size: 11px;
					line-height: 12px;
					color: #fff;
				}
			}
		}

		.product_list {
			width: 1280px;
			margin: 0 auto;
			padding: 40px;
			position: relative;
			.filter_wrap {
				display: flex;
				justify-content: space-between;
				padding-bottom: 20px;
				border-bottom: 1px solid #edf0f3;
				> button {
					display: flex;
					justify-content: center;
					align-items: center;
					font-weight: 500;
					font-size: 14px;
					width: 103px;
					height: 40px;
					background: #f5f7fa;
					border: 1px solid #edf0f3;
					border-radius: 1px;
				}
				.select_wrap {
					overflow: hidden;
					width: 100%;
					height: 45px;
					display: flex;
					flex-wrap: wrap;
					border-bottom: 1px solid rgba(161, 172, 183, 0.35);
					border-right: 1px solid rgba(161, 172, 183, 0.35);
					&.filter_open {
						height: 89px;
						button.filter {
							color: #fff;
							background: #31383f url(../img/common/ico_filter_on.svg) no-repeat 44px 50%;
						}
					}
					button {
						width: calc(100% / 7);
						height: 44px;
						padding: 0;
						border: 1px solid rgba(161, 172, 183, 0.35);
						border-bottom: 0 solid #000;
						border-right: 0 solid #000;
						border-radius: 1px;
						font-weight: 500;
						font-size: 14px;
						&.on {
							color: #fff;
							border-color: #31383f;
							background: #31383f;
						}
						&.filter {
							padding-left: 25px;
							background: #f5f7fa url(../img/common/ico_filter.svg) no-repeat 44px 50%;
							&.on {
								background: #31383f url(../img/common/ico_filter_on.svg) no-repeat 44px 50%;
							}
						}
					}
				}
			}
			.sort_wrap {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 28px 0 32px;
				font-size: 14px;

				span {
					font-size: 14px;
					display: flex;
					line-height: 40px;
					b {
						font-weight: 700;
						margin-left: 8px;
					}
				}
				select {
					height: 40px;
					appearance: none;
					font-size: 14px;
					padding-right: 40px;
					border: 1px solid rgba(161, 172, 183, 0.35);
					border-radius: 1px;
					background: url(../img/common/ico_select.svg) no-repeat 100% 50%;
					&.filter_select {
						background: url(../img/common/ico_arrow_up.svg) no-repeat right 13px top 50%;
					}
				}
				.sort_right {
					display: flex;
				}
				.off {
					background: gray;
				}
				button {
					display: flex;
					justify-content: center;
					align-items: center;
					color: #fff;
					margin-right: 8px;
					width: 123px;
					height: 40px;
					font-weight: 500;
					background: #212529;
					border-radius: 100px;
					outline: none;
				}
			}
			.filter_box {
				margin-top: 12px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				.filter_btn_list {
					display: flex;
					li {
						display: flex;
						align-items: center;
						padding: 0 12px;
						margin-right: 8px;
						height: 32px;
						background: #484d5a;
						border-radius: 1px;
						span {
							font-weight: 500;
							font-size: 12px;
							color: #fff;
							margin-right: 16px;
						}
						button {
							width: 14px;
							height: 14px;
							img {
								vertical-align: top;
							}
						}
					}
				}
				.reset_btn {
					font-size: 13px;
					line-height: 24px;
				}
			}
			.pro_list_empty {
				padding: 100px 0;
				text-align: center;
				.t1 {
					font-weight: bold;
					font-size: 20px;
					margin-top: 28px;
					line-height: 1.55;
				}
				.t2 {
					font-weight: 500;
					font-size: 13px;
					margin-top: 12px;
					line-height: 1.55;
					b {
						color: #be9162;
					}
				}
				.btn_watch {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 196px;
					height: 52px;
					margin: 40px auto 0;
					background: #2b3035;
					font-weight: 500;
					font-size: 16px;
					color: #fff;
				}
			}
			.pro_list {
				display: flex;
				flex-wrap: wrap;
				border-top: 1px solid #edf0f3;
				> li {
					position: relative;
					width: calc(100% / 4);
					padding: 24px 0 28px;
					border: 1px solid #edf0f3;
					border-top: 0 solid #000;
					border-right: 0 solid #000;
					text-align: center;
					&:nth-child(4n) {
						border-right: 1px solid #edf0f3;
					}
					> img {
						width: 100%;
					}
					.sale {
						position: absolute;
						left: 0;
						top: 0;
						display: flex;
						justify-content: center;
						align-items: center;
						width: 94px;
						height: 30px;
						background: #be9162;
						font-weight: 500;
						font-size: 11px;
						line-height: 12px;
						color: #fff;
					}
					.option {
						position: absolute;
						right: 12px;
						top: 12px;
						img {
							margin-left: 10px;
						}
					}
					.watch_name {
						margin-top: 30px;
						font-weight: 500;
						font-size: 16px;
						line-height: 20px;
						opacity: 0.6;
					}
					.price {
						margin-top: 5px;
						font-weight: 500;
						font-size: 20px;
						line-height: 24px;
					}
					.fluctuations {
						display: flex;
						justify-content: center;
						align-items: center;
						width: 36px;
						height: 24px;
						margin: 6px auto 0;
						border-radius: 2px;
						background: #a1acb7;
						font-weight: 500;
						font-size: 12px;
						line-height: 14px;
						color: #fff;
						&.up {
							background: #309d5c;
							span {
								padding-left: 12px;
								background: url(../img/common/ico_polygon_up.svg) no-repeat 0 50%;
							}
						}
						&.down {
							background: #d74c4c;
							span {
								padding-left: 12px;
								background: url(../img/common/ico_polygon_down.svg) no-repeat 0 50%;
							}
						}
					}
					.comment {
						line-height: 20px;
						width: 126px;
						margin: 15px auto 0;
						opacity: 0.8;
						font-weight: 500;
						font-size: 14px;
						height: 40px;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						word-wrap: break-word;
						flex-direction: column-reverse;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
					}

					ul {
						display: flex;
						justify-content: center;
						margin-top: 16px;
						li {
							position: relative;
							padding: 0 16px;
							line-height: 1.2;
							&::after {
								content: '';
								display: block;
								position: absolute;
								right: 0;
								top: 50%;
								transform: translateY(-50%);
								width: 1px;
								height: 27px;
								background-color: rgba(161, 172, 183, 0.5);
							}
							&:last-child::after {
								display: none;
							}
							span {
								display: block;
								font-weight: 500;
								font-size: 11px;
								opacity: 0.5;
							}
							b {
								font-size: 12px;
								font-weight: 500;
							}
						}
					}
				}
			}
			.paging {
				margin-top: 40px;
			}
		}
	}
	.buy_detail {
		width: 1280px;
		margin: 0 auto;
		padding: 60px 40px 140px;
		.top_cont {
			display: flex;
			justify-content: space-between;
			.watch_list {
				width: 596px;
				height: 606px;
				display: flex;
				justify-content: center;
				align-items: center;
				border: 1px solid rgba(161, 172, 183, 0.35);
				li {
					display: none;
					&.on {
						display: block;
					}
				}
			}
			.watch_info {
				position: relative;
				width: 564px;
				border-top: 1px solid #31383f;
				border-bottom: 1px solid rgba(161, 172, 183, 0.35);
				padding-top: 16px;
				.bland {
					font-family: 'Poppins';
					font-weight: 500;
					font-size: 16px;
					opacity: 0.6;
				}
				.option {
					position: absolute;
					right: 0;
					top: 12px;
					img {
						margin-left: 10px;
					}
				}
				.watch_name {
					font-weight: bold;
					font-size: 32px;
				}
				.t1 {
					margin: 10px 0 16px;
					font-weight: 500;
					font-size: 16px;
					padding-bottom: 16px;
					border-bottom: 1px solid rgba(161, 172, 183, 0.35);
					line-height: 1.55;
				}
				.t2 {
					font-weight: 500;
					font-size: 16px;
					line-height: 20px;
					opacity: 0.75;
					span {
						font-weight: 700;
						color: #be9162;
					}
				}
				.t3 {
					margin-top: 20px;
					display: flex;
					align-items: center;
					.price {
						font-weight: bold;
						font-size: 44px;
					}
					.fluctuations {
						transform: translateY(1px);
						margin-left: 16px;
						display: flex;
						justify-content: center;
						align-items: center;
						width: 43px;
						height: 24px;
						border-radius: 2px;
						background: #a1acb7;
						font-weight: 500;
						font-size: 12px;
						line-height: 14px;
						color: #fff;
						&.up {
							background: #309d5c;
							span {
								padding-left: 12px;
								background: url(../img/common/ico_polygon_up.svg) no-repeat 0 50%;
							}
						}
						&.down {
							background: #d74c4c;
							span {
								padding-left: 12px;
								background: url(../img/common/ico_polygon_down.svg) no-repeat 0 50%;
							}
						}
					}
				}
				.sale {
					margin-top: 30px;
					display: flex;
					justify-content: center;
					align-items: center;
					width: 120px;
					height: 36px;
					background: #be9162;
					font-weight: 500;
					font-size: 13px;
					color: #fff;
				}
				.t4 {
					margin-top: 30px;
					font-weight: bold;
					font-size: 16px;
				}
				.store_list {
					display: flex;
					margin-top: 10px;
					li {
						margin-right: 32px;
						label {
							display: flex;
							align-items: center;
						}
						span {
							margin-left: 10px;
							font-weight: 500;
							font-size: 18px;
						}
					}
				}
				.buy_reservation {
					margin-top: 40px;
					width: 100%;
					height: 64px;
					background: #31383f;
					border-radius: 1px;
					font-weight: 500;
					font-size: 18px;
					color: #fff;
				}
				.buy_reservation_link{
					margin-top: 20px;
					width: 100%;
					height: 64px;
					background: #31383f;
					border-radius: 1px;
					font-weight: 500;
					font-size: 18px;
					color: #fff;
					&:not([disabled]) {
						&:hover {
							text-decoration: none;
							color: #be9162;
							outline: none;
						}
					}
					&:disabled {
						background: lightgray !important;
						cursor: default !important;
					}
				}
				.t5 {
					margin-top: 16px;
					font-weight: 500;
					font-size: 16px;
					display: flex;
					a {
						margin-left: auto;
						font-weight: bold;
						color: #be9162;
					}
				}
			}
		}
		.tab_list {
			margin-top: 16px;
			width: 596px;
			justify-content: space-between;
			display: flex;
			li {
				width: 140px;
				height: 140px;
				display: flex;
				justify-content: center;
				align-items: center;
				border: 1px solid rgba(161, 172, 183, 0.35);
				background-color: #fff;
				&.on {
					border: 1px solid #31383f;
				}
			}
		}
		.graph_box {
			margin-top: 36px;
			position: relative;
			width: 100%;
			height: 160px;
			background: #f5f7fa;
			border: 1px solid #edf0f3;
			border-radius: 1px;
			p {
				position: absolute;
				left: 28px;
				top: 24px;
				font-weight: bold;
				font-size: 16px;
				z-index: 1;
			}
			.graph {
				position: absolute;
				left: 36px;
				top: 37px;
				width: 1128px;
				height: 81px;
				padding-top: 12px;
			}
		}
		h3 {
			margin: 80px 0 20px;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 28px;
		}
		.tbl {
			width: 100%;
			border-top: 1px solid #31383f;
			border-radius: 1px;
			th,
			td {
				border-bottom: 1px solid rgba(161, 172, 183, 0.35);
				padding: 22px 20px;
				text-align: left;
				font-size: 16px;
			}
			th {
				font-weight: bold;
				background: #f5f7fa;
			}
		}
	}
	.popup.reservation {
		.cont {
			margin-top: 14px;
			border-top: 1px solid #cbcdcf;
			padding-top: 20px;
			h3 {
				font-weight: 500;
				font-size: 12px;
				span {
					color: #ff5353;
					margin-left: 2px;
				}
			}
			input[type='text'] {
				width: 100%;
				margin-top: 6px;
				font-weight: 500;
				color: rgba(49, 56, 63, 0.5);
			}
			input[type='text'] + h3 {
				margin-top: 16px;
			}
			textarea {
				margin-top: 6px;
				width: 100%;
				height: 120px;
				padding: 17px 16px;
				font-weight: 500;
				font-size: 14px;
				border: 1px solid #c2ccd5;
				color: rgba(49, 56, 63, 0.5);
			}
			.check_list {
				margin: 13px 0 28px;
				display: flex;
				font-weight: 500;
				font-size: 16px;
				li {
					margin-right: 24px;
					label {
						display: flex;
						align-items: center;
						span {
							margin-left: 8px;
						}
					}
				}
			}
			.calendar_wrap {
				margin-top: 6px;
				display: flex;
				justify-content: space-between;
				& > div {
					width: 216px;
					height: 48px;
					background: #ffffff;
					border: 1px solid #c2ccd5;
					border-radius: 1px;
					position: relative;
					input {
						display: flex;
						width: 173px;
						height: 46px;
						align-items: center;
						padding: 0 16px;
						border: 0 solid #000;
						font-weight: 500;
						font-size: 14px;
						opacity: 0.5;
						margin-top: 0;
					}
					// 프론트-datepicker랑 겹쳐서 버튼 말고 클래스네임 부여
					// button {
					.calendar_img {
						position: absolute;
						right: 12px;
						top: 12px;
						width: 18px;
						height: 20px;
						img {
							vertical-align: top;
						}
					}
				}
				select {
					width: 156px;
					padding-left: 16px;
					appearance: none;
					padding-right: 36px;
					font-weight: 500;
					font-size: 14px;
					color: rgba(49, 56, 63, 0.5);
					background: url(../img/common/ico_drop_down.svg) no-repeat right 12px top 50%;
				}
			}
			.alert_txt {
				margin-top: 8px;
				font-weight: 500;
				font-size: 12px;
				color: #ff5353;
			}
			.agree_box {
				position: relative;
				margin-top: 16px;
				padding-left: 28px;
				input {
					position: absolute;
					left: 0;
					top: 3px;
				}
				span {
					font-weight: 500;
					font-size: 12px;
					a {
						text-decoration: underline;
					}
				}
				p {
					margin-top: 4px;
					font-weight: 500;
					font-size: 12px;
					line-height: 1.55;
					color: rgba(49, 56, 63, 0.75);
				}
			}
		}
		.btn_wrap {
			margin-top: 40px;
		}
	}
	.sale {
		overflow: hidden;
		.sub_top {
			height: 360px;
			// 프론트 수정 - 판매 사진 변경
			// background: url(../img/common/sub_top_sale.png) no-repeat 0 0 / cover;
			background: linear-gradient(
					to right,
					rgba(0, 0, 0, 0.9) 10%,
					rgba(0, 0, 0, 0.75) 30%,
					rgba(20, 20, 20, 0.5) 50%,
					rgba(0, 0, 0, 0.75) 65%,
					rgba(0, 0, 0, 0.9) 100%
				),
				url(../front-img/sell_new_banner.png);
			background-size: cover;
			background-position: 100% 80%;

			h1 {
				font-size: 32px;
			}
			h1 + p {
				font-size: 20px;
				margin-top: 4px;
			}
			.search_box {
				margin-top: 32px;
				width: 420px;
				height: 52px;
				padding-right: 12px;
				background-color: #fff;
				display: flex;
				align-items: center;
				input {
					border: 0 solid #000;
					display: flex;
					justify-content: center;
					align-items: center;
					width: calc(100% - 48px);
					height: 52px;
					padding-left: 20px;
					font-size: 15px;
					color: rgba(49, 56, 63, 0.5);
				}
				button {
					width: 36px;
					height: 36px;
					margin-left: auto;
					background: url(../img/common/ico_search.svg) no-repeat 50% 50%;
				}
			}
		}
		.last_selling {
			width: 1280px;
			margin: 0 auto;
			padding: 70px 40px 0;
			h2 {
				font-weight: bold;
				font-size: 24px;
				line-height: 28px;
				text-align: center;
				margin-bottom: 30px;
			}
			ul {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
			}
			li {
				width: 390px;
				margin-bottom: 20px;
				display: flex;
				background: #fff;
				border-radius: 2px;
				overflow: hidden;
				position: relative;
				border: 1px solid #edf0f3;
				.product_info {
					width: calc(100% - 180px);
					padding: 20px 24px;
					position: relative;
					.comment {
						position: absolute;
						left: 24px;
						bottom: 24px;
						line-height: 20px;
						width: 162px;
						font-weight: 500;
						font-size: 14px;
						display: block;
						text-overflow: ellipsis;
						overflow: hidden;
						white-space: nowrap;
						// height: 40px;
						// overflow: hidden;
						// text-overflow: ellipsis;
						// display: -webkit-box;
						// word-wrap:break-word;
						// flex-direction: column-reverse;
						// -webkit-line-clamp: 2;
						// -webkit-box-orient: vertical;
					}
					.watch_name {
						font-weight: 500;
						font-size: 16px;
						line-height: 20px;
						opacity: 0.6;
					}
					.price {
						margin-top: 8px;
						font-weight: 500;
						font-size: 20px;
						line-height: 24px;
					}
					.fluctuations {
						margin-top: 20px;
						display: inline-flex;
						padding: 0 7px;
						justify-content: center;
						align-items: center;
						width: auto;
						min-width: 36px;
						height: 24px;
						border-radius: 2px;
						background: #a1acb7;
						font-weight: 500;
						font-size: 12px;
						line-height: 14px;
						color: #fff;
						&.up {
							background: #309d5c;
							span {
								padding-left: 12px;
								background: url(../img/common/ico_polygon_up.svg) no-repeat 0 50%;
							}
						}
						&.down {
							background: #d74c4c;
							span {
								padding-left: 12px;
								background: url(../img/common/ico_polygon_down.svg) no-repeat 0 50%;
							}
						}
					}
					.option {
						margin-top: 10px;
						img {
							margin-right: 8px;
						}
					}
				}
				.sale {
					position: absolute;
					right: 0;
					top: 0;
					display: flex;
					justify-content: center;
					align-items: center;
					width: 94px;
					height: 30px;
					background: #be9162;
					font-weight: 500;
					font-size: 11px;
					line-height: 12px;
					color: #fff;
				}
			}
		}
		.model_info {
			width: 1280px;
			margin: 0 auto;
			display: flex;
			.info_box {
				position: relative;
				width: 670px;
				padding: 78px 50px 0 110px;
				background: rgba(250, 251, 252, 0.75);
				::after {
					position: absolute;
					left: 0;
					bottom: -200px;
					content: '';
					display: block;
					width: 100%;
					height: 200px;
					background: rgba(250, 251, 252, 0.75);
				}
				h3 {
					position: relative;
					font-weight: bold;
					font-size: 20px;
					padding-left: 18px;
					margin-bottom: 12px;
					line-height: 24px;
					&::after {
						content: '';
						display: block;
						width: 6px;
						height: 6px;
						border-radius: 100%;
						background: #31383f;
						position: absolute;
						left: 0;
						top: 7px;
					}
				}
				.model_box {
					display: flex;
					padding-bottom: 40px;
					margin-bottom: 38px;
					border-bottom: 1px solid #edf0f3;
					.img_box {
						width: 264px;
					}
					.info_list {
						padding: 30px 0 0 32px;
						dt {
							font-weight: 500;
							font-size: 14px;
							color: #31383f;
							opacity: 0.75;
						}
						dd {
							font-weight: bold;
							margin-top: 10px;
							font-size: 20px;
						}
						dd + dt {
							margin-top: 28px;
						}
					}
				}
			}
			.step_box {
				width: calc(100% - 670px);
				padding: 52px 110px 0 60px;
				> ol {
					display: flex;
					justify-content: space-between;
					padding-bottom: 20px;
					border-bottom: 1px solid #edf0f3;
					li {
						position: relative;
						display: flex;
						justify-content: center;
						align-items: center;
						width: 97px;
						height: 34px;
						font-weight: 500;
						font-size: 12px;
						&.on {
							color: #fff;
							background: #343a40;
							border-radius: 1px;
						}
						&.complete {
							color: #fff;
							background: rgba(52, 58, 64, 0.25);
							&:after {
								position: absolute;
								right: -17px;
								content: '';
								display: block;
								width: 17px;
								height: 1px;
								background-color: #000;
								opacity: 0.5;
							}
						}
					}
				}
				h2 {
					margin: 30px 0 24px;
					font-weight: bold;
					font-size: 20px;
				}
				h4 {
					font-weight: 500;
					font-size: 13px;
					span {
						color: #ff5353;
						margin-left: 1px;
					}
				}
				input + h4,
				select + h4,
				.attach_file + h4 {
					margin-top: 20px;
				}
				input:not([type='checkbox']) {
					display: flex;
					align-items: center;
					margin-top: 8px;
					font-weight: 500;
					padding: 0 16px;
					width: 100%;
					height: 48px;
					border: 1px solid #c2ccd5;
					box-sizing: border-box;
					font-size: 14px;
					&:disabled {
						background: #f8f9fa;
						color: rgba(49, 56, 63, 0.5);
					}
					&::placeholder {
						color: rgba(49, 56, 63, 0.5);
					}
				}
				select {
					display: flex;
					align-items: center;
					margin-top: 8px;
					font-weight: 500;
					padding: 0 16px;
					width: 100%;
					height: 48px;
					border: 1px solid #c2ccd5;
					box-sizing: border-box;
					font-size: 14px;
					color: rgba(49, 56, 63, 0.5);
					background: url(../img/common/ico_select.svg) no-repeat right 12px top 50%;
				}
				textarea {
					display: flex;
					align-items: center;
					margin-top: 8px;
					font-weight: 500;
					padding: 16px;
					width: 100%;
					height: 120px;
					border: 1px solid #c2ccd5;
					box-sizing: border-box;
					font-size: 14px;
					&:disabled {
						background: #f8f9fa;
						color: rgba(49, 56, 63, 0.5);
					}
					&::placeholder {
						color: rgba(49, 56, 63, 0.5);
					}
				}
				.check_sale {
					position: relative;
					display: flex;
					align-items: center;
					margin-bottom: 30px;
					h4 {
						width: 122px;
					}
					ul {
						display: flex;
					}
					li {
						display: flex;
						align-items: center;
						&:first-child {
							margin-right: 60px;
						}
						label {
							font-weight: 500;
							font-size: 16px;
							display: flex;
							align-items: center;
							input {
								margin-right: 8px;
							}
						}
					}
					.btn_tooltip {
						margin-left: 8px;
						width: 24px;
						height: 24px;
						background: url(../img/common/ico_tooltip.svg) no-repeat 0 0 / cover;
					}
					.tooltip {
						position: absolute;
						display: none;
						top: 30px;
						right: 0;
						width: 358px;
						height: 134px;
						padding: 18px 16px;
						background: #ffffff;
						border: 1px solid #edf0f3;
						box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
						border-radius: 2px;
						z-index: 1;
						&.on {
							display: block;
						}
						.t1 {
							font-weight: bold;
							font-size: 12px;
						}
						.t2 {
							margin-top: 8px;
							font-weight: 500;
							font-size: 12px;
							line-height: 16px;
						}
						a {
							position: absolute;
							left: 16px;
							bottom: 14px;
							font-weight: bold;
							font-size: 12px;
						}
					}
				}
				.check_store {
					position: relative;
					display: flex;
					align-items: center;
					margin-bottom: 30px;
					h4 {
						width: 95px;
					}
					ul {
						display: flex;
					}
					li {
						display: flex;
						align-items: center;
						margin-right: 24px;
						&:last-child {
							margin-right: 0;
						}
						label {
							font-weight: 500;
							font-size: 16px;
							display: flex;
							align-items: center;
							input {
								margin-right: 8px;
							}
						}
					}
				}
				.visit_schedule {
					position: relative;
					display: flex;
					margin-bottom: 30px;
					h4 {
						width: 95px;
						padding-top: 20px;
					}
					> div {
						width: calc(100% - 95px);
					}
					.calendar_wrap {
						margin-top: 6px;
						display: flex;
						justify-content: space-between;
						& > div {
							width: 206px;
							height: 48px;
							background: #ffffff;
							border: 1px solid #c2ccd5;
							border-radius: 1px;
							position: relative;
							input {
								display: flex;
								margin-top: 0;
								width: 173px;
								height: 46px;
								align-items: center;
								padding: 0 16px;
								border: 0 solid #000;
								font-weight: 500;
								font-size: 14px;
								opacity: 0.5;
							}
							button {
								position: absolute;
								right: 12px;
								top: 12px;
								width: 18px;
								height: 20px;
								img {
									vertical-align: top;
								}
							}
						}
						select {
							margin-top: 0;
							width: 124px;
							padding-left: 16px;
							appearance: none;
							padding-right: 36px;
							font-weight: 500;
							font-size: 14px;
							color: rgba(49, 56, 63, 0.5);
							background: url(../img/common/ico_drop_down.svg) no-repeat right 12px top 50%;
						}
					}
					.agree_box {
						position: relative;
						margin-top: 20px;
						padding-left: 28px;
						input {
							position: absolute;
							left: 0;
							top: 2px;
						}
						span {
							font-weight: 500;
							font-size: 12px;
							a {
								text-decoration: underline;
							}
						}
						p {
							margin-top: 7px;
							margin-bottom: 10px;
							font-weight: 500;
							font-size: 12px;
							line-height: 14px;
							color: rgba(49, 56, 63, 0.75);
						}
					}
				}
				.attach_file {
					position: relative;
					margin-bottom: 30px;
					input[type='file'] {
						position: absolute;
						width: 1px;
						height: 1px;
						padding: 0;
						margin: -1px;
						overflow: hidden;
						clip: rect(0, 0, 0, 0);
						border: 0;
					}
					label {
						position: absolute;
						right: 0;
						top: -28px;
						font-weight: 500;
						font-size: 14px;
						text-decoration: underline;
						color: #31383f;
						cursor: pointer;
					}
					span {
						display: flex;
						align-items: center;
						margin-top: 8px;
						font-weight: 500;
						padding: 0 16px;
						width: 100%;
						height: 48px;
						border: 1px solid #c2ccd5;
						box-sizing: border-box;
						font-size: 14px;
						color: rgba(49, 56, 63, 0.5);
					}
					.attach_file_list {
						width: 100%;
						overflow-x: auto;
						margin: 12px 0 30px;
						ul {
							display: flex;
							width: max-content;
						}
						li {
							display: flex;
							justify-content: center;
							align-items: center;
							margin-right: 8px;
							width: 84px;
							height: 84px;
							position: relative;
							border: 1px solid #a1acb7;
							&:last-child {
								margin-right: 0;
							}
							button {
								position: absolute;
								right: 0;
								top: 0;
								width: 16px;
								height: 16px;
								background: #212529 url(../img/common/ico_x.svg) no-repeat 50% 50% / 6px;
							}
						}
					}
				}
				.alert_txt {
					margin-top: 8px;
					font-weight: 500;
					font-size: 12px;
					color: #ff5353;
				}
				.btn_wrap {
					display: flex;
					justify-content: space-between;
					margin-top: 18px;
					padding-top: 16px;
					border-top: 1px solid #edf0f3;
					button {
						height: 48px;
						padding: 0 40px;
						border: 1px solid #31383f;
						border-radius: 1px;
						font-weight: 500;
						font-size: 15px;
					}
					button.ok {
						height: 48px;
						// padding: 0 40px;
						// 프론트 버튼 다 깨져서 수정
						background: #31383f;
						border-radius: 1px;
						font-weight: 500;
						font-size: 15px;
						color: #fff;
					}
					.btn_reset {
						font-weight: 500;
						font-size: 15px;
						padding-left: 36px;
						border: 0 solid #000;
						background: url(../img/common/ico_search.svg) no-repeat 8px 55%;
					}
				}
			}
		}
		> .complete {
			padding: 120px 0 140px;
			text-align: center;
			.t1 {
				margin-top: 20px;
				font-weight: bold;
				font-size: 20px;
			}
			.cont {
				width: 440px;
				margin: 24px auto 0;
				padding: 34px 50px 27px;
				background: #f7fbff;
				border: 0.75px solid rgba(92, 92, 92, 0.0075);
				border-radius: 2px;
				.t2 {
					text-align: center;
					font-weight: bold;
					font-size: 16px;
					margin-bottom: 29px;
				}
				dl {
					display: flex;
					text-align: left;
					margin-bottom: 22px;
					dt {
						width: 95px;
						font-weight: 500;
						font-size: 14px;
						opacity: 0.75;
					}
					dd {
						font-weight: bold;
						font-size: 15px;
					}
				}
			}
			.btn_home {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 134px;
				height: 52px;
				margin: 40px auto 0;
				background: #31383f;
				border-radius: 1px;
				font-weight: 500;
				font-size: 16px;
				color: #fff;
			}
		}
	}
	.popup.model_search {
		width: 640px;
		text-align: center;
		padding: 40px 30px 30px;
		h2 {
			font-size: 24px;
		}
		.search_box {
			margin-top: 32px;
			width: 420px;
			margin: 12px auto 0;
			height: 52px;
			padding-right: 12px;
			background-color: #fff;
			border: 1px solid #c2ccd5;
			border-radius: 1px;
			display: flex;
			align-items: center;
			input[type='text'] {
				margin-left: 1px;
				border: 0 solid #000;
				display: flex;
				justify-content: center;
				align-items: center;
				width: calc(100% - 48px);
				height: 49px;
				padding-left: 20px;
				font-size: 15px;
				color: #1c1e20;
				&::placeholder {
					color: rgba(49, 56, 63, 0.5);
				}
			}
			button {
				width: 36px;
				height: 36px;
				margin-left: auto;
				background: url(../img/common/ico_search.svg) no-repeat 50% 50%;
			}
		}
		.t1 {
			margin-top: 12px;
			font-weight: 500;
			font-size: 14px;
			color: #596066;
			b {
				font-weight: 700;
				color: #be9162;
			}
		}
		.t2 {
			margin-top: 8px;
			font-weight: 500;
			font-size: 16px;
			color: #1c1e20;
		}
		.cont {
			margin-top: 28px;
			height: 363px;
			overflow-y: auto;
			li {
				display: flex;
				background: #ffffff;
				border: 1px solid #c2ccd5;
				border-radius: 1px;
				margin-bottom: 10px;
				cursor: pointer;
				&.on {
					border: 1px solid #343a40;
					.img_box {
						border-right: 1px solid #343a40;
					}
				}
				.img_box {
					width: 120px;
					border-right: 1px solid #c2ccd5;
					display: flex;
					justify-content: center;
					align-items: center;
					overflow: hidden;
					padding: 1px;
					img {
						width: 100%;
					}
				}
				.txt_box {
					width: calc(100% - 120px);
					padding: 34px 30px;
					text-align: left;
					.brand {
						font-weight: 500;
						font-size: 16px;
						color: rgba(49, 56, 63, 0.75);
					}
					.modle_name {
						margin-top: 6px;
						font-weight: 500;
						font-size: 16px;
					}
				}
			}
		}
	}
	.auction {
		.most_viewed {
			width: 1280px;
			margin: 0 auto;
			padding: 52px 40px 56px;
			position: relative;
			// background: #edf0f3; 프론트 : 주석
			&::before {
				content: '';
				display: block;
				// 프론트추가 width: 200% => 500%
				width: 500%;
				// 프론트 height 440 => 100%, min-height추가
				// height: 440px;
				height: 100%;
				min-height: 440px;
				background: #edf0f3;
				position: absolute;
				top: 0;
				left: -100%;
			}
			//프론트 주석
			// &::after {
			// 	content: '';
			// 	width: 200%;
			// 	height: 440px;
			// 	display: block;
			// 	background: #edf0f3;
			// 	position: absolute;
			// 	top: 0;
			// 	right: -100%;
			// }
			h2 {
				font-weight: bold;
				font-size: 24px;
				line-height: 28px;
				text-align: center;
				margin-bottom: 20px;
				position: relative;
				z-index: 1;
			}
			.auction-swiper-container {
				padding-bottom: 36px;
			}
			.auction-swiper-pagination {
				bottom: 0;
			}
			.auction-swiper-pagination-bullet {
				width: 20px;
				height: 2px;
				background: #31383f;
				opacity: 0.25;
				border-radius: 0;
			}

			.auction-swiper-pagination-bullet-active {
				opacity: 1;
			}
			.auction-swiper-slide {
				display: flex;
				background: #fff;
				border-radius: 2px;
				overflow: hidden;
				height: 270px;
				.product_info {
					width: calc(100% - 180px);
					padding: 20px 24px;
					position: relative;
					.comment {
						position: absolute;
						left: 24px;
						bottom: 24px;
						line-height: 20px;
						width: 162px;
						font-weight: 500;
						font-size: 14px;
						// height: 40px;
						// overflow: hidden;
						// text-overflow: ellipsis;
						// display: -webkit-box;
						// word-wrap:break-word;
						// flex-direction: column-reverse;
						// -webkit-line-clamp: 2;
						// -webkit-box-orient: vertical;
					}
					.watch_name {
						font-weight: 500;
						font-size: 16px;
						line-height: 20px;
						opacity: 0.6;
					}
					.price {
						margin-top: 8px;
						font-weight: 500;
						font-size: 20px;
						line-height: 24px;
					}
					.end {
						margin-top: 10px;
						font-weight: bold;
						font-size: 18px;
						line-height: 24px;
						color: #ff5353;
						text-decoration: underline;
					}
					.join_num {
						margin-top: 8px;
						font-weight: 500;
						font-size: 15px;
					}
					.fluctuations {
						margin-top: 20px;
						display: inline-flex;
						padding: 0 7px;
						justify-content: center;
						align-items: center;
						width: auto;
						min-width: 36px;
						height: 24px;
						border-radius: 2px;
						background: #a1acb7;
						font-weight: 500;
						font-size: 12px;
						line-height: 14px;
						color: #fff;
						&.up {
							background: #309d5c;
							span {
								padding-left: 12px;
								background: url(../img/common/ico_polygon_up.svg) no-repeat 0 50%;
							}
						}
						&.down {
							background: #d74c4c;
							span {
								padding-left: 12px;
								background: url(../img/common/ico_polygon_down.svg) no-repeat 0 50%;
							}
						}
					}
					.option {
						margin-top: 10px;
						img {
							margin-right: 8px;
						}
					}
					ul {
						display: flex;
						justify-content: center;
						margin-top: 16px;
						li {
							position: relative;
							line-height: 1.2;
							&:nth-child(2) {
								padding: 0 10px;
								margin: 0 10px;
							}
							&:nth-child(2)::before {
								content: '';
								display: block;
								position: absolute;
								left: 0;
								top: 50%;
								transform: translateY(-50%);
								width: 1px;
								height: 27px;
								background-color: rgba(161, 172, 183, 0.5);
							}
							&:nth-child(2)::after {
								content: '';
								display: block;
								position: absolute;
								right: 0;
								top: 50%;
								transform: translateY(-50%);
								width: 1px;
								height: 27px;
								background-color: rgba(161, 172, 183, 0.5);
							}

							span {
								display: block;
								font-weight: 500;
								font-size: 11px;
								opacity: 0.5;
							}
							b {
								font-size: 12px;
								font-weight: 500;
							}
						}
					}
				}
				.sale {
					position: absolute;
					right: 0;
					top: 0;
					display: flex;
					justify-content: center;
					align-items: center;
					width: 94px;
					height: 30px;
					background: #be9162;
					font-weight: 500;
					font-size: 11px;
					line-height: 12px;
					color: #fff;
				}
			}
		}

		.product_list {
			width: 1280px;
			margin: 0 auto;
			padding: 40px;
			position: relative;
			.filter_wrap {
				display: flex;
				justify-content: space-between;
				padding-bottom: 20px;
				border-bottom: 1px solid #edf0f3;
				> button {
					display: flex;
					justify-content: center;
					align-items: center;
					font-weight: 500;
					font-size: 14px;
					width: 103px;
					height: 40px;
					background: #f5f7fa;
					border: 1px solid #edf0f3;
					border-radius: 1px;
				}
				.select_wrap {
					overflow: hidden;
					width: 100%;
					height: 45px;
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					-ms-flex-wrap: wrap;
					flex-wrap: wrap;
					border-bottom: 1px solid rgba(161, 172, 183, 0.35);
					border-right: 1px solid rgba(161, 172, 183, 0.35);
					&.filter_open {
						height: 90px;
						button.filter {
							color: #fff;
							background: #31383f url(../img/common/ico_filter_on.svg) no-repeat 44px 50%;
						}
					}
					button {
						width: calc(100% / 7);
						height: 44px;
						padding: 0;
						border: 1px solid rgba(161, 172, 183, 0.35);
						border-bottom: 0 solid #000;
						border-right: 0 solid #000;
						border-radius: 1px;
						font-weight: 500;
						font-size: 14px;

						&.on {
							color: #fff;
							border-color: #31383f;
							background: #31383f url(../img/common/ico_arrow_down_on.svg) no-repeat right 14px top
								50%;
						}
						&.filter {
							padding-left: 25px;
							background: #f5f7fa url(../img/common/ico_filter.svg) no-repeat 44px 50%;
							&.on {
								background: #31383f url(../img/common/ico_filter_on.svg) no-repeat 44px 50%;
							}
						}
					}
				}
			}
			.sort_wrap {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 28px 0 32px;
				span {
					font-size: 14px;
					display: flex;
					line-height: 40px;
					b {
						font-weight: 700;
						margin-left: 8px;
					}
				}
				select {
					height: 40px;
					appearance: none;
					font-size: 14px;
					padding-right: 40px;
					border: 1px solid rgba(161, 172, 183, 0.35);
					border-radius: 1px;
					background: url(../img/common/ico_select.svg) no-repeat 100% 50%;
					& .filter_select {
						background: url(../img/common/ico_arrow_up.svg) no-repeat 100% 50%;
					}
				}
			}
			.filter_box {
				margin-top: 12px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				.filter_btn_list {
					display: flex;
					li {
						display: flex;
						align-items: center;
						padding: 0 12px;
						margin-right: 8px;
						height: 32px;
						background: #484d5a;
						border-radius: 1px;
						span {
							font-weight: 500;
							font-size: 12px;
							color: #fff;
							margin-right: 16px;
						}
						button {
							width: 14px;
							height: 14px;
							img {
								vertical-align: top;
							}
						}
					}
				}
				.reset_btn {
					font-size: 13px;
					line-height: 24px;
				}
			}
			.pro_list_empty {
				padding: 100px 0;
				text-align: center;
				.t1 {
					font-weight: bold;
					font-size: 20px;
					margin-top: 28px;
				}
				.t2 {
					font-weight: 500;
					font-size: 13px;
					margin-top: 12px;
					line-height: 14px;
					b {
						color: #be9162;
					}
				}
				.btn_watch {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 196px;
					height: 52px;
					margin: 40px auto 0;
					background: #2b3035;
					font-weight: 500;
					font-size: 16px;
					color: #fff;
				}
			}
			.pro_list {
				display: flex;
				flex-wrap: wrap;
				border-top: 1px solid #edf0f3;
				> li {
					position: relative;
					width: calc(100% / 4);
					padding: 24px 0 28px;
					border: 1px solid #edf0f3;
					border-top: 0 solid #000;
					border-right: 0 solid #000;
					text-align: center;
					&:nth-child(4n) {
						border-right: 1px solid #edf0f3;
					}
					> img {
						width: 100%;
					}
					.sale {
						position: absolute;
						left: 0;
						top: 0;
						display: flex;
						justify-content: center;
						align-items: center;
						width: 94px;
						height: 30px;
						background: #be9162;
						font-weight: 500;
						font-size: 11px;
						line-height: 12px;
						color: #fff;
					}
					.option {
						position: absolute;
						right: 12px;
						top: 12px;
						img {
							margin-left: 10px;
						}
					}
					.watch_name {
						margin-top: 30px;
						font-weight: 500;
						font-size: 16px;
						line-height: 20px;
						opacity: 0.6;
					}
					.price {
						margin-top: 5px;
						font-weight: 500;
						font-size: 20px;
						line-height: 24px;
					}
					.end {
						margin-top: 6px;
						font-weight: 500;
						font-size: 20px;
						line-height: 24px;
						color: #ff5353;
					}
					.join_num {
						margin: 10px 0 22px;
						font-weight: 500;
						font-size: 15px;
					}
					.fluctuations {
						display: flex;
						justify-content: center;
						align-items: center;
						width: 36px;
						height: 24px;
						margin: 6px auto 0;
						border-radius: 2px;
						background: #a1acb7;
						font-weight: 500;
						font-size: 12px;
						line-height: 14px;
						color: #fff;
						&.up {
							background: #309d5c;
							span {
								padding-left: 12px;
								background: url(../img/common/ico_polygon_up.svg) no-repeat 0 50%;
							}
						}
						&.down {
							background: #d74c4c;
							span {
								padding-left: 12px;
								background: url(../img/common/ico_polygon_down.svg) no-repeat 0 50%;
							}
						}
					}
					.comment {
						line-height: 20px;
						width: 126px;
						margin: 15px auto 0;
						opacity: 0.8;
						font-weight: 500;
						font-size: 14px;
						height: 40px;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						word-wrap: break-word;
						flex-direction: column-reverse;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
					}

					ul {
						display: flex;
						justify-content: center;
						margin-top: 16px;
						li {
							position: relative;
							padding: 0 16px;
							line-height: 1.2;
							&::after {
								content: '';
								display: block;
								position: absolute;
								right: 0;
								top: 50%;
								transform: translateY(-50%);
								width: 1px;
								height: 27px;
								background-color: rgba(161, 172, 183, 0.5);
							}
							&:last-child::after {
								display: none;
							}
							span {
								display: block;
								font-weight: 500;
								font-size: 11px;
								opacity: 0.5;
							}
							b {
								font-size: 12px;
								font-weight: 500;
							}
						}
					}
				}
			}
			.paging {
				margin-top: 40px;
			}
		}
	}
	.auction_detail {
		width: 1280px;
		margin: 0 auto;
		padding: 60px 40px 140px;
		.top_cont {
			display: flex;
			justify-content: space-between;
			.watch_list {
				width: 596px;
				height: 606px;
				display: flex;
				justify-content: center;
				align-items: center;
				border: 1px solid rgba(161, 172, 183, 0.35);
				li {
					display: none;
					&.on {
						display: block;
					}
				}
			}
			.watch_info {
				position: relative;
				width: 564px;
				border-top: 1px solid #31383f;
				border-bottom: 1px solid rgba(161, 172, 183, 0.35);
				padding: 16px 0 33px;
				.bland {
					font-family: 'Poppins';
					font-weight: 500;
					font-size: 16px;
					opacity: 0.6;
				}
				.option {
					position: absolute;
					right: 0;
					top: 12px;
					img {
						margin-left: 10px;
					}
				}
				.watch_name {
					font-weight: bold;
					font-size: 32px;
				}
				.t1 {
					margin: 10px 0 16px;
					font-weight: 500;
					font-size: 16px;
					padding-bottom: 16px;
					border-bottom: 1px solid rgba(161, 172, 183, 0.35);
				}
				.t2 {
					font-weight: 500;
					font-size: 16px;
					line-height: 20px;
					opacity: 0.75;
					span {
						font-weight: 700;
						color: #be9162;
					}
				}
				.t3 {
					margin-top: 20px;
					display: flex;
					align-items: center;
					.price {
						font-weight: bold;
						font-size: 44px;
					}
					.fluctuations {
						transform: translateY(1px);
						margin-left: 16px;
						display: flex;
						justify-content: center;
						align-items: center;
						width: 43px;
						height: 24px;
						border-radius: 2px;
						background: #a1acb7;
						font-weight: 500;
						font-size: 12px;
						line-height: 14px;
						color: #fff;
						&.up {
							background: #309d5c;
							span {
								padding-left: 12px;
								background: url(../img/common/ico_polygon_up.svg) no-repeat 0 50%;
							}
						}
						&.down {
							background: #d74c4c;
							span {
								padding-left: 12px;
								background: url(../img/common/ico_polygon_down.svg) no-repeat 0 50%;
							}
						}
					}
				}
				.end {
					margin-top: 24px;
					font-weight: bold;
					font-size: 24px;
					line-height: 28px;
					color: #ff5353;
					text-decoration: underline;
				}
				.join_num {
					margin-top: 12px;
					font-weight: 500;
					font-size: 20px;
				}
				.fluctuations {
					margin-top: 20px;
					display: flex;
					justify-content: center;
					align-items: center;
					width: 40px;
					height: 32px;
					border-radius: 2px;
					background: #a1acb7;
					font-weight: 500;
					font-size: 12px;
					line-height: 14px;
					color: #fff;
					&.up {
						background: #309d5c;
						span {
							padding-left: 12px;
							background: url(../img/common/ico_polygon_up.svg) no-repeat 0 50%;
						}
					}
					&.down {
						background: #d74c4c;
						span {
							padding-left: 12px;
							background: url(../img/common/ico_polygon_down.svg) no-repeat 0 50%;
						}
					}
				}
				.bidding {
					margin-top: 28px;
					display: flex;
					text-align: right;
					width: 100%;
					height: 64px;
					padding: 0 16px;
					font-weight: 500;
					font-size: 36px;
					background: #ffffff;
					border: 1px solid #c2ccd5;
					border-radius: 1px;
				}
				.sale {
					margin-top: 30px;
					display: flex;
					justify-content: center;
					align-items: center;
					width: 120px;
					height: 36px;
					background: #be9162;
					font-weight: 500;
					font-size: 13px;
					color: #fff;
				}
				.t4 {
					margin-top: 30px;
					font-weight: bold;
					font-size: 16px;
				}
				.store_list {
					display: flex;
					margin-top: 10px;
					li {
						margin-right: 32px;
						label {
							display: flex;
							align-items: center;
						}
						span {
							margin-left: 10px;
							font-weight: 500;
							font-size: 18px;
						}
					}
				}
				.buy_bidding {
					margin-top: 10px;
					width: 100%;
					height: 64px;
					background: #31383f;
					border-radius: 1px;
					font-weight: 500;
					font-size: 18px;
					color: #fff;
				}
				.alert_txt {
					margin-top: 8px;
					font-weight: 500;
					font-size: 14px;
					color: #ff5353;
				}
				.t5 {
					margin-top: 42px;
					font-weight: 500;
					font-size: 16px;
					display: flex;
					a {
						margin-left: auto;
						font-weight: bold;
						color: #be9162;
					}
				}
			}
		}
		.tab_list {
			margin-top: 16px;
			width: 596px;
			justify-content: space-between;
			display: flex;
			li {
				width: 140px;
				height: 140px;
				display: flex;
				justify-content: center;
				align-items: center;
				border: 1px solid rgba(161, 172, 183, 0.35);
				background-color: #fff;
				&.on {
					border: 1px solid #31383f;
				}
			}
		}
		.graph_box {
			margin-top: 36px;
			position: relative;
			width: 100%;
			height: 160px;
			background: #f5f7fa;
			border: 1px solid #edf0f3;
			border-radius: 1px;
			p {
				position: absolute;
				left: 28px;
				top: 24px;
				font-weight: bold;
				font-size: 16px;
				z-index: 1;
			}
			.graph {
				position: absolute;
				left: 36px;
				top: 37px;
				width: 1128px;
				height: 81px;
			}
		}
		h3 {
			margin: 80px 0 20px;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 28px;
		}
		.tbl {
			width: 100%;
			border-top: 1px solid #31383f;
			border-radius: 1px;
			th,
			td {
				border-bottom: 1px solid rgba(161, 172, 183, 0.35);
				padding: 22px 20px;
				text-align: left;
				font-size: 16px;
			}
			th {
				font-weight: bold;
				background: #f5f7fa;
			}
		}
	}
	.popup.grade_application {
		.txt_box {
			margin-top: 12px;
			padding: 16px;
			background: #f7fbff;
			border: 0.75px solid rgba(92, 92, 92, 0.0075);
			border-radius: 2px;
			.t1 {
				font-weight: 500;
				font-size: 13px;
				opacity: 0.75;
				line-height: 1.6;
			}
			.t2 {
				margin-top: 12px;
				font-weight: 500;
				font-size: 13px;
				line-height: 1.6;
			}
		}
		.cont {
			border-bottom: 1px solid #edf0f3;
			padding: 20px 0 46px;
			h3 {
				font-weight: 500;
				font-size: 12px;
				span {
					color: #ff5353;
				}
			}
			input[type='text'] {
				width: 100%;
				margin-top: 6px;
				font-weight: 500;
				color: rgba(49, 56, 63, 0.5);
			}
			input[type='text'] + h3 {
				margin-top: 16px;
			}
		}
		.cont2 {
			margin-top: 16px;
			font-weight: 500;
			font-size: 15px;
			> div {
				margin-top: 19px;
			}
			dl {
				display: flex;
				margin-bottom: 12px;
				dt {
					width: 95px;
					font-weight: 500;
					font-size: 14px;
					opacity: 0.6;
				}
				dd {
					font-weight: bold;
					font-size: 15px;
				}
			}
		}
		.btn_wrap {
			margin-top: 32px;
			.full {
				margin-left: 0;
			}
		}
	}
	.recently_view {
		position: fixed;
		right: 40px;
		bottom: 30px;
		display: flex;
		align-items: flex-end;
		flex-direction: column;
		> button {
			margin-top: 8px;
			display: flex;
			justify-content: center;
			align-items: center;
			color: #fff;
			width: 133px;
			height: 44px;
			font-weight: 500;
			font-size: 14px;
			background: #212529;
			box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
			border-radius: 100px;
			outline: none;
		}
		.recently_viewed_products {
			position: relative;
			width: 340px;
			height: 362px;
			padding: 18px 16px 12px;
			background: #fff;
			border: 1px solid #edf0f3;
			box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
			border-radius: 2px;
			display: none;
			&.on {
				display: block;
			}
			.top {
				display: flex;
				justify-content: space-between;
				.tit {
					font-weight: bold;
					font-size: 13px;
				}
				.btn_all_delete {
					font-weight: 500;
					font-size: 12px;
					text-decoration: underline;
				}
			}
			.top + ul {
				margin-top: 10px;
				li {
					display: flex;
					position: relative;
					margin-bottom: 4px;
					height: 90px;
					border: 1px solid rgba(161, 172, 183, 0.4);
					&:last-child {
						margin-bottom: 0;
					}
					> div:first-child {
						width: 219px;
						padding: 12px 14px;
						position: relative;
					}
					.brand {
						font-weight: 500;
						font-size: 12px;
						font-family: 'Poppins';
						opacity: 0.6;
					}
					.price {
						margin-top: 5px;
						font-weight: 500;
						font-size: 14px;
					}
					.fluctuations {
						margin-top: 11px;
						display: flex;
						justify-content: center;
						align-items: center;
						width: 28px;
						height: 18px;
						border-radius: 2px;
						background: #a1acb7;
						font-weight: 500;
						font-size: 11px;
						line-height: 12px;
						color: #fff;
						&.up {
							background: #309d5c;
							span {
								padding-left: 12px;
								background: url(../img/common/ico_polygon_up.svg) no-repeat 0 50%;
							}
						}
						&.down {
							background: #d74c4c;
							span {
								padding-left: 12px;
								background: url(../img/common/ico_polygon_down.svg) no-repeat 0 50%;
							}
						}
					}
					.option {
						position: absolute;
						right: 10px;
						top: 12px;
						img {
							margin-left: 6px;
							width: 15px;
						}
					}
					.sale {
						position: absolute;
						right: 0;
						bottom: 1px;
						display: flex;
						justify-content: center;
						align-items: center;
						width: 100%;
						height: 20px;
						background: #be9162;
						font-weight: 500;
						font-size: 11px;
						line-height: 12px;
						color: #fff;
					}
					.img_box {
						display: flex;
						justify-content: center;
						align-items: center;
						position: relative;
						width: 90px;
						height: 89px;
						border-left: 1px solid rgba(161, 172, 183, 0.4);
						img {
							width: 74px;
						}
					}
					.btn_del {
						position: absolute;
						right: 0;
						top: 0;
						width: 16px;
						height: 16px;
						background: #000 url(../img/common/ico_cross.svg) no-repeat 50% 50% / 6px;
					}
				}
			}
			.empty {
				padding: 100px 0;
				font-weight: 500;
				font-size: 11px;
				text-align: center;
				opacity: 0.75;
			}
			.paging_wrap {
				position: absolute;
				box-sizing: border-box;
				width: 305px;
				bottom: 10px;
				margin-top: 8px;
				display: flex;
				justify-content: center;
				button {
					width: 20px;
					height: 20px;
					margin: 0 6px;
					opacity: 0.6;
					&.next {
						background: url(../img/common/btn_next.svg) no-repeat 50% 50%;
					}
					&.prev {
						background: url(../img/common/btn_prev.svg) no-repeat 50% 50%;
					}
					&:hover {
						opacity: 1;
					}
				}
			}
		}
	}
	.filter_detail_wrap {
		display: none;
		position: absolute;
		border: 1px solid rgba(145, 145, 145, 0.58);
		background-color: #fff;
		z-index: 10;
		&.on {
			display: block;
		}
		&.brand {
			width: 910px;
			top: 84px;
			left: 40px;
		}
		&.model {
			width: 528px;
			top: 84px;
			left: 211px;
		}
		&.reference {
			width: 320px;
			top: 84px;
			left: 383px;
		}
		&.caseSize {
			width: 200px;
			top: 84px;
			left: 554px;
		}
		&.movement {
			width: 200px;
			top: 84px;
			left: 725px;
		}
		&.year {
			width: 320px;
			top: 84px;
			left: 896px;
		}
		&.components {
			width: 200px;
			top: 128px;
			left: 40px;
		}
		&.gender {
			width: 200px;
			top: 128px;
			left: 211px;
		}
		&.material {
			width: 200px;
			top: 128px;
			left: 383px;
		}
		&.color {
			width: 200px;
			top: 128px;
			left: 554px;
		}
		&.type {
			width: 200px;
			top: 128px;
			left: 725px;
		}
		&.branch {
			width: 200px;
			top: 128px;
			left: 896px;
		}
		&.condition {
			//프론트 주석
			// width: 348px;
			// top: 128px;
			// left: 1068px;
			#slider-range-min {
				margin-top: 30px;
				margin-bottom: 20px;
				position: relative;
				.start {
					position: absolute;
					left: 0;
					top: -20px;
					font-weight: 500;
					font-size: 13px;
					line-height: 18px;
					color: rgba(52, 58, 64, 0.5);
				}
				.end {
					position: absolute;
					top: -20px;
					right: 0;
					font-weight: 500;
					font-size: 13px;
					line-height: 18px;
					color: rgba(52, 58, 64, 0.5);
				}
			}
			.ui-widget-header {
				background-color: #31383f;
			}
			.ui-widget-content {
				background: rgba(170, 180, 196, 0.2);
				border: 0 solid #000;
				border-radius: 100px;
			}
			.ui-slider-horizontal {
				height: 8px;
			}
			.ui-slider-horizontal .ui-slider-handle {
				background: url(../img/common/ico_polygon2.svg) no-repeat 0 0 / 90%;
				width: 14px;
				height: 12px;
				border: 0 solid #000;
				top: 5px;
				outline: none;
				margin-left: -7px;
				#custom-handle {
					display: block;
					position: absolute;
					bottom: -20px;
					left: 50%;
					text-align: center;
					transform: translateX(-50%);
					width: 30px;
					font-weight: bold;
					font-size: 14px;
					line-height: 18px;
					color: #343a40;
				}
			}
		}
		.btn_wrap {
			display: flex;
			justify-content: center;
			padding: 20px;
			border-top: 1px solid rgba(180, 190, 203, 0.5);
			button {
				font-weight: 500;
				font-size: 14px;
				height: 40px;
				color: #31383f;
				padding: 0 20px;
				border: 1px solid #31383f;
				border-radius: 1px;
				&.btn_ok {
					background-color: #31383f;
					color: #fff;
					margin-left: 8px;
				}
			}
			&.right {
				padding: 20px 24px;
				justify-content: flex-end;
			}
		}

		.search_box {
			display: flex;
			padding: 24px;
			align-items: center;
			strong {
				width: 160px;
				font-weight: 500;
				font-size: 14px;
				line-height: 16px;
				color: #31383f;
			}
			span {
				font-weight: 500;
				font-size: 12px;
				line-height: 14px;
				color: #112211;
				opacity: 0.75;
			}
		}
		.all_brand {
			display: flex;
			flex-wrap: wrap;
			padding: 20px 24px;
			align-items: top;
			border-top: 1px solid rgba(180, 190, 203, 0.5);
			.scroll_box {
				width: calc(100% - 160px);
				height: 110px;
				overflow-y: auto;
				margin-bottom: 20px;
			}
			ul {
				display: flex;
				flex-wrap: wrap;
				li {
					margin-right: 20px;
					margin-bottom: 24px;
				}
			}
			strong {
				padding-top: 5px;
				width: 160px;
				font-weight: 500;
				font-size: 14px;
				line-height: 16px;
				color: #31383f;
			}
			label {
				display: flex;
				align-items: center;
			}
			label span {
				font-size: 14px;
				color: #121212;
				margin-left: 8px;
				width: 120px;
			}
			& > span {
				display: block;
				width: 100%;
				font-weight: 500;
				font-size: 12px;
				line-height: 14px;
			}
		}
		.all_model {
			display: flex;
			flex-wrap: wrap;
			padding: 20px 24px;
			align-items: top;
			border-top: 1px solid rgba(180, 190, 203, 0.5);
			& strong + div {
				width: calc(100% - 160px);
			}
			.search_wrap {
				width: 100%;
				height: 38px;
				border: 1px solid #aab4c4;
				margin-bottom: 16px;
				position: relative;
				input {
					display: flex;
					align-items: center;
					padding: 12px;
					font-weight: 500;
					font-size: 12px;
					line-height: 14px;
					opacity: 0.59;
					border: 0 solid #000;
					background: transparent;
				}
				button {
					position: absolute;
					right: 12px;
					top: 50%;
					transform: translateY(-50%);
				}
			}
			.scroll_box {
				max-height: 220px;
				overflow-y: auto;
				margin-bottom: 20px;
			}
			ul {
				display: flex;
				flex-wrap: wrap;
				li {
					margin-right: 20px;
					margin-bottom: 20px;
					ul {
						margin-left: 12px;
						margin-top: 10px;
						li {
							margin-bottom: 12px;
						}
					}
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
			strong {
				padding-top: 5px;
				width: 160px;
				font-weight: 500;
				font-size: 14px;
				line-height: 16px;
				color: #31383f;
			}
			label {
				display: flex;
				align-items: center;
			}
			label span {
				font-size: 14px;
				color: #121212;
				margin-left: 8px;
			}
			& > span {
				display: block;
				width: 100%;
				font-weight: 500;
				font-size: 12px;
				line-height: 14px;
			}
		}
		.default_box {
			padding: 20px 24px;
			strong {
				display: block;
				font-weight: 500;
				font-size: 14px;
				line-height: 16px;
				color: #31383f;
				margin-bottom: 12px;
			}
			input[type='text'] {
				display: flex;
				align-items: center;
				padding: 12px;
				font-weight: 500;
				font-size: 12px;
				line-height: 14px;
				opacity: 0.59;
				width: 100%;
				height: 38px;
				border: 1px solid #aab4c4;
				background: transparent;
			}
			label span {
				font-weight: 500;
				font-size: 14px;
				line-height: 17px;
				color: #121212;
				margin-left: 10px;
			}
			strong + ul {
				margin-top: 10px;
			}
			strong + ul li {
				margin-bottom: 16px;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}
