.shop {
	.sub-top-hapl {
		font-family: Roboto;

		background-image: url(../../assets-pc/front-img/pc-place-banner.png);
		padding: 0 100px;
		background-size: 100% 100%;
		width: 100%;
		position: relative;
		height: 362px;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-align: center;
		align-items: center;
		-ms-flex-direction: column;
		flex-direction: column;
		-ms-flex-pack: center;
		justify-content: center;
		color: #fff;
		h1,
		div {
			font-family: Roboto;
			font-style: normal;
			font-weight: 300;
			font-size: 30px;
			line-height: 21px;
			letter-spacing: 0.1em;
			text-align: center;
			height: 40px;
		}
		.sub-title {
			font-size: 18px;
			margin-top: 8px;
		}
		.btn-box {
			margin-top: 30px;
			.reservation-btn {
				background-color: #574b3a;

				height: 54px;
				width: 211px;
				left: 0px;
				top: 0px;
				border-radius: 0px;
				margin-right: 2px;
				position: relative;
				.content {
					position: absolute;
					left: 31.75%;
					right: 18.96%;
					top: 37.04%;
					bottom: 24.07%;

					font-family: Roboto;
					font-style: normal;
					font-weight: normal;
					font-size: 18px;
					line-height: 21px;
					/* identical to box height */

					display: flex;
					align-items: center;
					text-align: center;

					color: rgba(255, 255, 255, 0.5);
				}
				.line1 {
					position: absolute;
					left: 31.75%;
					right: 5.21%;
					top: 85.19%;
					bottom: 14.81%;

					border: 1px solid rgba(255, 255, 255, 0.46);
				}
				.line2 {
					position: absolute;
					left: 84%;
					right: 4%;
					top: 70.61%;
					bottom: 33.33%;

					border: 1px solid rgba(255, 255, 255, 0.46);
					transform: rotate(37.57deg);
				}
			}
			.introduce-btn {
				background-color: #b9a07e;
				height: 54px;
				width: 211px;
				left: 0px;
				top: 0px;
				border-radius: 0px;
				margin-left: 2px;
				position: relative;
				.content {
					position: absolute;
					left: 32.23%;
					right: 26.07%;
					top: 38.89%;
					bottom: 22.22%;

					font-family: Roboto;
					font-style: normal;
					font-weight: normal;
					font-size: 18px;
					line-height: 21px;
					/* identical to box height */

					display: flex;
					align-items: center;
					text-align: center;

					color: #fcfcfc;
				}
				.line1 {
					position: absolute;
					left: 32.23%;
					right: 4.74%;
					top: 85.19%;
					bottom: 14.81%;

					border: 1px solid rgba(255, 255, 255, 0.46);
				}
				.line2 {
					position: absolute;
					left: 84%;
					right: 4%;
					top: 70.61%;
					bottom: 33.33%;

					border: 1px solid rgba(255, 255, 255, 0.46);
					transform: rotate(37.57deg);
				}
			}
			.left-top-logo {
				position: absolute;
				left: 5.21%;
				right: 60.66%;
				top: 12.96%;
				bottom: 64.81%;

				font-family: Roboto;
				font-style: normal;
				font-weight: normal;
				font-size: 10px;
				line-height: 12px;
				/* identical to box height */

				display: flex;
				align-items: center;
				text-align: center;

				color: rgba(252, 252, 252, 0.3);
			}
		}
	}
}
