.certification-section {
	border-top: 1px solid black;
	width: 1280px;
	margin: 0 auto;
	padding: 40px 0;
	.certification-box {
		display: flex;
		justify-content: space-between;
		img {
			width: 107px;
			height: 107px;
		}
	}
}
.youtube-thumbnail1 {
	width: 418px;
	z-index: 99;
	background-color: 'rgba(0,0,0,0.2)';
	height: 235px;
	position: absolute;
	font-family: 'Roboto';
	font-style: normal;
}
.hapl-section {
	width: 1280px;
	margin: 0 auto;
	display: block;
	box-sizing: border-box;
	margin-top: 60px;
	.top-section {
		display: flex;
		justify-content: space-between;
		height: 28px;
		margin-bottom: 30px;
		.title {
			font-weight: 500;
			font-size: 24px;
			line-height: 28px;

			color: #000000;

			.desc {
				font-weight: 300;
				font-size: 18px;
				line-height: 21px;
				/* identical to box height */

				letter-spacing: 0.1em;

				color: #797979;
				margin-left: 12px;
			}
		}
		.more {
			font-weight: 500;
			font-size: 24px;
			line-height: 16px;
			/* identical to box height, or 67% */

			display: flex;
			align-items: center;

			color: #000000;
		}
	}
	.img-list-section {
		display: flex;
		justify-content: space-between;
		.img-box {
			img {
				width: 586px;
				height: 282px;
			}
		}
		.reserve-box {
			border-bottom: 1px solid rgba(236, 236, 236, 1);
			height: 282px;
			width: 636px;
			display: flex;
			justify-content: space-between;
			.part {
				width: 184px;

				.head {
					height: 40px;
					left: 869px;
					top: 1643px;

					background: #1c1c1c;
					border-radius: 5px;
					color: white;
					display: flex;
					justify-content: center;
					align-items: center;
				}
				.body {
					.list {
						height: 21px;

						font-family: 'NanumGothic';
						font-weight: 400;
						font-size: 18px;
						line-height: 18px;
						text-align: center;

						color: #000000;
						margin: 22px auto;
					}
				}
			}
		}
	}
}
///// 리뷰 pick
.review-section {
	width: 1280px;
	margin: 0 auto;
	display: block;
	box-sizing: border-box;
	margin-top: 85px;
	margin-bottom: 25px;
	.top-section {
		display: flex;
		justify-content: space-between;
		height: 28px;
		margin-bottom: 30px;
		font-family: 'Roboto';
		font-size: 24px;

		.left {
			line-height: 28px;

			color: #000000;
		}
		.right {
			line-height: 16px;
			/* identical to box height, or 67% */

			display: flex;
			align-items: center;

			color: #000000;
		}
	}
	.review-container {
		display: flex;
		justify-content: space-between;
		.one-review {
			width: 298px;
			cursor: pointer;
			.desc {
				margin-top: 16px;
				width: 267px;
			}
		}
	}
}
.other-section {
	width: 1280px;
	margin: 0 auto;
	display: block;
	box-sizing: border-box;
	margin-top: 85px;
	.top-section {
		height: 28px;
		margin-bottom: 30px;
		font-family: 'Roboto';
		font-size: 24px;
	}
	.content-section {
		display: flex;
		justify-content: space-between;
		.one-content {
			cursor: pointer;
			.btn {
				margin-top: 8px;
				width: 420px;
				height: 90px;

				border: 1px solid #c4c4c4;
				box-sizing: border-box;
				position: absolute;

				font-family: 'Roboto';
				font-style: normal;
				// font-weight: 500;
				padding: 55px 15px 0 15px;
				font-size: 24px;
				line-height: 16px;
				/* or 67% */

				display: flex;
				justify-content: space-between;

				color: #000000;
				span {
					font-size: 36px;
				}
			}
		}
	}
}
.youtube-section {
	padding-top: 120px;
}
